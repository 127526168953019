import AdminLogo from '../Resources/Images/Logos/user-lock.png';
import UserLogo from '../Resources/Images/Logos/user.png';
import { APPCONFIG } from '../app.config'

const GLOBAL = {
  APPNAME: 'Origenes',
  USER_TYPE_PRODUCTOR: '1',
  USER_TYPE_SIMULADOR: '2',
  USER_TYPE_ADMINISTRADOR: '3',
  COMBO_EMPTY_ITEM: { id: 0, value: '-- SELECCIONAR --', message: '' },
  DEMO: {
    SUBTITLE: "Links:",
    MENU: "UI Atomic Design"
  },
  FOOTER: {
    PORTAL: 'PORTAL',
    ENVIRONMENT: 'PRODUCCIÓN',
    VERSION: 'V 1.2.1',
    BUTTON_TEXT: 'Soporte técnico'
  },
  LOGIN: {
    TITLE: "Portal de Productores ",
    SUBTITLE: "Bienvenidos",
    PORTAL: "Te damos la bienvenida al Portal de Productores",
    TEXT: "Orígenes online es la manera más ágil, cómoda y segura de acceder a tu información actualizada."
  },
  CHANGE_PASSWORD_FORM: {
    OLD_PASSWORD: 'Contraseña actual',
    NEW_PASSWORD: 'Nueva contraseña',
    CONFIRM_NEW_PASSWORD: 'Repetir nueva contraseña',
    SUBMIT_BUTTON: 'Cambiar contraseña',
    TITLE: "Orígenes PAS ",
  },
  SIMULATE_FORM: {
    ERROR_SIMULATE_PRODUCER: 'Error al similar productor',
    SUBMIT_BUTTON: 'Simular',
    REMEMBER_PASSWORD: 'Recordar Contraseña',
    TITLE: "Orígenes PAS ",
  },
  LOGIN_FORM: {
    CUIT: 'CUIT / CUIL',
    EMAIL: 'E-mail',
    PASSWORD: 'Contraseña',
    LOG_IN: 'Iniciar Sesión',
    BUTTON_TEXT: 'Ingresar',
    REGISTER: 'Registrate ahora',
    MISSING_ACCOUNT: '¿Aún no tienes cuenta?',
    REMEMBER_PASSWORD: 'Recordar Contraseña',
    RECOVER_PASSWORD: 'Recuperar contraseña',
    MAILNOTRECEIVED: 'Si se registró, y no recibio mail pasados 5 minutos, haga '
  },
  REGISTER_FORM: {
    PASS_INSTRUCTIONS: '> Incluir un número y una letra mayúscula',
    CONFIRM_INSTRUCTIONS: '> Repetir contraseña',
    HAVE_READ: 'He leído los',
    TERMS_AND_CONDITIONS: 'términos y condiciones de uso',
    BUTTON_TEXT: 'Registrarse',
    ERRORS: {
      PASSWORD: 'Las contraseñas no coinciden'
    },
    INPUTS: {
      NAMES: 'Nombre',
      LAST_NAMES: 'Apellidos',
      CUIT: 'CUIT/CUIL',
      EMAIL: 'Email *',
      PHONE: 'Teléfono',
      PHONE_COMPANY: 'Empresa',
      PASSWORD: 'Contraseña *',
      CONFIRM_PASSWORD: 'Confirmación *'
    }
  },
  REGISTER_VALIDATION: {
    TITLE: '¡Gracias por completar el formulario!',
    SUBTITLE: 'En breve recibirá un mail a su correo. Por favor siga las instrucciones para finalizar su registración',
    MESSAGE: 'Se ha enviado un mail a su correo, por favor siga las instrucciones para finalizar su registración'
  },
  SUCCESS_REGISTER_FORM: {
    TITLE: 'Registro completado con éxito',
    SUBTITLE:
      'Se ha completado el registro exitosamente, te pedimos que inicies sesión para acceder a tu perfil.',
    RECOVER_SUCCESS: {
      TITLE: 'Restablecer contraseña',
      TITLE_MESSAGE:
        'Elija una nueva contraseña para este usuario. Esta debe constar de 7 dígitos, una mayúscula y un número.',
      NEW_PASSWORD: 'Nueva contraseña',
      NEW_PASSWORD_CONFIRM: 'Confirmar tu nueva contraseña',
      BUTTON_TEXT: 'Continuar'
    }
  },
  USER_OPTIONS: {
    PLATFORM: 'Elegir el rol para entrar a la plataforma',
    SMALLPRINT: 'Dependiendo el rol elegido los permisos a los que podrás acceder serán diferentes.',
    ADMIN: { img: AdminLogo, title: 'Rol Administrador' },
    USER: { img: UserLogo, title: 'Rol Usuario' },
    ROLE: '| ROL: Administrador',
    BUTTON_TEXT: 'Ingresar'
  },
  INVOICE_DELIVERY: {
    DOCUMENT_ERROR: 'Error al subir documentos',
    DOCUMENT_SENT_MSG: 'Recibirá un email con la documentación enviada',
    DOCUMENT_SENT_TITLE: 'Los documentos se han enviado satisfactoriamente',
    FORM_TITLE: 'Envío de Facturas',
    FORM_SUBTITLE: 'Recuerden facturar los ramos indicados en forma separada'
  },
  DEBT_FREE: {
    DOCUMENT_ERROR: 'Error al generar libre deuda',
    VALIDATION_ERROR: 'Existen campos incommpletos',
    FORM_TITLE: 'Libre deuda',
  },
  RUBRIC_DIGITAL: {
    DOCUMENT_ERROR: 'Error al generar rúbrica digital',
    VALIDATION_ERROR: 'Existen campos incommpletos',
    FORM_TITLE: 'Rúbrica digital',
  },
  SEND_PAYROLL: {
    DOCUMENT_ERROR: 'Error al generar el archivo',
    DOCUMENT_OK: 'Archivo generado correctamente',
    VALIDATION_ERROR: 'Existen campos incompletos',
    FORM_TITLE: 'Envío de nóminas',
  },
  INVOICE_CRM: {
    DOCUMENT_ERROR: 'Error al subir documentos',
    DOCUMENT_SENT_MSG: 'Recibira un email con la documentación enviada',
    DOCUMENT_SENT_TITLE: 'Los documentos se han enviado satisfactoriamente',
    FORM_TITLE: 'Consulta de Facturas',
  },
  CHANNEL_OPTIONS: {
    TITLE: 'Administrador de canales',
    SUBTITLE: 'Ya elegiste el rol deseado, ahora resta elegir el canal al que quieres acceder.',
    ROLE: '| ROL: Administrador'
  },
  RECOVER_FORM: {
    TITLE: 'Recuperar contraseña',
    TITLE_MESSAGE:
      'Utilice el siguiente formulario para recuperar el usuario y contraseña que había registrado. Una vez validado se le enviará un link a su casilla de correo para que pueda modificar su contraseña actual.',
    TITLE_CONFIRM: 'Revisa tu mail',
    MESSAGE_CONFIRM: 'Hemos enviado un mensaje con un link para que pueda modificar su contraseña actual.',
    RECOVER_BUTTON: 'Recuperar contraseña',
    MAIL_QUESTION: '¿No has recibido un mail?',
    SEND_AGAIN: 'Enviarlo de nuevo',
    CANCEL_BUTTON: 'Cancelar',
    CONTINUE_BUTTON: 'Continuar',
    CODE_INPUT: 'Introduce el código',
    INPUTS: {
      CUIT: 'CUIT/CUIL',
      EMAIL: 'Email',
      CODE: 'Código'
    }
  },
  RECOVER_ACCOUNT: {
    TITLE: 'Reenviar email de registración',
    TITLE_MESSAGE:
      'Utilice el siguiente formulario para recuperar su cuenta ya registrada. Se reenviará un email en el cual deberá clickear el botón que le permitirá generar la contraseña de su usuario.',
    TITLE_CONFIRM: 'Revisa tu mail',
    MESSAGE_CONFIRM: 'Hemos enviado un mensaje con un link para que pueda modificar su contraseña actual.',
    RECOVER_BUTTON: 'Reenviar correo de validación',
    MAIL_QUESTION: '¿No has recibido un mail?',
    SEND_AGAIN: 'Enviarlo de nuevo',
    CANCEL_BUTTON: 'Cancelar',
    CONTINUE_BUTTON: 'Continuar',
    CODE_INPUT: 'Introduce el código',
    INPUTS: {
      CUIT: 'CUIT/CUIL',
      EMAIL: 'Email',
      CODE: 'Código'
    },
    SERVER_ERROR: "No se pudo conectar con el servidor!"
  },
  RECOVER_RESET: {
    TITLE: 'Restablecer contraseña',
    TITLE_MESSAGE:
      'Elija una nueva contraseña para este usuario. Esta debe constar de al menos 6 dígitos, una mayúscula y un número.',
    CUIT: 'Cuit',
    NEW_PASSWORD: 'Nueva contraseña',
    NEW_PASSWORD_CONFIRM: 'Confirmar tu nueva contraseña',
    BUTTON_TEXT: 'Continuar',
    PASS_INSTRUCTIONS: '> Incluir un número y una letra mayúscula',
    CONFIRM_INSTRUCTIONS: '> Repetir contraseña',
  },
  RECOVER_SUCCESS: {
    TITLE: 'Contraseña reestablecida',
    TITLE_MESSAGE: 'Se ha enviado un email para recuperar su contraseña. Por favor revice su casilla y siga los pasos.',
    USER: 'Usuario',
    PASSWORD: 'Contraseña',
    BUTTON_TEXT: 'Ingresar'
  },
  MODAL: {
    TITLE: 'Cancelar recupero',
    BODY: '¿Está seguro que desea cancelar el recupero?',
    CONFIRM_BUTTON: 'SI',
    CANCEL_BUTTON: 'NO'
  },
  CARTERA_SEARCH_FORM: {
    TITLE: 'Búsqueda de Cartera'
  },

  COMMON_CRUD_FORM: {
    NEW_BUTTON: "Nuevo registro",
    NEW_ICON_BUTTON: "fa-plus",
  },

  COMMON_MODAL_FORM: {
    SAVE_BUTTON: "Guardar",
    GO_BUTTON: "Continuar",
    OK_BUTTON: "Aceptar",
    CLOSE_BUTTON: "Cerrar",
  },

  COMMON_SEARCH_FORM: {
    PRODUCTOR_CUIT: 'CUIT',
    PRODUCTOR_CUIT_PLACEHOLDER: 'BUSCAR POR NRO. DE CUIT',
    PRODUCTOR_NOMBRE: 'NOMBRE O RAZÓN SOCIAL',
    PRODUCTOR_NOMBRE_PLACEHOLDER: 'BUSCAR POR NOMBRE O RAZÓN SOCIAL',
    FECHA_EMISION: {
      DESDE: "Fecha de Emisión (Desde)",
      HASTA: "Fecha de Emisión (Hasta)"
    },
    FECHA_RECHAZO: {
      DESDE: "Fecha de rechazo (Desde)",
      HASTA: "Fecha de rechazo (Hasta)"
    },
    FECHA_VIGENCIA: {
      DESDE: "Fecha de Vigencia (Desde)",
      HASTA: "Fecha de Vigencia (Hasta)"
    },
    FECHA: {
      DESDE: 'Fecha (Desde)',
      HASTA: 'Fecha (Hasta)'
    },
    VIGENCIA: 'Vigencia',
    RAMO: "Ramo",
    PRODUCTO: "Producto",
    NRO_POLIZA: "Nro de Póliza",
    NRO_CERTIFICADO: "Nro de Certificado",
    ESTADO: "Estado",
    ASEGURADO: {
      DNI: "DNI",
      NOMBRE: "Nombre",
      APELLIDO: "Apellido"
    },
    VIEW_BUTTON: "Administrar columnas",
    SCHEDULER_BUTTON: "Programar envío",
    SEARCH_BUTTON: "Buscar",
    SEARCHING_TEXT: "Buscando..",
    PROMPT_TEXT: "Escribe para buscar...",
    EMPTY_LABEL_TEXT: "No se encontraron coincidencias.",
    CLEAR_FILTERS: "Limpiar",
    RESULTS: "Resultados",
    DATEPICKER_PLACEHOLDER: "Seleccione...",
    SERVER_ERROR: "No se pudo conectar con el servidor!",
    SELECTED_PRODUCERS: "Productores seleccionados: ",
    SELECT_PRODUCERS_TAB: 'Selección Productor/es',
    SELECT_DATES_TAB: 'Filtro por Fecha',
    SELECT_PRODUCTS_TAB: 'Filtro por Producto',
    SELECT_POLICY_TAB: 'Filtro por Póliza',
    DATERANGE_SEARCH_TEXT: 'Rango de Fechas:',
    PRODUCTS_SEARCH_TEXT: 'Filtro por productos:',
    POLICY_SEARCH_TEXT: 'Por póliza:',
    USER_SEARCH_TEXT: 'Por asociado:',
    PRODUCTOR_SELECTED_ALERT: 'Ahora seleccioná uno o más items del árbol del productor seleccionado para ver su información.',
    SET_FILTER_ALERT: 'Completá un filtro de realizar la búsqueda de resultados',
    APPLIED_FILTERS_ALERT: 'Filtros aplicados: ',
    START_SEARCH_ALERT: 'Para iniciar la búsqueda elegí uno de los filtros disponibles en la parte superior',
    SELECT_PRODUCER_ALERT: 'Primero debés elegir un productor de la selección de Productores.',
    SELECT_ALERT: 'Seleccioná un productor del listado.',
    VIEW_FILTERS: 'Ver filtros aplicados',
    ONLY_POLICY_EXPIRED_ALERT: 'Las pólizas que deben ser regularizadas para que no se anulen son aquellas que tienen deudas de más de 60 días'
  },

  LIQUIDACION_SEARCH_FORM: {
    TITLE: "Liquidación"
  },

  DNI_SELECT: {
    SELECT_VALUE: 'Seleccione',
    DNI_VALUE: 'DNI'
  },
  PAGES: {
    TITLE: {
      PORTFOLIO: 'Búsqueda de cartera',
      SETTLEMENT: 'Liquidación de comisiones',
      INVOICE: 'Envío de facturas',
      DEBT_FREE: 'Libre deuda',
      RUBRIC_DIGITAL: 'Rúbrica digital',
      SEND_PAYROLL: 'Envío de nómina',
      TIPSFACTURACION: 'Tips de Facturación',
      CRMINVOICE: 'Consulta de facturas',
      REJECTION: 'Rechazos por motivo',
      DEUDORES: 'Deudores por premio',
      ADMINNOTIFICATION: 'Administración de Notificaciones',
      HISTORIALNOTIFICATION: 'Historial de Notificaciones',
      ADMINCOMMUNICATION: 'Administración de Comunicaciones',
      ADMINLABEL: 'Administración de Leyendas en las planillas',
      HISTORIALCOMMUNICATION: 'Historial de Comunicaciones',
      USER_DASHBOARD: 'Edición de perfil',
      ADMINUSERS: 'Administrar usuarios',
      SIMULATEPRODUCER: 'Simular productor',
      CHANGEPASSWORD: 'Cambiar contraseña',
      STATISTICS: 'Estadísticas'
    }
  },
  USER_PROFILE: {
    PASS_INSTRUCTIONS_CHANGE: '> Ingresela solo en caso de cambio de contraseña',
    PASS_INSTRUCTIONS: '> Incluir un número y una letra mayúscula',
    CONFIRM_INSTRUCTIONS: '> Repetir contraseña',
    HAVE_READ: 'He leído los',
    TERMS_AND_CONDITIONS: 'términos y condiciones de uso',
    BUTTON_TEXT: 'Registrarse',
    CHANGE_AVATAR_TEXT: 'Seleccionar foto de perfil',
    ERRORS: {
      PASSWORD: 'Las contraseñas no coinciden'
    },
    INPUTS: {
      NAMES: 'Nombre',
      LAST_NAMES: 'Apellidos',
      CUIT: 'CUIT/CUIL',
      EMAIL: 'Email *',
      PHONE: 'Teléfono',
      PHONE_COMPANY: 'Empresa',
      ACCOUNT_MANAGER: 'Ejecutivo de cuentas',
      CHANGE_PASSWORD: 'Cambio de contraseña:',
      CURRENT_PASSWORD: 'Contraseña actual',
      NEW_PASSWORD: 'Nueva contraseña',
      CONFIRM_PASSWORD: 'Confirmación de contraseña'
    }
  },
  INNER_NAV: [
    { name: 'Inicio', link: '/' },
    { name: 'Cartera', link: '/cartera' },
    { name: 'Liquidación', link: '/liquidacion' },
    { name: 'Envío de facturas', link: '/enviofacturas' },
    { name: 'Libre deuda', link: '/libredeuda' },
    { name: 'Administración de notificaciones', link: '/adminnotificaciones' },
    { name: 'Historial de notificaciones', link: '/historialnotificaciones' },
    { name: 'Administración de comunicaciones', link: '/admincomunicaciones' },
    { name: 'Administración de leyendas en las plantillas', link: '/adminleyendas' },
    { name: 'Historial de comunicaciones', link: '/historialcomunicaciones' },
    { name: 'Rúbrica digital', link: '/rubricadigital' },
    { name: 'Envío de nómina', link: '/envionominas' },
    { name: 'Tips de facturación', link: '/tipsfacturacion' },
    //{ name: 'Consulta de facturas', link: '/consultafacturas' },
    { name: 'Rechazos por motivo', link: '/rechazos' },
    { name: 'Deudores por premio', link: '/deudores' },
    { name: 'Edición de perfil', link: '/user-dashboard-info' },
    { name: 'Simular productor', link: '/simulate-producer' },
    { name: 'Avisos web', link: '/news' },
    { name: 'Estadísticas', link: '/estadisticas' },
    { name: 'Consultas', link: '/consulta' },
    { name: 'Orígenes Caución', link: '/https://caucion.origenes.com.ar' },
    //{ name: 'Certificados de retención', link: '/certificados-retencion' },
    // { name: 'Deudores por Premio', link: '/table'},
    // { name: 'Liquidaciones', link: '/envio-facturas1'},
    // { name: 'Rol', link: '/user' },
    // { name: 'Canal', link: '/channels' },
    // { name: 'Alta formulario', link: '/form' }
  ],
  COMPONENTS_FORM: {
    CONFIRM_TITLE: "ADVERTENCIA!",
    CONFIRM_DELETE: '¿Esta seguro que desea eliminar el registro?',
    ALERT_SUCCESS_TITLE: '¡Proceso exitoso!',
    ALERT_SUCCESS_MSG: 'Se realizó la acción correctamente.',
    ALERT_ERROR_TITLE: '¡Ha ocurrido un error!',
    ALERT_ERROR_DESC: 'Texto de referencia del error y cual pudo haber sido la causa.',
    ALERT_ERROR_INFO: 'Tipo de error y la información del mismo.',
    ALERT_ERROR_LINE: 'Línea 1',
    ALERT_ERROR_MSG: 'La información no pudo ser validada.',
    ALERT_WARNING_TITLE_SIMULATOR: 'Simulación de productor',
    ALERT_WARNING_SIMULATOR: 'Esta simulando al usuario CUIT: XX-XXXXXXXX-X, esta acción afectara a los datos, acciones del productor con el que accedio a simular ¿Desea continuar?',
    REGISTEROK: 'Registración exitosa',
    FORM_ERROR: 'Error al realizar la operación',
    RECOVER_SUCCESS: 'Hemos enviado un email para reestablecer su contraseña.',
    RECOVER_SUCCESS_MSG: 'Revise su casilla y siga los pasos para reestablecerla.',
    PLEASELOGIN: 'Por favor inicie sesión',
    ERRORS: 'Mock error',
    IMAGECARD_SEE_MORE: 'Ver más',
    NAME_INPUT: 'Nombre',
    LASTNAME_INPUT: 'Apellido',
    USERNAME_INPUT: 'Usuario',
    PASSWORD_INPUT: 'Contraseña *',
    PASSWORD_INPUT_MSG: '> Incluir un número y una letra mayúscula',
    CONFIRM_PASSWORD_INPUT: 'Confirmar contraseña *',
    CONFIRM_PASSWORD_INPUT_MSG: '> Repetir contraseña',
    MAIL_INPUT: 'Mail',
    MAIL_INPUT_MSG: '> Ingresar una dirección de mail válida',
    TEL_INPUT: 'Teléfono',
    TEL_INPUT_MSG: '> Por favor incluir solamente números',
    SELECT_INPUT: 'Selector de opciones',
    SELECT_INPUT_MSG: '> Por favor elija una de las opciones',
    SELECT_INPUT_DEFAULT: 'Seleccione',
    SELECT_INPUT_OPTIONAL: 'Sin determinar',
    MULTIPLE_INPUT: 'Selector múltiple',
    TERMS_INPUT: 'Acepto los términos',
    CRUD_ACTIONS: {
      EDIT: 'Editar',
      DELETE: 'Eliminar',
      UPDATE: 'Modificar',
      CUSTOM: {
        SEND_EMAIL: 'Enviar por e-mail',
        DOWNLOAD: 'Descargar',
        VIEW_DETAIL: 'Ver detalle'
      }
    },
    MENUS: [
      {
        order: 1,
        label: 'Inicio',
        permissionName: 'Home',
        link: '/',
        options: [],
        className: '',
        tooltip: '',
        nivel: 1
      },
      {
        order: 2,
        label: 'Cartera',
        permissionName: 'Cartera',
        link: '/cartera',
        options: [
        ],
        className: '',
        tooltip: 'Ver cartera vigente',
        nivel: 1
      },
      {
        order: 8,
        label: 'Canales',
        permissionName: 'URLCanales',
        link: APPCONFIG.URLS.CANALESLINK,
        options: [],
        className: '',
        tooltip: '',
        nivel: 1,
      },
      {
        order: 9,
        label: 'Caución',
        permissionName: 'URLCaucion',
        link: APPCONFIG.URLS.CAUCIONLINK,
        options: [],
        className: '',
        tooltip: '',
        nivel: 1,
      },
      {
        order: 16,
        icon: 'fa fa-send',
        label: 'Gestión de trámites',
        permissionName: 'Consultas',
        link: '/consulta',
        options: [],
        className: '',
        tooltip: '',
        nivel: 1
      },
      /*{
        order: 15,
        label: 'Marketing digital',
        permissionName: 'Marketing',
        link: 'https://www.origenes.com.ar/marketing/',
        options: [],
        className: '',
        tooltip: '',
        nivel: 1
      },*/
      {
        order: 17,
        icon: 'fa fa-question-circle',
        label: 'Ayuda',
        permissionName: 'Tutoriales',
        link: '/tutoriales',
        options: [],
        className: '',
        tooltip: '',
        nivel: 1
      },
      
      {
        order: 18,
        label: 'Administración',
        permissionName: 'Administracion',
        link: '',
        options: [
          {
            order: 19,
            label: 'Notificaciones',
            permissionName: 'AdminNotificaciones',
            link: '/adminnotificaciones',
            options: [],
            className: '',
            nivel: 2
          },
          {
            order: 20,
            label: 'Comunicaciones',
            permissionName: 'AdminComunicaciones',
            link: '/admincomunicaciones',
            options: [],
            className: '',
            nivel: 2
          },
          {
            order: 21,
            label: 'Leyendas en las planillas',
            permissionName: 'AdminLeyendas',
            link: '/adminleyendas',
            options: [],
            className: '',
            nivel: 2
          },
          {
            order: 22,
            label: 'Auditoria',
            permissionName: 'VerAuditoriaProductor',
            link: '/auditoria-productor',
            options: [],
            className: '',
            nivel: 2
          },
        ],
        className: '',
        nivel: 1
      },
      {
        order: 23,
        label: 'Estadisticas',
        permissionName: 'VerEstadisticas',
        link: '/estadisticas',
        options: [],
        className: '',
        nivel: 1
      },
      {
        order: 24,
        label: 'Marketing',
        permissionName: 'VerMarketing',
        link: '/news',
        options: [],
        className: '',
        nivel: 1
      }
    ],
    RADIO_OPTIONS: [
      { label: 'Opción para elegir entre 1 o 2', value: 'opcion1', disabled: false },
      { label: 'Esta es la segunda opción para elegir', value: 'opcion2', disabled: false },
      { label: 'Esta opción no está disponible', value: 'opcion3', disabled: true }
    ],
    BIRTH_DATE: 'Fecha de nacimiento',
    DATE_BEGIN: 'Desde',
    DATE_END: 'Hasta',
    TEXTAREA_LABEL: 'Escribir texto',
    TOAST_MESSAGE: 'Alta formulario - Poliza N° 001-332123',
    TOAST_CONFIRM_MESSAGE: 'Continuar',
    TITLE: 'Alta formulario',
    SUBTITLE: `Información correspondiente sobre el formulario que ayude al usuario a completarlo/realizar acciones.`,
    TEXT_AREA: 'Escribiendo...',
    OK_BUTTON: 'Continuar',
    CANCEL_BUTTON: 'Cancelar',
    CHOOSE_IMAGE: 'Elige imagen'
  },
  FORM_SUCCESS: {
    TITLE: '¡Gracias por completar el formulario!',
    SUBTITLE: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.'
  },
  ALERT_ERROR: {
    CANCEL_BUTTON: 'No enviar',
    SEND_BUTTON: 'Enviar reporte',
    SEE_MORE: 'Ver detalle >',
    SEE_LESS: 'Mostrar Menos v',
    TEXTAREA_TITLE: 'Informanos más acerca del error',
    ATTACH_REPORT: 'Adjuntar el error del reporte',
    TEXTAREA_PLACEHOLDER: 'Escribir..'
  },
  USER_CONFIG: {
    MODIFIED_DATA: 'Datos de usuario modificados',
    EMAIL_SENT: 'En breve recibirá un mail en el correo informado. Por favor siga las instrucciones para finalizar el cambio de mail.'
  },
  FILE_UPLOAD: {
    LABEL: 'Elegir archivo adjunto',
    TEXT: 'Seleccionar archivo',
    DEFAULT_FILE: 'Ningún archivo seleccionado'
  },
  TABLE_VIEW: {
    TITLE: 'Nombre de la tabla',
    SUBTITLE: 'Posible descripción de la acción que se hará en esta instancia',
    PREVIOUS_TEXT: 'Anterior',
    NEXT_TEXT: 'Siguiente',
    OF_TEXT: 'de',
    ROWS_TEXT: 'filas',
    PAGE_TEXT: 'Página',
    EXPORT_TEXT: 'Descargar',
    EXPORT_MODAL_TITLE: 'Exportar documento',
    EXPORT_MODAL_BODY: '¿Está seguro que desea exportar las filas seleccionadas?',
    DELETE_MODAL_TITLE: 'Eliminar',
    DELETE_MODAL_BODY: '¿Está seguro que desea eliminar la fila seleccionada?'
  },
  ROUTES: [
    // {name: 'Demo Table and form', link:'/DemoTable'},
    { name: 'Login', link: '/login' },
    { name: 'Form', link: '/form' },
    { name: 'Form loading', link: '/form-loading' },
    { name: 'Form validation', link: '/form-validated' },
    { name: 'Form success', link: '/form-success' },
    { name: 'Cartera', link: '/cartera' },
    { name: 'Envio de Facturas', link: '/envio-facturas' },
    { name: 'Table', link: '/table' },
    { name: 'Table success', link: '/table-success' },
    { name: 'Table error', link: '/table-error' },
    { name: 'Drag files', link: '/drag-files' },
    { name: 'User Dashboard', link: '/user-dashboard-info' },
    { name: 'Generic error', link: '/generic-error' },
    { name: 'Master page', link: '/master-page' },
    { name: 'Deudores por premio', link: '/deudores' }
  ],
  DRAG_AND_DROP: {
    TITLE: 'Arrastra y suelta tus archivos aquí',
    TEXT: 'o utiliza el buscador de archivos...'
  },
  REPORT_FORMAT: {
    TEXTO: 0,
    ENTERO: 1,
    DECIMAL: 2,
    MONEDA: 3,
    FECHA: 4,
    LOGICO: 5
  },

  MASTER_PAGE: {
    subtitle: `Deducí hasta $12000 por año de tu impuesto a las ganancias, mientras asegurás tu vida y el futuro de los que más querés.`,
    sectionTitle: 'Titulo con información',
    sectionDescription: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.'
  },
  USER_DASHBOARD: {
    INFO: {
      legend: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque.',
    },
    LOGOUT_MODAL: {
      TITLE: 'Cerrar sesión',
      BODY: '¿Está seguro que desea salir?'
    },
    SIDEBAR: {
      TITLE: 'Perfil',
      ITEMS: [{
        icon: 'far fa-user',
        label: 'Información',
        link: '/user-dashboard-info'
      },
        // {
        //   icon: 'far fa-bell',
        //   label: 'Notificaciones',
        //   link: '/user-dashboard-notifications'
        // },
        // {
        //   icon: 'fas fa-sign-out-alt',
        //   label: 'Salir',
        //   doBeforeRedirect: true,
        //   link: '/',
        // }
      ]
    },
    TABS: [
      {
        label: 'Opción 1',
      },
      {
        label: 'Opción 2',
      },
      {
        label: 'Opción 3',
      },
      {
        label: 'Opción 4',
      },
      {
        label: 'Opción 5',
      },
      {
        label: 'Opción 6',
      },
      {
        label: 'Opción 7',
      },
      {
        label: 'Opción 8',
        disabled: true
      },
    ],
    NOTIFICATION_TILES: [{
      date: '06 de Julio de 2019',
      text: 'Se ha agregado una nueva poliza al canal X.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 2.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 3.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 4.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 5.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 6.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal elegido opción 7.'
    },
    {
      date: '01 de Julio de 2019',
      text: 'Notificación del canal X.'
    }]
  },
  NOTIFICATIONS_PANEL: {
    TITLE: 'Notificaciones',
    SEE_MORE: 'Mostrar más'
  },
  RANGE_DATEPICKER: {
    START_DATE_PLACEHOLDER: 'Fecha de inicio',
    END_DATE_PLACEHOLDER: 'Fecha de fin'
  },
  GENERIC_ERROR: {
    TITLE: "¡Ha ocurrido un error inesperado!",
    TITLE_MESSAGE: "Lo sentimos, ha ocurrido un error y no se pudo acceder a la página.",
    BUTTON: "Volver al sitio"
  },
  TERMS: {
    TITLE: ' TERMINOS Y CONDICIONES DE USO DEL SITIO "Orígenes PAS – Portal de Autogestión y Servicios"',
    SUBTITLE: 'La presente describe los términos y condiciones generales (en adelante los “Términos y Condiciones”), aplicables a los servicios web (en adelante los “Servicios”) a través del sitio “Orígenes PAS – Portal de Autogestión y Servicios” al que tendrán acceso con clave personal, únicamente los Productores Asesores de Seguros (en adelante, “PAS”) que formen parte de la red comercial de ORIGENES SEGUROS DE RETIRO (en adelante, la Aseguradora).',
    PARAGRAPH: ' Aquel PAS que desee acceder y/o usar el sitio podrá hacerlo sujetándose a los Términos y Condiciones de uso incorporados a la presente, que revisten el carácter de obligatorios y vinculantes y los cuales declara conocer y aceptar.',
    ITEM_1: ' Las consultas y operaciones que se podrán realizar a través de "Orígenes PAS – Portal de Autogestión y Servicios" serán exclusivamente aquellas que se encuentren habilitadas en el Menú de Opciones que aparece en la página inicial del sitio y todas aquellas que se indican en cada una de las opciones seleccionadas.',
    ITEM_2: ' El acceso a "Orígenes PAS – Portal de Autogestión y Servicios" se realizará a través de una computadora personal conectada a Internet o red privada de datos, mediante el ingreso de su nombre de usuario y la Clave de Acceso Personal.  ',
    ITEM_3: ' La Clave de Acceso Personal se enviará a la dirección de correo electrónico declarada por el PAS. Esta clave podrá ser modificada mediante la opción Cambio de Clave del sitio "Orígenes PAS – Portal de Autogestión y Servicios". Los PAS ingresarán con el nombre de usuario y la Clave de Acceso remitida a la dirección de correo electrónico declarada por el PAS, en caso de olvidar dicha clave, ¿se la podrá recuperar ingresando a la opción “Olvidaste la Clave?” en la cual se solicitará el ingreso del tipo, y número de documento, dirección de correo electrónico personal, y pregunta / respuesta secreta.  ',
    ITEM_4: ' La Clave de Acceso Personal es secreta e intransferible y por lo tanto el PAS asume las consecuencias de su divulgación a terceros, liberando a la Aseguradora de toda responsabilidad que de ello se derive. El PAS instruye a la Aseguradora para que ésta acepte e interprete que toda conexión que se efectúe mediante el ingreso de su nombre de usuario y la Clave de Acceso Personal debe entenderse hecha por él y, en consecuencia, cumplidos dichos requisitos, la Aseguradora podrá considerar que tal instrucción ha emanado válida, legítima y auténticamente del PAS, sin necesidad de tomar ningún otro resguardo de índole alguna. El PAS renuncia expresamente a oponer defensa alguna basada en defecto de acreditación de la existencia de la consulta o el uso de su Clave de Acceso Personal asumiendo, como condición esencial de la presente, toda consecuencia jurídica del uso del sistema en su nombre. El ingreso del Usuario o el tipo, y número de documento y juntamente con la Clave de Acceso Personal del PAS validará las operaciones como si hubiesen sido perfeccionadas con su firma.',
    ITEM_5: ' El PAS, declara y acepta que los datos que a través del uso del Servicio sean recopilados por la Aseguradora, sean utilizados de conformidad con el artículo 9 de la Ley 25.326 y su reglamentación.  ',
    ITEM_6: ' El PAS, titular de los datos personales, tiene la facultad de ejercer el derecho de acceso a los mismos en forma gratuita a intervalos no inferiores a seis (6) meses, salvo que se acredite un interés legítimo al efecto, conforme lo establecido en el artículo 14, inciso 3 de la Ley Nº 25.326 (Disposición 10/2008). La Dirección Nacional de Protección de Datos Personales, Órgano de control de la Ley referenciada, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al cumplimiento de las normas sobre protección de datos personales. ',
    ITEM_7: 'El PAS podrá requerir en cualquier momento la actualización, rectificación y/o supresión cuando corresponda de los datos personales de los cuales sea titular, de conformidad con lo dispuesto por el artículo 16 de la Ley Nº 25.326 y su reglamentación.',
    ITEM_8: ' El PAS podrá solicitar información, efectuar operaciones y consultas,  en definitiva, realizar todas aquellas operaciones que estén habilitadas para operar a través del sitio “Orígenes PAS – Portal de Autogestión y Servicios”.',
    ITEM_9: ' El PAS conoce y acepta que existen operaciones y consultas que pueden realizar a través del sitio “Orígenes PAS – Portal de Autogestión y Servicios” y cuya efectiva realización quedará sujeta a la confirmación de la Aseguradora, así como del eventual diferimiento de las operaciones solicitadas a través del sitio "Orígenes PAS – Portal de Autogestión y Servicios" en caso de inconvenientes técnicos.  ',
    ITEM_10: ' Las operaciones y consultas realizadas por el PAS se regirán por las disposiciones establecidas en los Términos y Condiciones, por las normas legales, reglamentarias y estatutarias aplicables y vigentes al momento de su concertación y por los términos y condiciones de las solicitudes y/o contratos que la Aseguradora utilizan habitualmente para instrumentar tales operaciones.  ',
    ITEM_11: 'El PAS se compromete a utilizar el sitio "Orígenes PAS – Portal de Autogestión y Servicios" ajustándose a los Términos y Condiciones que obran en la presente y a las instrucciones impartidas por la Aseguradora.  ',
    ITEM_12: ' La Aseguradora podrá modificar en cualquier momento los términos y condiciones del sitio "Orígenes PAS – Portal de Autogestión y Servicios".',
    ITEM_13: ' El PAS podrá dar de baja su suscripción al sitio "Orígenes PAS – Portal de Autogestión y Servicios". Asimismo, la Aseguradora quedan facultada para suprimir total o parcialmente el servicio del sitio "Orígenes PAS – Portal de Autogestión y Servicios" y/o suspenderlo cuando lo consideren conveniente, sin necesidad de notificación previa de ninguna naturaleza, sirviendo como suficiente aviso la evidencia de tal circunstancia en "Orígenes PAS – Portal de Autogestión y Servicios".',
    ITEM_14: ' El PAS acepta la prueba de la existencia de las órdenes cursadas por él que surjan de los elementos que componen el sistema informático, así como toda prueba que sea hábil para acreditar las operaciones y consultas realizadas a través del sitio "Orígenes PAS – Portal de Autogestión y Servicios". A este efecto el PAS faculta a la Aseguradora a utilizar cualquier sistema idóneo para acreditar la existencia y pertenencia de la orden cursada. El PAS renuncia expresamente a cuestionar la idoneidad o habilidad de esa prueba, constituyendo ésta una condición esencial de adhesión a "Orígenes PAS – Portal de Autogestión y Servicios".',
    ITEM_15: ' Los PAS, adheridos al Sitio web "Orígenes PAS – Portal de Autogestión y Servicios" podrán efectuar cualquier consulta o reclamo relacionado con los Servicios, llamando al 0810-666-8484 de Lunes a Viernes de 9hs-18hs. ',
    ITEM_16: ' Estos Términos y Condiciones estarán regidos en todos sus aspectos por las leyes vigentes en la República Argentina.',
    ITEM_17: ' Cualquier controversia que se suscite con relación a la ejecución, interpretación y/o alcance de estos Términos y Condiciones deberá ser sometida por las partes intervinientes a la jurisdicción de los tribunales ordinarios con asiento en la Ciudad Autónoma de Buenos Aires, República Argentina, haciendo renuncia expresa a cualquier otro fuero o jurisdicción que pudiera corresponder. '
  },
  PARAMETERS: {
    SHOW_DEFAULT_IMAGE_HOME: 'ShowDefaultHomeImg',
  }
};

const IDENTITY_CONFIG = {
  client_id: 'spa',
  redirect_uri: 'http://localhost:5100/#/callback',
  response_type: 'token id_token',
  scope: "openid profile api1",
  silent_redirect_uri: 'http://localhost:5100/silentRenew.html',
  automaticSilentRenew: true,
  filterProtocolClaims: true,
  loadUserInfo: true,
  monitorSession: true
}

// const METADATA_OIDC= {
//   issuer: "https://identityserver",
//   jwks_uri: `${process.env.REACT_APP_AUTH_URL  }/.well-known/openid-configuration/jwks`,
//   authorization_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/authorize`,
//   token_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/token`,
//   userinfo_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/userinfo`,
//   end_session_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/endsession`,
//   check_session_iframe: `${process.env.REACT_APP_AUTH_URL  }/connect/checksession`,
//   revocation_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/revocation`,
//   introspection_endpoint: `${process.env.REACT_APP_AUTH_URL  }/connect/introspect`
// }

export { GLOBAL };

export { IDENTITY_CONFIG };
