import React from 'react';
import userAuth from "../../Config/userauth";
import { APPCONFIG } from '../../app.config';


class HomeAccountManagerInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ejecutivoInfo: null,
      error: null
    };

    this.handleResponseOk = this.handleResponseOk.bind(this);
    this.handleResponseError = this.handleResponseError.bind(this);
  }

  componentDidMount() {
    const userId = userAuth.userId();
    this.loadEjecutivoInfo(userId);
  }

  loadEjecutivoInfo(userId) {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    };
    
    return fetch(PRODUCERURL + "api/producer/GetAccountManagerByUserId?userId=" + userId, requestOptions)
      .then((response) => {
        if (response.ok)
          this.handleResponseOk(response);
        else
          this.handleResponseError(response);
      })
  }


  handleResponseOk(response) {
    response.json()
    .then((data) => {
      if (data && data.success) {
        this.setState({ ejecutivoInfo: data });
      }
      else {
        this.setState({ error: "No se pudo procesar la información" });
      }
    });
  }

  handleResponseError(response) {
    response.text()
    .then((error) => {
      this.setState({ error: error });
    });
  }


  render() {

    return (
      <div className="account-manager-info-container">
        <div className="account-manager-info-form">
          <div className="account-manager-info-form-header">
            <div className="col-12 pt-1">
              <span>Mi ejecutivo de cuentas es</span>
            </div>
          </div>
          {this.state && !this.state.error && this.state.ejecutivoInfo &&
            <div className="row">
                <img className="account-manager-campo-imagen" alt="foto avatar" src={
                  (this.state.ejecutivoInfo.avatar) ? this.state.ejecutivoInfo.avatar : "/static/media/profile-lg.0923e932.png"
                  } />
              <div className="col-12">
                <div className="row pb-3 pt-4">
                  <div className="col-1 account-manager-campo-titulo"><i className="fa fa-user"></i></div>
                  <div className="account-manager-campo-valor">{this.state.ejecutivoInfo.name + " "+ this.state.ejecutivoInfo.lastName}</div>
                </div>
                <div className="row pb-3">
                  <div className="col-1 account-manager-campo-titulo"><i className="fa fa-phone"></i></div>
                  <div className="account-manager-campo-valor">{<a target='_blank' href={`https://wa.me/${this.state.ejecutivoInfo.telephone.replace('+','').replace('-','').replace(/ /g,'')}`}>{this.state.ejecutivoInfo.telephone}</a>}</div>
                </div>
                <div className="row pb-3">
                  <div className="col-1 account-manager-campo-titulo"><i className="fa fa-envelope"></i></div>
                  <div className="account-manager-campo-valor">{this.state.ejecutivoInfo.email}</div>
                </div>
              </div>
            </div>
          }
          {this.state && this.state.error && !this.state.ejecutivoInfo &&
            <div>
              <div className="row pb-3 pt-4">
                <div className="col-1"></div>
                <div className="col-10 account-manager-campo-error">{this.state.error}</div>
                <div className="col-1"></div>
              </div>
            </div>
          }
        </div>
      </div>
    );

  }

};

export default HomeAccountManagerInfo;
