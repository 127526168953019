/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { Breadcrumb, Button, Alert, Loading, Input, Combobox, DatePicker } from '../../Components';
import { GLOBAL } from '../../Config/global';
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';


class LibreDeuda extends React.Component {

	constructor(props) {
		super(props);

		const toDay = new Date();
		const toDayString = toDay.getFullYear() + "/" + (toDay.getMonth() + 1) + "/" + toDay.getDate()

		this.itemInit = {
			empresa: 'VT',
			poliza: '',
			certificado: '',
			fecha: toDayString
		};
		
		this.state = {
			error: '',
			success: '',
			loadingForm: true,
			item: this.itemInit,
			periodos: [],
			periodoSelected: {
				'value': '',
				'label': ''
			},
			searchCriteria: QLIBS.getPeriod()

		};

		/// ERRORS
		this.errorEmpresa = "";
		this.errorPoliza = "";
		this.errorCertificado = "";
		this.errorFecha = "";

		/// FUNCIONES
		this.handleComboChange = this.handleComboChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
		this.handleDatePickerChange = this.handleDatePickerChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponseOk = this.handleResponseOk.bind(this);
		this.handleResponseError = this.handleResponseError.bind(this);
		this.procesarLibreDeuda = this.procesarLibreDeuda.bind(this);
	}

	componentDidMount() {
		const d1 = new Date(2021, 7, 1);
		let d2 = new Date();
		d2.setDate(d2.getDate()-1);
		const periodRange = QLIBS.getPeriodRange(d1, d2, true);
		const dataCombo = periodRange.map(period => ({ id: period, value: QLIBS.getPeriodName(period) }));
		this.setState({ ['periodos']: dataCombo ,loadingForm: false}, () => { });
	}


	handleComboChange = (selectedOption, event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let inputName = event.name;
		let inputValue = selectedOption.value;

		this.errorEmpresa = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
		this.errorEmpresaBorderColor = (this.errorEmpresa) ? "error" : "success";

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}
	handleInputChange = (event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let inputName = event.target.name;
		let inputValue = event.target.value;

		switch (inputName) {
			case "poliza":
				this.errorPoliza = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
				this.errorPolizaBorderColor = (this.errorPoliza) ? "error" : "success";
				break;
			case "certificado":
				this.errorCertificado = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
				this.errorCertificadoBorderColor = (this.errorCertificado) ? "error" : "success";
				break;
			default:
				break;
		}

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}

	handleInputKeyPress = (event) => {
		var code = event.which || event.keyCode;
		if (code < 48 || code > 57) {
			event.preventDefault();
		}
	}
	handleDateChange = (selectedOption, event)=>{
		let inputName = event.name;
		let inputValue = selectedOption.value;
		let inputString = selectedOption.label;
		let searchCriteria = this.state.searchCriteria;

		switch (inputName) {
			case "vigencia":
				searchCriteria = inputValue;
				break;
			default:
				break;
		}
		
		this.setState({
			searchCriteria: searchCriteria
		}, () => {
		});
		console.log(searchCriteria)
	}
	handleDatePickerChange = (event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;
		let inputName = event.target.id;
		let inputValue = event.target.value;

		this.errorFecha = (!event.target.isValid) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
		this.errorCertificadoBorderColor = (this.errorFecha) ? "error" : "success";

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { DEBT_FREE } = GLOBAL;
		const item = this.state.item;

		if (item.empresa.length > 0 &&
			item.poliza.length > 0 &&
			item.certificado.length > 0 &&
			item.fecha.length > 0)
		{
			this.setState({ error: "" });
			this.setState({ success: false });
			this.procesarLibreDeuda();
		}
		else
		{
			this.setState({ error: DEBT_FREE.VALIDATION_ERROR });
		}
	}

	handleResponseOk = (response,nombreCertificado) => {
		let self = this;
		response.blob()
		.then(function (blob) {
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${nombreCertificado}.pdf`;
			link.click();
		})
		.then(() => {
			self.setState({ loadingForm: false, item: self.itemInit, success: true });
		});
	}

	handleResponseError = (response) => {
		let self = this;
		response.text()
		.then(function (message) {
			self.setState({ loadingForm: false, error: message });
		})
	}

	procesarLibreDeuda() {
		
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        var userId = userAuth.userId();

		this.setState({ loadingForm: true });
			
		const item = this.state.item;
		const dateName = `${this.state.periodos.filter(x=>x.id ==this.state.searchCriteria).map(x=>x.value)}`
		const nombreCertificado = `Certificado-Libre-deuda-${item.poliza}-${item.certificado}-${dateName.replace(', ','-')}`;
		const parameters = JSON.stringify({
			Empresa: item.empresa,
			Poliza: item.poliza,
			Certificado: item.certificado,
			Fecha: this.state.searchCriteria,
			UserId: userId 
		});
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: `{"Report": "DebtFree", "FileName": "libre-deuda.pdf", "ContentType": "application/pdf", "Parameters": "${parameters.replace(/['"]+/g, '\\"')}"}`
		};

		fetch(`${PRODUCERURL}debtors/downloaddebtfree`, requestOptions)
		.then(response => {
			if (response.ok)
				this.handleResponseOk(response, nombreCertificado);
			else
				this.handleResponseError(response);
		})
		.catch(error => {
			this.setState({ error: `Error: ${error.message}` });
		});
	}


	render(...props) {
		const { DEBT_FREE, PAGES } = GLOBAL;
	
		return (
			<>
				<Loading visible={this.state.loadingForm}></Loading>
				<section className='form-container master-page libreDeuda'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<div className="col-12">
									<Breadcrumb currentPage="/libredeuda" />
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<h4 className="form-title">{PAGES.TITLE.DEBT_FREE}</h4>
										</div>
									</div>
								</div>
								<div className="col-12">
									{
										(this.state.error) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													type="error"
													icon="info"
													title={this.state.error}
													message=""
													noDetail={true}
												/>
											</div>
										</div>
									}
									{
										(this.state.success) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													type="success"
													title={"Certificado de Libre deuda generado con éxito"}
													message=""
													noDetail={true}
												/>
											</div>
										</div>
									}
								</div>
								<form id="libre_deuda_form" onSubmit={this.handleSubmit} className="libre_deuda__Form">
									<section>
										
										<div className="mt-4">
											<div className="row">
												<div className="col-4">
													<Combobox
																	id="vigencia"
																	label="Período del libre deuda"
																	options={this.state.periodos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleDateChange(v, e); }}
																	defaultValue={this.state.searchCriteria}
																/>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<Combobox
																	id="vigencia"
																	label="Período del libre deuda"
																	options={this.state.periodos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleDateChange(v, e); }}
																	defaultValue={this.state.searchCriteria}
																/>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<Input
														type={'test'}
														id={'poliza'}
														text={'Nro. de póliza'}
														placeholder={''}
														errorMsg={this.errorPoliza}
														borderColor={this.errorPolizaBorderColor}
														value={this.state.item.poliza}
														onChange={this.handleInputChange}
														onKeyPress={this.handleInputKeyPress}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<Input
														type={'test'}
														id={'certificado'}
														text={'Nro. de certificado'}
														placeholder={''}
														errorMsg={this.errorCertificado}
														borderColor={this.errorCertificadoBorderColor}
														value={this.state.item.certificado}
														onChange={this.handleInputChange}
														onKeyPress={this.handleInputKeyPress}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-4 pt-5">
													<Button type="submit" onClick={this.handleSubmit}>{FORM_DATA.GET_DEBT_FREE}</Button>
												</div>
											</div>
										</div>
									</section>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(LibreDeuda);
