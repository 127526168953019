import React, { useEffect, useState } from "react";
import { object, string } from "prop-types";
import { GLOBAL } from "../../Config/global";
import { MOCK_DATA } from "../../Config/mock-data";

import {
  Table as TableComponent,
  ConfirmationModal
} from "..";
import { requestData } from "../Table/utils";

const PrimaryTableUsers = ({ className, history }) => {
  const { TABLES } = MOCK_DATA;

  const [editingRow] = useState(null);
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [tableElementRef, setTableElementRef] = useState(null);
  const [rowModel, setRowModel] = useState({
    loading: false,
    pages: null,
    data: TABLES.makeData(),
    sorted: []
  });

  const toggleAll = () => {
    if (selectAll) {
      const wrappedInstance = tableElementRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      setSelection(
        currentRecords.map(record => `select-${record._original.id}`)
      );
    } else {
      setSelection([]);
    }
  };
  const toggleAllSelection = toggleAll;

  const toggleSingleSelection = key => {
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      setSelection([
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ]);
    } else {
      setSelection([...selection, key]);
    }
  };

  const isSelected = key => selection.includes(`select-${key}`);

  const isEditing = key => editingRow && editingRow.index === key;
    const defaultSortingMethod = (filter, row) => {
    return row._original[filter.id]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  };
    const renderSelectInputComponent = ({
    checked,
    id,
    onClick,
    row: { id: rowId }
  }) =>
    rowId % 2 === 0 && ( // EXAMPLE, only even rows have checkbox selection enabled
      <input
        type="checkbox"
        defaultChecked={checked}
        onClick={() => onClick(id)}
      />
    );

  // eslint-disable-next-line no-nested-ternary
  const getSelectedRowProps = rowInfo =>
    rowInfo && isSelected(rowInfo.original.id)
      ? "-selected"
      : rowInfo && selection.length && !isSelected(rowInfo.original.id)
      ? "-disabled"
      : "";

  // eslint-disable-next-line no-nested-ternary
  const getEditingRowProps = rowInfo =>
    rowInfo && isEditing(rowInfo.index)
      ? "-editing"
      : rowInfo && editingRow && !isEditing(rowInfo.index)
      ? "-disabled"
      : "";
    const getRowProps = (state, rowInfo) => ({
    className: `${getSelectedRowProps(rowInfo)} ${getEditingRowProps(rowInfo)}`
  });

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleExportModal = () => {
    setShowExportModal(!showExportModal);
  };

  const onSuccessRedirect = () => {
    history.push("/table-success");
  };

  const handleFetchData = (state, instance) => {
    setRowModel({
      ...rowModel,
      loading: true
    });

    requestData(
      instance.state.pageSize,
      instance.state.page,
      instance.state.sorted,
      instance.state.filtered
    ).then(res => {
      return setRowModel({
        ...rowModel,
        data: res.rows,
        pages: res.pages,
        loading: false
      });
    });
  };

  const handleSortedChange = (newSort) => {
    return setRowModel({
      ...rowModel,
      sorted: newSort
    });
  };

  useEffect(() => {
    toggleAllSelection();
  }, [selectAll, toggleAllSelection]);

  return (
    <>
      {showDeleteModal && (
        <ConfirmationModal
          message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
          status="warning"
          title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
          onDismiss={toggleDeleteModal}
          onConfirm={onSuccessRedirect}
        />
      )}
      {showExportModal && (
        <ConfirmationModal
          message={GLOBAL.TABLE_VIEW.EXPORT_MODAL_BODY}
          title={GLOBAL.TABLE_VIEW.EXPORT_MODAL_TITLE}
          status="primary"
          onDismiss={toggleExportModal}
          onConfirm={onSuccessRedirect}
        />
      )}
      <TableComponent
        className={className}
        data={[
          {
              dni:"29872780",
              nombre:"santiago",
              apellido:"ojeda",
              edad:37
          },
          {
              dni:"29872780",
              nombre:"gfgf",
              apellido:"ojeda",
              edad:37
          },
          {
              dni:"29872780",
              nombre:"santigfgfgago",
              apellido:"ojeda",
              edad:37
          },
          {
              dni:"29872780",
              nombre:"ewewew",
              apellido:"ojeda",
              edad:37
          }
      ]}
        defaultSortingMethod={defaultSortingMethod}
        handleToggleAllSelection={toggleAllSelection}
        handleToggleSingleSelection={toggleSingleSelection}
        handleSetSelectAll={setSelectAll}
        selectAll={selectAll}
        isSelected={isSelected}
        getRowProps={getRowProps}
        selectInputComponent={renderSelectInputComponent}
        getTableRef={setTableElementRef}

        loading={rowModel.loading}
        sorted={rowModel.sorted}
        pages={rowModel.pages}
        onSortedChange={handleSortedChange}
        onFetchData={handleFetchData}
        columns={[
          {
              Header: "DNI",
              accessor: "dni",
              editable: true,
              resizable: true,
              filterable: true
          },
          {
              Header: "NOMBRE",
              accessor: "nombre",

              filterable: false
          },
          {
              Header: "APELLIDO",
              accessor: "apellido",
              editable: true,
              resizable: true
          },
          {
              Header: "EDAD",
              accessor: "edad",
              editable: true,
              resizable: true
          }
     ]}
      />
    </>
  );
};

PrimaryTableUsers.propTypes = {
  className: string,
  history: object.isRequired
};

PrimaryTableUsers.defaultProps = {
  className: "-striped -highlight"
};

export default PrimaryTableUsers;
