/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Button,
	DatePicker,
	Input,
	Loading,
	Table as TableComponent,
	Combobox,
	ConfirmationModal,
	CommunicationModal
} from "../../Components";
import Actions from "../../Components/GridComponents/Actions";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import { FORM_DATA } from "../../Config/form_data";
import "font-awesome/css/font-awesome.min.css";
import { APPCONFIG } from '../../app.config';
import moment from "moment";
import "moment/locale/es";
import { truncate } from '../../Shared/Utils';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';

class AdminComunicaciones extends React.Component {
	
	constructor(props) {
		super(props);

		this.state = {
			showDeleteModal: false,
			showCommunicationModal: false,
			showGrid: false,
			rowId: 0,
			loadingData: false
		};
		this.searchResultsError = "";

		this.detailsColumn = {
			Header: "",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<>
					<Actions
						icon="fa fa-edit"
						onDownload={() => this.detailsItem(row)}
					/>
					<Actions
						icon="fa fa-remove"
						onDownload={() => this.removeItem(row)}
					/>
				</>
			)
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.listTableColumns = this.createListTableColumns();

		this.loadGrid = this.loadGrid.bind(this);
		this.deleteCommunication = this.deleteCommunication.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleMuevo = this.handleMuevo.bind(this);
		this.detailsItem = this.detailsItem.bind(this);
		this.removeItem = this.removeItem.bind(this);
		this.exitDeleteModal = this.exitDeleteModal.bind(this);
		this.exitCommunicationModal = this.exitCommunicationModal.bind(this);
	}

	componentDidMount() {

		this.handleResponse([]);
		this.loadGrid();

	}

	detailsItem = event => {
		this.setState({ rowId: event.row._original.Id, showCommunicationModal: true });
	}

	removeItem = event => {
		this.setState({ rowId: event.row._original.Id, showDeleteModal: true });
	}

	deleteCommunication = () => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.searchResultsError = "";

		this.setState({loadingData: true });

		const id = this.state.rowId;

		// Parse data to send
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};				
		return fetch(`${PRODUCERURL}api/Communication/DeleteCommunication?Id=${id}`, requestOptions)
		.then((response) => {
			if (response.ok) {
				this.loadGrid();
			}
			else {
				this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
				this.setState({loadingData: false, showGrid: false });
			}
		});
	}

	loadGrid = () => {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
		this.searchResultsError = "";

		this.setState({loadingData: true });

		// Parse data to send
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};				
		return fetch(`${PRODUCERURL}api/Communication/GetCommunications`, requestOptions)
		.then((response) => {
			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then(this.handleResponse)
				.catch(this.handleResponse);
			}
			else {
				this.searchResultsError = `Error procesando solicitud: ${response.statusText}`;
				this.setState({loadingData: false, showGrid: false });
			}

		  })
	}

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;

		let tableData = [];
		if (Array.isArray(response)) {
			this.searchResultsError = "";

			moment.locale("es");
			tableData = response.map(function (item) {

				return {
					...item,
					SendMailDesc: (item.SendMail) ? 'Sí' : 'No',
					ConfirmationRequiredDesc: (item.ConfirmationRequired) ? 'Sí' : 'No',
					StartDateDesc: moment(item.StartDate).format("DD/MM/YYYY"),
					EndDateDesc: (item.EndDate) ? moment(item.EndDate).format("DD/MM/YYYY") : 'Indeterminado',
					EnabledDesc: (item.Enabled) ? 'Sí' : 'No',
					PriorityDesc: (item.Priority == 1) ? 'Baja' : (item.Priority == 2) ? 'Regular' : (item.Priority == 3) ? 'Alta' : null
				}
			});

		}
		else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
		}

		this.listTableColumns = this.createListTableColumns();

		this.searchTableData = {
			makeData: () => {
				return tableData
			}
		};

		this.setState({ name: this.state.name, loadingData: false, showGrid: true });
	}


	createListTableColumns() {
		const newTableColumns = [this.detailsColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"Description": { title: "Descripción", field: "Description", show: true },
			"StartDateDesc": { title: "Desde", field: "StartDateDesc", show: true, width: 150 },
			"EndDateDesc": { title: "Hasta", field: "EndDateDesc", show: true, width: 150 },
			"PriorityDesc": { title: "Prioridad", field: "PriorityDesc", show: true, width: 150 },
			"ConfirmationRequiredDesc": { title: "Requiere confirmación", field:"ConfirmationRequired", show: true, width: 150 },
			"EnabledDesc": { title: "Habilitado", field:"EnabledDesc", show: true, width: 140 }
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}


	exitDeleteModal(confirm) {
		this.setState({ showDeleteModal: false });
		if (confirm) {
			this.deleteCommunication();
		}
	}

	exitCommunicationModal(reload) {
		this.setState({ showCommunicationModal: false });
		if (reload) {
			this.loadGrid();
		}
	}

	handleMuevo() {
		this.setState({ rowId: 0, showCommunicationModal: true });
	}

	render(...props) {
		const { TABLE_VIEW } = GLOBAL;
		const { PAGES } = GLOBAL;

		return (
			<>
			
				{this.state.showDeleteModal && (
					<ConfirmationModal
						message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
						status="primary"
						title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
						onDismiss={() => { this.exitDeleteModal(false) }}
						onConfirm={() => { this.exitDeleteModal(true) }}
					/>
				)}

				{this.state.showCommunicationModal && (
					<CommunicationModal
						Id={this.state.rowId}
						dismiss={(reload) => { this.exitCommunicationModal(reload) }}
					/>
				)}
				
				<Loading visible={this.state.loadingData}></Loading>
				
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/admincomunicaciones" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.ADMINCOMMUNICATION}</h4>
						</div>
						<div className="form-container container table-view col-md-12 mt-2">

							{this.searchResultsError && (
								<div id="form-error-message" className="row my-2">
									<div className="col-12">
										<Alert
											icon="times"
											message=""
											title={this.searchResultsError}
											errorInfo={this.searchResultsError}
											type="error"
										/>
									</div>
								</div>
							)}
							<div className="row col-12 my-3">
								<div className="search-buttons">
									<Button type="button" id="btnNuevo" onClick={() => { this.handleMuevo() }} classes="to-bottom button-small search-button"><span className="fa fa-plus"> </span> Nueva Comunicación</Button>
								</div>
							</div>
							{this.state.showGrid && (
								<div className="row my-3">
									<div className="col-12 px-3">
										{<TableComponent
											columns={this.listTableColumns}
											data={this.searchTableData.makeData()}
										/>}
									</div>
								</div>
							)}
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default AdminComunicaciones;