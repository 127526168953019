/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Loading, Alert, Input, Checkbox, Select, BackButton, Button, Header, FormTitle } from "../../Components";
import registerImage from "../../Resources/Images/FormImages/pantalla-login.jpg";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL } from "../../Config/global";
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import Script from "react-load-script";
import { Link, Redirect, withRouter } from 'react-router-dom';
import { APPCONFIG } from '../../app.config';

class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      redirect: null
    }

    this.nombre = "";
    this.apellido = "";
    this.cuit = "";
    this.email = "";
    this.telefono = "";
    this.compania = "";
    this.password = "";
    this.confirmacion = "";
    this.terminos = true;
    this.formError = false;

    this.formValidated = false;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  handleKeyDown = event => {
    // Chek which field
    switch (event.target.id) {
      case "nombre":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
          event.preventDefault();
        }
        break;

      case "apellido":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
          event.preventDefault();
        }
        break;

      case "email":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105) &&
          (event.keyCode < 192 || event.keyCode > 252) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 190) {
          event.preventDefault();
        }
        break;

      case "telefono":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "password":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      case "confirmacion":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
          event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105)) {
          event.preventDefault();
        }
        break;

      default:
        // Do Nothing
        break;
    }
  }

  handleChange = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { REGISTER_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    // Chek which field
    switch (event.target.id) {
      case "nombre":
        if (event.target.value.length >= 3) {
          this.nombreErrorIcon = InputCheck;
          this.nombreErrorBorderColor = "success";
        } else {
          this.nombreErrorIcon = "";
          this.nombreErrorBorderColor = "";
        }

        break;

      case "apellido":
        if (event.target.value.length >= 3) {
          this.apellidoErrorIcon = InputCheck;
          this.apellidoErrorBorderColor = "success";
        } else {
          this.apellidoErrorIcon = "";
          this.apellidoErrorBorderColor = "";
        }

        break;

      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";
        this.cuit = this.cuit.trim();

        // Validate data
        this.cuitValid = ValidarCuit(this.cuit);
        if (this.cuitValid) {
          this.cuitError = "";
          this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
          this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
        } else {
          this.cuitErrorIcon = InputError;
          this.cuitErrorBorderColor = "error";
        }

        if (this.cuit.length <= 0) {
          this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
        } else if (!this.cuitValid) {
          this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
        }
        break;

      case "email":
        if (checkEmail(event.target.value)) {
          this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
          this.emailErrorBorderColor = (this.emailError) ? "error" : "success";
        } else {
          this.emailErrorIcon = "";
          this.emailErrorBorderColor = "";
        }
        break;

      case "telefono":
        if (!isNaN(event.target.value) && event.target.value.length >= 8) {
          this.telefonoErrorIcon = InputCheck;
          this.telefonoErrorBorderColor = "success";
        } else {
          this.telefonoErrorIcon = "";
          this.telefonoErrorBorderColor = "";
        }
        break;

      case "compania":
        if (event.index > 0) {
          this.companiaError = "";
          this.companiaErrorBorderColor = ["success"];
          this.companiaErrorIcon = InputCheck;
        } else {
          this.companiaError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
          this.companiaErrorBorderColor = ["error"];
          this.companiaErrorIcon = InputError;
        }


        break;

      case "password":
        if (event.target.value.length >= 6 && checkPassword(event.target.value)) {
          this.password = event.target.value;
          this.passwordError = "";
          this.passwordErrorIcon = InputCheck;
          this.passwordErrorBorderColor = "success";
        } else {
          this.passwordError = "";
          this.passwordErrorIcon = "";
          this.passwordErrorBorderColor = "";
        }
        break;

      case "confirmacion":
        if (event.target.value.length >= 6 && checkPassword(event.target.value) && event.target.value === this.password) {
          this.confirmacionError = "";
          this.confirmacionErrorIcon = InputCheck;
          this.confirmacionErrorBorderColor = "success";
        } else {
          this.confirmacionError = "";
          this.confirmacionErrorIcon = "";
          this.confirmacionErrorBorderColor = "";
        }
        break;

      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name });
  }

  handleInputBlur = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { REGISTER_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    // Chek which field
    switch (event.target.id) {
      case "nombre":
        this.nombreErrorIcon = (event.target.value.length >= 2) ? InputCheck : InputError;
        this.nombreErrorBorderColor = (event.target.value.length >= 2) ? "success" : "error";
        this.nombreError = (event.target.value.length < 2) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD : "";
        break;

      case "apellido":
        this.apellidoErrorIcon = (event.target.value.length >= 2) ? InputCheck : InputError;
        this.apellidoErrorBorderColor = (event.target.value.length >= 2) ? "success" : "error";
        this.apellidoError = (event.target.value.length < 2) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD : "";
        break;

      case "email":
        var isValidEmail = checkEmail(event.target.value);
        this.emailErrorIcon = (isValidEmail) ? InputCheck : InputError;
        this.emailErrorBorderColor = (isValidEmail) ? "success" : "error";
        this.emailError = (isValidEmail) ? "" : COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMAIL_INVALID;
        break;

      case "telefono":
        if (!isNaN(event.target.value) && event.target.value.length >= 8) {
          this.telefonoErrorIcon = InputCheck;
          this.telefonoErrorBorderColor = "success";
          this.telefonoError = "";
        } else {
          this.telefonoErrorIcon = InputError;
          this.telefonoErrorBorderColor = "error";
          this.telefonoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_8;
        }
        break;

      case "password":
        if (event.target.value.length <= 0) {
          this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
          this.passwordErrorIcon = InputError;
          this.passwordErrorBorderColor = "error";
        } else {
          if (event.target.value.length < 6) {
            this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
            this.passwordErrorIcon = InputError;
            this.passwordErrorBorderColor = "error";
          } else if (!checkPassword(event.target.value)) {
            this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
            this.passwordErrorIcon = InputError;
            this.passwordErrorBorderColor = "error";
          } else {
            this.password = event.target.value;
            this.passwordError = "";
            this.passwordErrorIcon = InputCheck;
            this.passwordErrorBorderColor = "success";
          }
        }
        break;

      case "confirmacion":
        if (event.target.value.length <= 0) {
          this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
          this.confirmacionErrorIcon = InputError;
          this.confirmacionErrorBorderColor = "error";
        } else {
          if (event.target.value.length < 6) {
            this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
            this.confirmacionErrorIcon = InputError;
            this.confirmacionErrorBorderColor = "error";
          } else if (!checkPassword(event.target.value)) {
            this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
            this.confirmacionErrorIcon = InputError;
            this.confirmacionErrorBorderColor = "error";
          } else if (event.target.value !== this.password) {
            this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_DONT_MATCH;
            this.confirmacionErrorIcon = InputError;
            this.confirmacionErrorBorderColor = "error";
          } else {
            this.confirmacionError = "";
            this.confirmacionErrorIcon = InputCheck;
            this.confirmacionErrorBorderColor = "success";
          }
        }
        break;

      default:
        // Do Nothing
        break;
    }

    this.setState({ name: this.state.name });
  }

  handleSubmit = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { REGISTER_FORM } = GLOBAL;
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    event.preventDefault();
    const data = new FormData(event.target);

    this.nombre = event.target.nombre.value.toUpperCase();
    this.apellido = event.target.apellido.value.toUpperCase();
    this.cuit = event.target.cuit.value;
    this.email = event.target.email.value;
    this.telefono = event.target.telefono.value;
    this.compania = event.target.compania.value;
    this.password = event.target.password.value;
    this.confirmacion = event.target.confirmacion.value;
    this.terminos = event.target.terminos.checked;
    this.formError = false;
    this.formValidated = true;

    this.nombreError = "";
    this.apellidoError = "";
    this.cuitError = "";
    this.emailError = "";
    this.telefonoError = "";
    this.companiaError = "";
    this.passwordError = "";
    this.confirmacionError = "";

    this.nombreErrorIcon = "";
    this.apellidoErrorIcon = "";
    this.cuitErrorIcon = "";
    this.emailErrorIcon = "";
    this.telefonoErrorIcon = "";
    this.companiaErrorIcon = "";
    this.passwordErrorIcon = "";
    this.confirmacionErrorIcon = "";

    this.nombreErrorBorderColor = "";
    this.apellidoErrorBorderColor = "";
    this.cuitErrorBorderColor = "";
    this.emailErrorBorderColor = "";
    this.telefonoErrorBorderColor = "";
    this.companiaErrorBorderColor = "";
    this.passwordErrorBorderColor = "";
    this.confirmacionErrorBorderColor = "";

    // First validation
    if (!this.nombre) {
      this.nombreError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.apellido) {
      this.apellidoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.cuit) {
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.email) {
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.telefono) {
      this.telefonoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.compania || this.compania <= 0) {
      this.companiaErrorBorderColor = ["error"];
      this.companiaError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
    }
    if (!this.password) {
      this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.confirmacion) {
      this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    // Special Validation
    if (!this.nombreError && this.nombre.length < 2) {
      this.nombreError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_2;
    }
    if (!this.apellidoError && this.apellido.length < 2) {
      this.apellidoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_2;
    }
    if (!this.cuitError) {
      // Remove "-"
      this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";

      // Validate data
      this.cuitValid = ValidarCuit(this.cuit);
      if (!this.cuitValid) {
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    }
    if (!this.emailError && !checkEmail(this.email)) {
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMAIL_INVALID;
    }
    if (!this.telefonoError && this.telefono.length < 8) {
      this.telefonoError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_8;
    }
    if (!this.passwordError) {
      if (this.password.length < 6) {
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      } else if (!checkPassword(this.password)) {
        this.passwordError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      }
    }
    if (!this.confirmacionError) {
      if (this.confirmacion.length < 6) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
      } else if (!checkPassword(this.password)) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
      } else if (this.password !== this.confirmacion) {
        this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_DONT_MATCH;
      }
    }

    this.formError = this.nombreError || this.apellidoError || this.cuitError || this.emailError || this.telefonoError || this.companiaError || this.passwordError || this.confirmacionError;
    if (this.formError) {
      this.nombreErrorIcon = (this.nombreError) ? InputError : InputCheck;
      this.nombreErrorBorderColor = (this.nombreError) ? "error" : "success";
      this.apellidoErrorIcon = (this.apellidoError) ? InputError : InputCheck;
      this.apellidoErrorBorderColor = (this.apellidoError) ? "error" : "success";
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
      this.emailErrorBorderColor = (this.emailError) ? "error" : "success";
      this.telefonoErrorIcon = (this.telefonoError) ? InputError : InputCheck;
      this.telefonoErrorBorderColor = (this.telefonoError) ? "error" : "success";
      this.passwordErrorIcon = (this.passwordError) ? InputError : InputCheck;
      this.passwordErrorBorderColor = (this.passwordError) ? "error" : "success";
      this.confirmacionErrorIcon = (this.confirmacionError) ? InputError : InputCheck;
      this.confirmacionErrorBorderColor = (this.confirmacionError) ? "error" : "success";

      this.loadingForm = false;
      this.setState({ name: this.state.name })

    } else if (!this.terminos) {
      // No acepto Terminos y Condiciones
      this.loadingForm = false;
      this.setState({ name: this.state.name })

    } else {
      this.loadingForm = true;
      this.setState({ name: this.state.name })

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Application: GLOBAL.APPNAME,
          Name: this.nombre,
          LastName: this.apellido,
          CUIT: this.cuit,
          Password: this.password,
          Email: this.email,
          Telephone: this.telefono,
          TelephoneCompany: this.compania
        })
      };

      return fetch(PRODUCERURL + "api/login/register", requestOptions)
        .then((response) => {
          return response.json()
        })
        .then(this.handleResponse)
        .catch(this.handleResponse)
        ;
    }
  }

  handleResponse(response) {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { REGISTER_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    if (response && response.success) {
      if (response.isRegistred) {
        //console.log(response.coreName);
        //document.location.href = "./register-validation";
        this.setState({
          redirect: "/register-validation"
        });
      } else {
        if (response.businessExceptions.length) {
          this.formError = response.businessExceptions.map(x => x.message).join(". ");
        }
        else {
          this.formError = COMMON_ERRORS.REQUEST_DEFAULT;
        }
      }
    } else {
      this.formError = Object.keys(response).length && response[Object.keys(response)].toString().length ?
        response[Object.keys(response)].toString() :
        COMMON_ERRORS.REQUEST_DEFAULT;
    }

    this.loadingForm = false;
    this.setState({ name: this.state.name });
  }

  render() {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { REGISTER_FORM } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <section>
        <Loading visible={this.loadingForm}></Loading>
        <Header centered />
        <section className="MainContainer register">
          <div className="container MainContainer__Center">
            <div className="row MainContainer__Card rounded">
              <div className="col-lg-6 px-0 d-none d-lg-block divExpandImg">
                <img src={registerImage} className="img-fluid imgExpand" alt="" />
              </div>
              <div className="col-lg-1 register__Back d-md-block d-none">
                <BackButton />
              </div>
              <div className="col-lg-4 my-auto p-0">
                <Script
                  url="/scripts/form_validation.js"
                />
                <form id="register_form" onSubmit={this.handleSubmit} className="register__Form" noValidate>
                  <FormTitle
                    customClass="register__FormTitle"
                    text="Registro de usuario nuevo"
                  />
                  <div className="container">
                    {
                      (this.formValidated && this.formError) &&
                      <div id="form-error-message" className="row my-2">
                        <div className="col-12">
                          <Alert
                            icon="times"
                            message={this.formError}
                            title={COMPONENTS_FORM.ALERT_ERROR_TITLE}
                            type="errorMessage"
                          />
                        </div>
                      </div>
                    }
                    {
                      (this.formValidated && !this.formError && !this.terminos) &&
                      <div id="form-error-message" className="row my-2">
                        <div className="col-12">
                          <Alert
                            icon="times"
                            message=""
                            title={COMMON_ERRORS.TERMS_UNCHECKED}
                            type="errorMessage"
                          />
                        </div>
                      </div>
                    }
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          id="nombre"
                          type="text"
                          text={REGISTER_FORM.INPUTS.NAMES}
                          defaultValue=""
                          maxLength="50"
                          errorMsg={this.nombreError}
                          borderColor={this.nombreErrorBorderColor}
                          imgSrc={this.nombreErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          id="apellido"
                          type="text"
                          text={REGISTER_FORM.INPUTS.LAST_NAMES}
                          defaultValue=""
                          maxLength="50"
                          errorMsg={this.apellidoError}
                          borderColor={this.apellidoErrorBorderColor}
                          imgSrc={this.apellidoErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          id="cuit"
                          type="text"
                          text={REGISTER_FORM.INPUTS.CUIT}
                          defaultValue=""
                          mask="99-99999999-9"
                          errorMsg={this.cuitError}
                          borderColor={this.cuitErrorBorderColor}
                          imgSrc={this.cuitErrorIcon}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          id="email"
                          type="text"
                          text={REGISTER_FORM.INPUTS.EMAIL}
                          defaultValue=""
                          maxLength="100"
                          errorMsg={this.emailError}
                          borderColor={this.emailErrorBorderColor}
                          imgSrc={this.emailErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          id="telefono"
                          type="text"
                          text={REGISTER_FORM.INPUTS.PHONE}
                          defaultValue=""
                          maxLength="15"
                          errorMsg={this.telefonoError}
                          borderColor={this.telefonoErrorBorderColor}
                          imgSrc={this.telefonoErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <Select
                          id="compania"
                          text={REGISTER_FORM.INPUTS.PHONE_COMPANY}
                          defaultValue={FORM_DATA.SELECT_OPTION}
                          options={FORM_DATA.PHONE_COMPANIES}
                          errorMsg={this.companiaError}
                          status={this.companiaErrorBorderColor}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          id="password"
                          type="password"
                          text={REGISTER_FORM.INPUTS.PASSWORD}
                          defaultValue=""
                          maxLength="15"
                          smallPrint={REGISTER_FORM.PASS_INSTRUCTIONS}
                          errorMsg={this.passwordError}
                          borderColor={this.passwordErrorBorderColor}
                          imgSrc={this.passwordErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          id="confirmacion"
                          type="password"
                          text={REGISTER_FORM.INPUTS.CONFIRM_PASSWORD}
                          defaultValue=""
                          maxLength="15"
                          smallPrint={REGISTER_FORM.CONFIRM_INSTRUCTIONS}
                          errorMsg={this.confirmacionError}
                          borderColor={this.confirmacionErrorBorderColor}
                          imgSrc={this.confirmacionErrorIcon}
                          onKeyDown={this.handleKeyDown}
                          onBlur={this.handleInputBlur}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 register__Terms">
                        <Checkbox
                          id="terminos"
                          text={REGISTER_FORM.HAVE_READ}
                        />
                        <Link to="/terminos" target="_blank">
                          {REGISTER_FORM.TERMS_AND_CONDITIONS}
                        </Link>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        {this.terminos ? null : (
                          <div className="input"><span className={`input-span-error`}>Debe aceptar los términos y condiciones</span></div>
                        )}
                      </div>
                    </div>

                    <div className="m-bottom">
                      <Button type="submit">{REGISTER_FORM.BUTTON_TEXT}</Button>
                    </div>
                  </div>
                </form>

              </div>
              <div className="col-lg-1 register__Back d-md-block d-none" />
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default withRouter(Register);
