import React from 'react';
import { string } from 'prop-types';
import { Link } from 'react-router-dom';

const IconCard = ({ description, icon, title, link }) => (
  <div className="icon-card">
    <div className="header-icon">
      <i className={`fas fa-${ icon }`}></i>
    </div>
    <h3 className="header-title">{ title }</h3>
    <p className="card-description">
      { description }
    </p>
    <Link to={link}>Ver mas <i className="fas fa-chevron-right"></i></Link>
  </div>
);

IconCard.defaultProps = {
  description: '',
};

IconCard.propTypes = {
  description: string,
  icon: string.isRequired,
  title: string.isRequired,
  link: string.isRequired,
};

IconCard.displayName = 'IconCard';

export default IconCard;
