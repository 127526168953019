import React, { useState } from 'react';
import { object } from 'prop-types';
import { Header, IconCard, Carousel, ConfirmationModal } from "../../Components";
import { GLOBAL } from '../../Config/global';
import { HOME_DATA } from '../../Config/home-data';

const Channel = ({ history}) => {
  const { LOGOUT_MODAL } = GLOBAL;
  const { HOME_PAGE } = HOME_DATA;
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <section>
      {showLogoutModal && (
        <ConfirmationModal
          message={LOGOUT_MODAL.BODY}
          title={LOGOUT_MODAL.TITLE}
          status="warning"
          onDismiss={() => setShowLogoutModal(false)}
          onConfirm={() => history.push('/')}
        />
      )}
      <Header
        showBackButton={false}
        showNotificationPanel
        showProfileBadge
        showNavbar
        showHamburgerMenu
      />
      <section className="master-page">
        <div className="container-fluid">
          <div className="row px-0">
            <div className="col-12 px-0 img-container">
              <div className="d-md-block d-none">
                <Carousel
                  displayImages={HOME_PAGE.carousel.imagesDesktop}
                  titles={HOME_PAGE.carousel.titles}
                  subtitles={HOME_PAGE.carousel.subtitles}
                  links={HOME_PAGE.carousel.links}
                />
              </div>
              <div className="d-block d-md-none">
                <Carousel
                  displayImages={HOME_PAGE.carousel.imagesMobile}
                  titles={HOME_PAGE.carousel.titles}
                  subtitles={HOME_PAGE.carousel.subtitles}
                  links={HOME_PAGE.carousel.links}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row area-division">
            {
              HOME_PAGE.benefits.map(items => (
                <div className="col-md-4 text-center mt-5 mt-md-0" key={items.icon}>
                  <IconCard
                    { ...items }
                  />
                </div>
              ))
            }
          </div>
        </div>
      </section>
    {/* <Footer rol={ CHANNEL_OPTIONS.ROLE } /> */}
  </section>
  )
};

Channel.propTypes = {
  location: object.isRequired
};

export default Channel;
