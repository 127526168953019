import React, { Component } from 'react'
import { UploadFileRepository, Loading, TextArea, Button, Checkbox, Combobox, DatePicker, Input, Alert, Radio } from '..';
import { GLOBAL } from '../../Config/global';
import { APPCONFIG } from '../../app.config';
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
import { APIS } from '../../Config/apis';
import userAuth from '../../Config/userauth';
import moment from "moment";


export default class CommunicationModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.Id,
            loadingData: false,
            showForm: false,
            errorMessage: null,
            entity: null,
            textSendMailDate: ''
        }

        this.getCommunication = this.getCommunication.bind(this);
        this.clearCommunication = this.clearCommunication.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
        this.handleCommunicationBody = this.handleCommunicationBody.bind(this);
        this.handleCommunicationAttach = this.handleCommunicationAttach.bind(this);
        this.refreshFilesCommunication = this.refreshFilesCommunication.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        if (this.state.id == 0) {
            this.clearCommunication(); 
        }
        else {
           this.getCommunication(this.state.id); 
        }

    }

	getCommunication = (Id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null, entity: null });

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/communication/getcommunication?Id=${Id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {
                    const entity = response;
                    if (entity.EndDate == null) entity.EndDate = '';
                    if (entity.SendMailDate == null) entity.SendMailDate = '';
                    if (entity.ConfirmationText == null) entity.ConfirmationText = '';

                    let textSendMailDate = '';
                    if (entity.SendMailDate != '') {
                        const fecha = moment(entity.SendMailDate).format("DD/MM/YYYY");
                        textSendMailDate = `Fecha de último aviso: ${fecha}`;
                    }

                    this.setState({ loadingData: false, showForm: true, entity: entity, textSendMailDate: textSendMailDate });
                })
				.catch((response) => {
                    const message = 'Error procesando datos';
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: false, errorMessage: message });
			}

		});
	}

	refreshFilesCommunication = (Id) => {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		this.setState({loadingData: true, showForm: false, errorMessage: null});

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};			
		return fetch(`${PRODUCERURL}api/communication/getcommunication?Id=${Id}`, requestOptions)
		.then((response) => {

			if (response.ok) {
				QLIBS.authorizeAction(response)
				.then((response) => {

                    let entity = {...this.state.entity};
                    entity.UrlBody = response.UrlBody;
                    entity.UrlLink = response.UrlLink;

                    this.setState({ loadingData: false, showForm: true, entity: entity });
                })
				.catch((response) => {
                    const message = 'Error subiendo archivo';
                    this.setState({ loadingData: false, showForm: true, errorMessage: message });
                });
			}
			else {
				const message = `Error procesando solicitud: ${response.statusText}`;
				this.setState({ loadingData: false, showForm: true, errorMessage: message });
			}

		});
	}

    clearCommunication = () => {

        const entity = {
            Priority: 1,
            Description: '',
            Title: '',
            UrlBody: '',
            UrlLink: '',
            ConfirmationText: '',
            Enabled: false,
            SendMail: false,
            ConfirmationRequired: false,
            BodyEmbedded: false,
            StartDate: new Date(),
            EndDate: '',
            SendMailDate: '',
        };
        this.setState({ loadingData: false, showForm: true, entity: entity });
    }

    validar() {
        const entity = {...this.state.entity};
        if (!entity.Priority || !entity.Description || !entity.Title || (this.state.id != 0 && !entity.UrlBody) || !entity.StartDate) {
            this.setState({ errorMessage: 'Debe completar todos los campos obligatorios' });
            return false;
        }
        if (entity.ConfirmationRequired && !entity.ConfirmationText) {
            this.setState({ errorMessage: 'Debe completar todos los campos obligatorios' });
            return false;
        }

        return true;
    }


    handleSubmit = (isNew) => {

        if (this.validar()) {
            const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

            let data = {...this.state.entity};
            data.product = GLOBAL.APPNAME;
            //normalizo los campos sin datos
            if (data.EndDate == '') data.EndDate = null;
            if (data.SendMailDate == '') data.SendMailDate = null;
            if (data.ConfirmationText == '') data.ConfirmationText = null;

            this.setState({loadingData: true, showForm: false, errorMessage: null });

            const requestOptions = {
                method: 'POST',
                headers: DEFAULT_HEADER.get(),
                body: JSON.stringify(data)
            };			
            return fetch(`${PRODUCERURL}api/Communication/SaveCommunication`, requestOptions)
            .then((response) => {

                if (response.ok) {
                    QLIBS.authorizeAction(response)
                    .then((id) => {
                        if (isNew) {
                            this.setState({ id: id, loadingData: false, entity: null, errorMessage: null }, () => {
                                this.getCommunication(this.state.id);
                            });
                        }
                        else {
                            this.setState({ loadingData: false, showForm: false, entity: null, errorMessage: null });
                            this.props.dismiss(true);
                        }
                    })
                    .catch((response) => {
                        const message = 'Error procesando datos';
                        this.setState({ loadingData: false, showForm: false, errorMessage: message });
                    });
                }
                else {
                    const message = `Error procesando solicitud: ${response.statusText}`;
                    this.setState({ loadingData: false, showForm: false, errorMessage: message });
                }

            });
        }

    }


    handleInputChange = event => {
        const isCheckbox = event.target.type === "checkbox";

        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlDescription":
                entity.Description = inputValue;
                break;
            case "ctlTitle":
                entity.Title = inputValue;
                break;
            case "ctlUrlBody":
                entity.UrlBody = inputValue;
                break;
            case "ctlUrlLink":
                entity.UrlLink = inputValue;
                break;
            case "ctlConfirmationText":
                entity.ConfirmationText = inputValue;
                break;
            case "ctlEnabled":
                entity.Enabled = inputValue;
                break;
            case "ctlSendMail":
                entity.SendMail = inputValue;
                break;
            case "ctlConfirmationRequired":
                entity.ConfirmationRequired = inputValue;
                if (!entity.ConfirmationRequired) {
                    entity.ConfirmationText = '';
                }
                break;
            case "ctlBodyEmbedded":
                entity.BodyEmbedded = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };

    handleDatePickerChange = event => {
        let inputName = event.target.name ? event.target.name : event.target.id;
        let inputValue = event.target.value;

        if (inputValue != '') {
            inputValue = new Date(inputValue);
        }

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlStartDate":
                entity.StartDate = inputValue;
                break;
            case "ctlEndDate":
                entity.EndDate = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };

    handleComboChange = (selectedOption, event, index) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;

        let entity = {...this.state.entity};
        switch (inputName) {
            case "ctlPriority":
                entity.Priority = inputValue;
                break;
            default:
                break;
        }

        this.setState({ entity: entity });
    };

    handleCommunicationBody = () => {
        this.refreshFilesCommunication(this.state.id);
    }

    handleCommunicationAttach = () => {
        this.refreshFilesCommunication(this.state.id);
    }

    render() {
        const { COMMON_MODAL_FORM } = GLOBAL;

        return (
		    <>
                <Loading visible={this.state.loadingData}></Loading>

                <div id="form-communication" className="modal" tabIndex="-1" role="dialog"  aria-modal="true">
                    <div className="modal-dialog modal-lg m-b-modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title text-center">Definición de Comunicación</h4>
                            </div>
                            <div className="modal-body">

                                {this.state.errorMessage && (<Alert type="error" title="ERROR" message={this.state.errorMessage} noDetail={true} />)}
                                {this.state.showForm && (
                                 <section>
                                     <div className="row pb-4">
                                        <div className="col-12 pt-3 text-left">
                                            <Checkbox
                                                id="ctlEnabled"
                                                text="Habilitar la visualización de la comunicación"
                                                checked={this.state.entity.Enabled}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                                disabled={(this.state.id == 0)}
                                            />
                                        </div>
                                        <div className="col-12">
                                            <Input
                                                id="ctlDescription"
                                                type="text"
                                                text={"Descripción (*)"}
                                                defaultValue={this.state.entity.Description}
                                                classes="mb-0"
                                                maxLength="1000"
                                                onChange={this.handleInputChange}
                                                smallPrint="Es la descripción general de la comunicación"
                                            />
                                        </div>
                                     </div>
                                    <div className="row pb-4">

                                        <div className="col-12">
                                            <Input
                                                id="ctlTitle"
                                                type="text"
                                                text={"Título de la comunicación (*)"}
                                                defaultValue={this.state.entity.Title}
                                                classes="mb-0"
                                                maxLength="1000"
                                                onChange={this.handleInputChange}
                                            />
                                        </div>

                                        <div className="col-4 pt-3">
                                            <Combobox
                                                id="ctlPriority"
                                                label={"Prioridad (*)"}
                                                options={[
                                                    { value: 1, label: "Baja" },
                                                    { value: 2, label: "Regular" },
                                                    { value: 3, label: "Alta" }
                                                ]}
                                                onChange={(v, e) => { this.handleComboChange(v, e); }}
                                                defaultValue={this.state.entity.Priority}
                                            />
                                        </div>
                                        <div className="col-4 pt-3">
                                            <label className="pb-10px">{"Vigencia desde (*)"}</label>
                                            <DatePicker
                                                id="ctlStartDate"
                                                maxLength="10"
                                                noDefaultValue="true"
                                                onChange={this.handleDatePickerChange}
                                                defaultValue={this.state.entity.StartDate.toString()}
                                                disabled={(this.state.id != 0 && (new Date(this.state.entity.StartDate)).getTime() <= (new Date()).getTime())}
                                            />
                                        </div>
                                        <div className="col-4 pt-3">
                                            <label className="pb-10px">{"Vigencia hasta"}</label>
                                            <DatePicker
                                                id="ctlEndDate"
                                                maxLength="10"
                                                noDefaultValue="true"
                                                onChange={this.handleDatePickerChange}
                                                defaultValue={this.state.entity.EndDate.toString()}
                                            />
                                        </div>

                                        {this.state.id != 0 && (
                                        <>
                                        <div className="col-12 pt-3">
                                            <Input
                                                id="ctlUrlBody"
                                                type="text"
                                                text={"Link al contenido de la comunicación (*)"}
                                                defaultValue={this.state.entity.UrlBody}
                                                classes="mb-0"
                                                maxLength="1000"
                                                //onChange={this.handleInputChange}
                                                readOnly={true}
                                                smallPrint="Es un link al contenido a mostrar en la comunicación"
                                            />
                                            <UploadFileRepository
                                                entity="CommunicationBody"
                                                entityId={this.state.entity.Id.toString()}
                                                multiple={false}
                                                callbackDone={() => this.handleCommunicationBody()}
                                            />
                                        </div>
                                        <div className="col-12 pt-3 text-left">
                                            <Checkbox
                                                id="ctlBodyEmbedded"
                                                text="El contenido de la comunicación es un objeto embebido"
                                                checked={this.state.entity.BodyEmbedded}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                            />
                                        </div>
                                        <div className="col-12 pt-3">
                                            <Input
                                                id="ctlUrlLink"
                                                type="text"
                                                text={"Link al documento adjunto"}
                                                defaultValue={this.state.entity.UrlLink}
                                                classes="mb-0"
                                                maxLength="1000"
                                                //onChange={this.handleInputChange}
                                                readOnly={true}
                                                smallPrint="Es un link al documento adjunto de la comuniación"
                                            />
                                            <UploadFileRepository
                                                entity="CommunicationAttach"
                                                entityId={this.state.entity.Id.toString()}
                                                multiple={false}
                                                callbackDone={() => this.handleCommunicationAttach()}
                                            />
                                        </div>
                                        </>
                                        )}

                                        <div className="col-6 pt-3 text-left">
                                            <Checkbox
                                                id="ctlSendMail"
                                                text="Activar aviso por mail"
                                                checked={this.state.entity.SendMail}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                            />
                                        </div>
                                        <div className="col-6 pt-3 text-right">
                                            <label className="pb-10px">{this.state.textSendMailDate}</label>
                                        </div>
                                        <div className="col-12 pt-3 text-left">
                                            <Checkbox
                                                id="ctlConfirmationRequired"
                                                text="Solicitar confirmación de lectura"
                                                checked={this.state.entity.ConfirmationRequired}
                                                onChange={this.handleInputChange}
                                                smallPrint=""
                                            />
                                        </div>
                                        {this.state.entity.ConfirmationRequired && (
                                        <div className="col-12 pt-3">
                                            <Input
                                                id="ctlConfirmationText"
                                                type="text"
                                                text={"Leyenda de confirmación de lectura (*)"}
                                                defaultValue={this.state.entity.ConfirmationText}
                                                classes="mb-0"
                                                maxLength="1000"
                                                onChange={this.handleInputChange}
                                                smallPrint="Es el texto que aparecerá junto al check de confirmación de lectura"
                                            />
                                        </div>
                                        )}
                                    </div>
                                    <div className="pb-4 xMensajeAclarativo">
                                        <label className="xMensajeAclarativo">(*) obligatorio</label><br />
                                    </div>
                                 </section>
                                )}
                            
                            </div>
                            <div className="modal-footer">
                                <Button type="button" size="medium-lg" fill="outline" color="secondary" data-dismiss="modal" onClick={() => { this.props.dismiss(false) }}>{COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                                {this.state.showForm && this.state.id != 0 && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit(false) }}>{COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                                )}
                                {this.state.showForm && this.state.id == 0 && (
                                    <Button type="button" size="medium-lg" fill="outline" onClick={() => { this.handleSubmit(true) }}>{COMMON_MODAL_FORM.GO_BUTTON}</Button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
