import React from "react";
import { object } from "prop-types";
import {
  Alert,
  Breadcrumb,
  Button,
  Header,
  Table as TableComponent
} from "../../Components";
import { GLOBAL } from "../../Config/global";
import { MOCK_DATA } from "../../Config/mock-data";

const TableError = ({ ...props}) => {
  const { COMPONENTS_FORM, TABLE_VIEW } = GLOBAL;
  const { TABLES, TABLE_COLUMNS } = MOCK_DATA;

  return (
    <section>
      <Header showBackButton={false} showHamburgerMenu showNavbar />
      <section className="form">
        <div className="form-container container table-view">
          <div className="row">
            <div className="col-12">
              <Breadcrumb currentPage="/table" />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4 className="form-title mb-1">{TABLE_VIEW.TITLE}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <p className="form-subtitle">{TABLE_VIEW.SUBTITLE}</p>
            </div>
            <div className="col-md-2">
              <Button size="small">
                <i className="fas fa-file-download mx-2" />
                  {TABLE_VIEW.EXPORT_TEXT}
              </Button>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 mb-4">
            <Alert
              errorDesc={ COMPONENTS_FORM.ALERT_ERROR_DESC }
              errorInfo={ COMPONENTS_FORM.ALERT_ERROR_INFO }
              errorLine={ COMPONENTS_FORM.ALERT_ERROR_LINE }
              icon="times"
              message={ COMPONENTS_FORM.ALERT_ERROR_MSG }
              title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
              type="error"
            />
            </div>
            <div className="col-12">
              <TableComponent data={TABLES.makeData()} columns={TABLE_COLUMNS.data} {...props} />
            </div>
          </div>
        </div>
      </section>
      {/*<Footer rol={USER_OPTIONS.ROLE} />*/}
    </section>
  );
};

TableError.propTypes = {
  location: object.isRequired
};

export default TableError;
