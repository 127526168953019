import Channel1 from '../Resources/Images/Logos/channel-1.png';
import Channel2 from '../Resources/Images/Logos/channel-2.png';
import Channel3 from '../Resources/Images/Logos/channel-3.png';
import Channel4 from '../Resources/Images/Logos/channel-4.png';
import Channel5 from '../Resources/Images/Logos/channel-5.png';
import Channel6 from '../Resources/Images/Logos/channel-6.png';
import FriendsPhoto from '../Resources/Images/FormImages/friends-card.png';
import GrandsonPhoto from '../Resources/Images/FormImages/grandson-card.png';
import CouplePhoto from '../Resources/Images/FormImages/couple-card.png';
import CarouselDImageOne from '../Resources/Images/FormImages/family-1.png';
import CarouselDImageTwo from '../Resources/Images/FormImages/family-2.png';
import CarouselDImageThree from '../Resources/Images/FormImages/family-3.png';
import CarouselMImageOne from '../Resources/Images/FormImages/family-m-1.png';
import CarouselMImageTwo from '../Resources/Images/FormImages/family-m-2.png';
import CarouselMImageThree from '../Resources/Images/FormImages/family-m-3.png';

const MOCK_DATA = {
  CHANNEL_DATA: {
    LOGO_IMAGES: [Channel1, Channel2, Channel3, Channel4, Channel5, Channel6]
  },
  LOGIN_FORM: {
    USER: '',
    PASSWORD: '',
  },
  REGISTER_FORM: {
    NAMES: '',
    LAST_NAMES: '',
    EMAIL: '',
    PASSWORD: '',
    CONFIRM_PASSWORD: ''
  },
  SUCCESS_REGISTER_FORM: {
    USER: '',
    PASSWORD: ''
  },
  RECOVER_FORM: {
    DEFAULT_EMAIL: '',
    DEFAULT_CODE: '',
    DEFAULT_ID: '',
  },
  RECOVER_RESET: {
    PASSWORD: '',
    CONFIRM_PASSWORD: ''
  },
  COMPONENTS_FORM: {
    NAME: 'Federico',
    LAST_NAME: 'Colombo',
    USER: 'Fedecol80',
    PASS: 'password',
    CONFIRM_PASS: 'password',
    MAIL: 'fedecolo@gmail.com',
    PHONE: '+54 911 43225655',
    SELECT: [{
        value: "Opcion 1",
        disabled: true
      },
      {
        value: "Opcion 2"
      },
      {
        value: "Opcion 3"
      },
      {
        value: "Opcion 4"
      },
      {
        value: "Opcion 5"
      },
      {
        value: "Opcion 6"
      },
      {
        value: "Opcion 7"
      },
    ],
    MULTIPLE_SELECT: [{
        value: "Opcion 1",
        disabled: true
      },
      {
        value: "Opcion 2"
      },
      {
        value: "Opcion 3"
      },
      {
        value: "Opcion 4"
      },
      {
        value: "Opcion 5"
      },
      {
        value: "Opcion 6"
      },
      {
        value: "Opcion 7"
      },
      {
        value: "Opcion 8"
      },
      {
        value: "Opcion 9"
      },
      {
        value: "Opcion 10"
      },
      {
        value: "Opcion 11"
      },
      {
        value: "Opcion 12"
      },
      {
        value: "Opcion 13"
      },
    ],
  },
  TABLE_COLUMNS: {
    data: [
    {
      Header: "Acciones",
      accessor: "actions",
      width: 50,
      style: {
        display: "flex",
        alignItems: "center"
      },
      filterable: false,
      sortable: false,
      resizable: true
    },
    {
      Header: "Nombre Contratador",
      accessor: "contratador",
      width: 200,
      resizable: true
    },
    {
      Header: "Fecha de Vencimiento",
      accessor: "fecha_vencimiento",
      width: 250,
      resizable: true
    },
    {
      Header: "Cantidad días vencidos",
      accessor: "cantidad_dias_vencido",
      width: 250,
      resizable: true
    },
    {
      Header: "Saldo Pendiente",
      accessor: "saldo_pendiente",
      width: 250,
      resizable: true
    },
    {
      Header: "30 días",
      accessor: "30_dias",
      width: 250,
      resizable: true
    },
    {
      Header: "60 días",
      accessor: "60_dias",
      width: 250,
      resizable: true
    },
    {
      Header: "90 días",
      accessor: "90_dias",
      width: 250,
      resizable: true
    },
    {
      Header: "Más de 90 días",
      accessor: "mas_90_dias",
      width: 250,
      resizable: true
    },
    {
      Header: "A vencer",
      accessor: "a_vencer",
      width: 250,
      resizable: true
    },
    {
      Header: "Ramo",
      accessor: "ramo",
      width: 250,
      resizable: true
    },
    {
      Header: "Producto",
      accessor: "producto",
      width: 250,
      resizable: true
    },
    {
      Header: "Certificado",
      accessor: "certificado",
      width: 250,
      resizable: true
    },
    {
      Header: "Medio de Pago",
      accessor: "medio_pago",
      width: 250,
      resizable: true
    },
    {
      Header: "Documento",
      accessor: "documento",
      width: 250,
      resizable: true
    },
    {
      Header: "Cod.Productor",
      accessor: "codigo_productor",
      width: 250,
      resizable: true
    },
    {
      Header: "Nombre Productor",
      accessor: "nombre_productor",
      width: 250,
      resizable: true
    }
  ]
  },
  TABLES: {
    makeData: () => {
      return [
      {
        id: 1,
        ramo: 'RETIRO COLECTIVO',
        producto: 'AKZO NOBEL',
        poliza: '$ 504,630',
        certificado: '0',
        nombre: 'AKZO NOBEL',
        fecha: '02/06/2018',
        estado: 'ACTIVA',
        premio: '290',
        prima: 0,
        periodo_facturado: '2019/06',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '06/01/2017',
        fecha_vigencia_hasta: '', 
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '3050059454'
      },
      {
        id: 2,
        ramo: 'RETIRO COLECTIVO',
        producto: 'AKZO NOBEL ARGENTINA S.A.',
        poliza: '$ 503,440',
        certificado: '0',
        nombre: 'AKZO NOBEL ARGENTINA S.A.',
        fecha: '07/01/2012',
        estado: 'ACTIVA',
        premio: '1.983',
        prima: 0,
        periodo_facturado: '2019/06',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '05/01/2012',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30500529454'
      },
      {
        id: 3,
        ramo: 'RETIRO COLECTIVO',
        producto: 'AKZO NOBEL CHEMICALS ARGENTINA',
        poliza: '$ 504,700',
        certificado: '0',
        nombre: 'NOURYON CHEMICALS ARG. S.A.U',
        fecha: '07/20/2018',
        estado: 'ACTIVA',
        premio: '0',
        prima: 0,
        periodo_facturado: '2019/06',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '07/01/2018',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30715729756'
      },
      {
        id: 4,
        ramo: 'RETIRO COLECTIVO',
        producto: 'AKZO NOBEL CHEMICALS ARGENTINA',
        poliza: '$ 504,710',
        certificado: '0',
        nombre: 'NOURYON CHEMICALS ARG. S.A.U',
        fecha: '07/20/2018',
        estado: 'ACTIVA',
        premio: '1.427',
        prima: 0,
        periodo_facturado: '2019/08',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '07/01/2018',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30715729756'
      },
      {
        id: 5,
        ramo: 'RETIRO COLECTIVO',
        producto: 'ANGEL EL SANTO S.A.',
        poliza: '$ 504,620',
        certificado: '0',
        nombre: 'ANGEL EL SANTO  S.A.',
        fecha: '10/30/2017',
        estado: 'ACTIVA',
        premio: '465.000',
        prima: 0,
        periodo_facturado: '2017/11',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '10/01/2017',
        fecha_vigencia_hasta: '',
        productor: '243	Julia Maria Parotti',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30500235566'
      },
      {
        id: 6,
        ramo: 'RETIRO COLECTIVO',
        producto: 'ARECO SEMILLAS',
        poliza: '$ 504,260',
        certificado: '0',
        nombre: 'ARECO SEMILLAS S.A.',
        fecha: '01/18/2017',
        estado: 'ACTIVA',
        premio: '31.447',
        prima: 0,
        periodo_facturado: '2017/10',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '12/01/2016',
        fecha_vigencia_hasta: '',
        productor: '243	Julia Maria Parotti',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30710981724'
      },
      {
        id: 7,
        ramo: 'RETIRO COLECTIVO',
        producto: 'AVAL FERTIL SGR.',
        poliza: '$ 504,230',
        certificado: '0',
        nombre: 'AVAL FERTIL SGR.',
        fecha: '01/18/2017',
        estado: 'ACTIVA',
        premio: '9.615',
        prima: 0,
        periodo_facturado: '2017/02',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '12/01/2016',
        fecha_vigencia_hasta: '',
        productor: '243	Julia Maria Parotti',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30714824631'
      },
      {
        id: 8,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BANCO ITAU',
        poliza: '$ 503,020',
        certificado: '0',
        nombre: 'BANCO ITAU ARGENTINA S.A.',
        fecha: '11/25/2011',
        estado: 'ACTIVA',
        premio: '15.821',
        prima: 0,
        periodo_facturado: '2015/10',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '11/25/2011',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30580189411'
      },
      {
        id: 9,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BANCO EL AHORRO',
        poliza: '$ 504,600',
        certificado: '0',
        nombre: 'BANCO EL AHORRO',
        fecha: '10/30/2017',
        estado: 'ACTIVA',
        premio: '4.200.000',
        prima: 0,
        periodo_facturado: '2017/12',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '10/01/2017',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '33500005179'
      },
      {
        id: 10,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BCO. SUPERVILLE',
        poliza: '$ 504,080',
        certificado: '0',
        nombre: 'BANCO EL AHORRO',
        fecha: '09/16/2016',
        estado: 'ACTIVA',
        premio: '0',
        prima: 0,
        periodo_facturado: '2017/12',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '09/01/2016',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '33500005179'
      },
      {
        id: 11,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BANCO EL AHORRO',
        poliza: '$ 504,090',
        certificado: '0',
        nombre: 'BANCO EL AHORRO',
        fecha: '09/16/2016',
        estado: 'ACTIVA',
        premio: '2.013.372',
        prima: 0,
        periodo_facturado: '2018/02',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '09/01/2016',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '33500005179'
      },
      {
        id: 12,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BLACK & DECKER',
        poliza: '$ 503,880',
        certificado: '0',
        nombre: 'BLACK & DECKER ARGENTINA S.A.',
        fecha: '01/16/2015',
        estado: 'ACTIVA',
        premio: '12.668',
        prima: 0,
        periodo_facturado: '2019/07',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '01/01/2015',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '33658615969'
      },
      {
        id: 13,
        ramo: 'RETIRO COLECTIVO',
        producto: 'BUNGE ARGENTINA S.A.',
        poliza: '$ 503,790',
        certificado: '0',
        nombre: 'BUNGE ARGENTINA S.A.',
        fecha: '11/13/2013',
        estado: 'ACTIVA',
        premio: '1.196',
        prima: 0,
        periodo_facturado: '2019/08',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '11/01/2013',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30700869918'
      },
      {
        id: 14,
        ramo: 'RETIRO COLECTIVO',
        producto: 'CHEMINOVA AGRO DE ARGENTINA S.',
        poliza: '$ 504,580',
        certificado: '0',
        nombre: 'CHEMINOVA AGRO DE ARGENTINA S.A.',
        fecha: '10/09/2017',
        estado: 'ACTIVA',
        premio: '9.359',
        prima: 0,
        periodo_facturado: '2019/08',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '10/01/2017',
        fecha_vigencia_hasta: '',
        productor: '244	Ernestina Luppo',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30600659177'
      },
      {
        id: 15,
        ramo: 'RETIRO COLECTIVO',
        producto: 'CONSULTORES',
        poliza: '$ 504,410',
        certificado: '0',
        nombre: 'CONSULTORES ASSETS MANAGEMENT SA',
        fecha: '05/17/2017',
        estado: 'ACTIVA',
        premio: '0',
        prima: 0,
        periodo_facturado: '2019/08',
        medio_pago: 'EFECTIVO',
        fecha_vigencia_desde: '05/01/2017',
        fecha_vigencia_hasta: '',
        productor: '242	Mariano Castro',
        compania: 'Orígenes Seguros de Retiro',
        documento: '30708676906'
      }
    ]
   }
  },
  TABLES_2: {
    makeData: () => {
      return [
        {
          contratador: 'VEGA, HECTOR ARIEL',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 34.92',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 34.92',
          mas_90_dias: '$ 0',
          a_vencer: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: ': ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135579',
          poliza: '109000007',
          certificado: '107',
          medio_pago: 'Débito Bancario - PAC',
          documento: '32754143',
          codido_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'SILVA, ADA ORQUIDEA',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 34.79',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 34.79',
          mas_90_dias: '$ 0',
          a_vencer: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135578',
          poliza: '109000007',
          certificado: '108',
          medio_pago: 'Débito Bancario - PAC',
          documento: '39136292',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'CESPEDES OVIEDO, ALFREDO DANIEL',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 34.79',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 34.79',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135573',
          poliza: '109000007',
          certificado: '113',
          medio_pago: 'Débito Bancario - PAC',
          documento: '95009264',
          codido_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'JUSTINIANO , JOSE MARIA',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 34.79',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 34.79',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135567',
          poliza: '109000007',
          certificado: '119',
          medio_pago: 'Débito Bancario - PAC',
          documento: '35191577',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'AVALOS , CAROLINA GISELA',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 35.99',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 35.99',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135528',
          poliza: '109000007',
          certificado: '159',
          medio_pago: 'Débito Bancario - PAC',
          documento: '33544384',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'PAREDES , IRMA RAMONA',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 35.99',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 35.99',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '135523',
          poliza: '109000007',
          certificado: '164',
          medio_pago: 'Débito Bancario - PAC',
          documento: '24780256',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'GINES , MARIO',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 33.59',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 33.59',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '134264',
          poliza: '109000007',
          certificado: '1444',
          medio_pago: 'Débito Bancario - PAC',
          documento: '21101106',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'BRIZUELA , CARMEN SILVANA',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 34.79',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 34.79',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '134225',
          poliza: '109000007',
          certificado: '1483',
          medio_pago: 'Débito Bancario - PAC',
          documento: '29087238',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'ORUE , WILLIAM FABIAN',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 33.59',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 33.59',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '132885',
          poliza: '109000007',
          certificado: '2830',
          medio_pago: 'Débito Bancario - PAC',
          documento: '34047261',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'AIBA , CARLOS MILCIADES',
          fecha_vencimiento: '3/1/2019',
          cantidad_dias_vencido: '213',
          saldo_pendiente: '$ 33.72',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 33.72',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'ROBO CAJERO-ROBO/HURTO EP MA',
          referencia: '132882',
          poliza: '109000007',
          certificado: '2833',
          medio_pago: 'Débito Bancario - PAC',
          documento: '18149173',
          codigo_productor: '1230000087',
          nombre_productor: 'Julia Maria Perotti'
          },
          {
          contratador: 'CALZADA , ZULEMA INES',
          fecha_vencimiento: '12/18/2018',
          cantidad_dias_vencido: '286',
          saldo_pendiente: '$ -0.14',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ -0.14',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'Bolso Protegido',
          referencia: '71667',
          poliza: '109000001',
          certificado: '165',
          medio_pago: 'Débito Bancario - PAC',
          documento: '10269928',
          codigo_productor: '1030000099',
          nombre_productor: 'Raul Aberro'
          },
          {
          contratador: 'POLLEE , NANCY NATALIA',
          fecha_vencimiento: '12/18/2018',
          cantidad_dias_vencido: '286',
          saldo_pendiente: '$ -0.28',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ -0.28',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'Bolso Protegido',
          referencia: '71668',
          poliza: '109000001',
          certificado: '168',
          medio_pago: 'Tarjeta de Crédito',
          documento: '27264335',
          codigo_productor: '1030000099',
          nombre_productor: 'Raul Aberro'
          },
          {
          contratador: 'BENITEZ , CINTIA PAOLA',
          fecha_vencimiento: '12/19/2018',
          cantidad_dias_vencido: '285',
          saldo_pendiente: '$ 77.22',
          '30_dias': '$ 0',
          '60_dias': '$ 0',
          '90_dias': '$ 77.22',
          mas_90_dias: '$ 0',
          ramo: 'Robo y riesgos similares',
          producto: 'Bolso Protegido',
          referencia: '77188',
          poliza: '109000009',
          certificado: '85',
          medio_pago: 'Pago en Compañia',
          documento: '26005858',
          codigo_productor: '1030000099',
          nombre_productor: 'Raul Aberro'
          }
    ]
    }
  },
  TABLES_3: {
    makeData: () => {
      return [
      {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000041",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220274",
        "documento": "16784767",
        "contratante": "IREN , HECTOR LUIS",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000044",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220299",
        "documento": "16458543",
        "contratante": "MORENO , SUSANA DEL VALLE",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000047",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220311",
        "documento": "17926355",
        "contratante": "CARTA , ANGEL FRANCISCO",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000075",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220488",
        "documento": "17239120",
        "contratante": "GALVAN , RAMON ALFREDO",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000007",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220027",
        "documento": "33047881",
        "contratante": "GOMEZ , ROSA ANDREA",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000008",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.34 ",
        "premio": " $ 43.89 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220040",
        "documento": "11909886",
        "contratante": "GARCIA , SOLEDAD DEL VALLE",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000009",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.34 ",
        "premio": " $ 43.89 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220053",
        "documento": "39140742",
        "contratante": "AGUIRRE , PABLO JOSE MIGUEL",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000018",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.34 ",
        "premio": " $ 43.89 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220105",
        "documento": "17614738",
        "contratante": "FIGUEROA , YOLANDA INES",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000020",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.34 ",
        "premio": " $ 43.89 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220121",
        "documento": "35814147",
        "contratante": "VELIZ , JOHANA DANIELA",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000033",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "ROBO CAJERO-ROBO/HURTO EP MA",
        "referencia": "220214",
        "documento": "20547870",
        "contratante": "NIEVA , FATIMA DE LOS ANGELES",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000052",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "Bolso Protegido",
        "referencia": "220347",
        "documento": "31052584",
        "contratante": "TORRES , ADRIAN MAXIMILIANO",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000053",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "Bolso Protegido",
        "referencia": "220351",
        "documento": "33372834",
        "contratante": "CORO RAMOS , CINTHIA CELINA",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    },
    {
        "ramo": "Robo y riesgos similares",
        "poliza": "117000055",
        "certificado": "0",
        "fecha_imputacion": "7/11/19",
        "monto_resultante": " $ 2.43 ",
        "porcentaje": "6.87%",
        "prima_base_cálculo": " $ 35.39 ",
        "premio": " $ 43.95 ",
        "producto": "Bolso Protegido",
        "referencia": "220363",
        "documento": "32828488",
        "contratante": "JUAREZ , FRANCO MARTIN",
        "codido_productor": "244",
        "productor": "Julia Maria Perotti",
        "fecha_inicio_vigencia": "6/14/19"
    }
    ]
  }
},
TABLES_4: {
  makeData: () => {
    return [
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000001",
                "Certificado": "21",
                "Mes rechazo": "2019/09",
                "Monto": " $ 35.99 ",
                "Descrip_Rech": "Baja de déb. aut. Solicitada",
                "Referencia": "172102",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "SUAREZ , MARIA ALEJANDRA",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4509********5930"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000002",
                "Certificado": "1063",
                "Mes rechazo": "2019/09",
                "Monto": " $ 35.99 ",
                "Descrip_Rech": "Baja de déb. aut. Solicitada",
                "Referencia": "171084",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "SUAREZ , MARIA ALEJANDRA",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4509********5930"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000004",
                "Certificado": "58",
                "Mes rechazo": "2019/09",
                "Monto": " $ 51.03 ",
                "Descrip_Rech": "Baja de déb. aut. Solicitada",
                "Referencia": "172149",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "SUAREZ , MARIA ALEJANDRA",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4509********5930"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000005",
                "Certificado": "39",
                "Mes rechazo": "2019/09",
                "Monto": " $ 165.71 ",
                "Descrip_Rech": "Tarjeta dada de baja",
                "Referencia": "166806",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "MARTINEZ , ANDREA INES",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4546********7370"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000006",
                "Certificado": "63",
                "Mes rechazo": "2019/09",
                "Monto": " $ 156.28 ",
                "Descrip_Rech": "Tarjeta dada de baja",
                "Referencia": "166795",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "MARTINEZ , ANDREA INES",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4546********7370"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000007",
                "Certificado": "74",
                "Mes rechazo": "2019/09",
                "Monto": " $ 121.19 ",
                "Descrip_Rech": "Tarjeta dada de baja",
                "Referencia": "166791",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "MARTINEZ , ANDREA INES",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4546********7370"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000009",
                "Certificado": "613",
                "Mes rechazo": "2019/09",
                "Monto": " $ 277.88 ",
                "Descrip_Rech": "Tarjeta dada de baja",
                "Referencia": "166668",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "MARTINEZ , ANDREA INES",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4546********7370"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000010",
                "Certificado": "628",
                "Mes rechazo": "2019/09",
                "Monto": " $ 196.49 ",
                "Descrip_Rech": "Tarjeta dada de baja",
                "Referencia": "166663",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "MARTINEZ , ANDREA INES",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4546********7370"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000011",
                "Certificado": "716",
                "Mes rechazo": "2019/09",
                "Monto": " $ 117.56 ",
                "Descrip_Rech": "Tarjeta no operativa",
                "Referencia": "166642",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "VISCIGLIA , ROLDOLFO NESTOR",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4548********9490"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000012",
                "Certificado": "861",
                "Mes rechazo": "2019/09",
                "Monto": " $ 163.82 ",
                "Descrip_Rech": "Cuenta sin disponible",
                "Referencia": "166609",
                "Producto": "ROBO CAJERO-ROBO/HURTO EP MA",
                "Contratador DNI": "30-69729083-0",
                "Contratador Nombre y Apellido": "VARGAS , MARCELA",
                "Forma de pago": "Tarjeta de Crédito",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Numero de Tarjeta": "4347********5520"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000012",
                "Certificado": "983",
                "Mes rechazo": "2019/09",
                "Monto": " $ 116.29 ",
                "Descrip_Rech": "Falta de fondos",
                "Referencia": "166569",
                "Producto": "Bolso Protegido",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "ROCHETTI , TERESA OLGA",
                "Forma de pago": "Débito Bancario - PAC",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Banco CBU": "0720268***************"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000012",
                "Certificado": "324",
                "Mes rechazo": "2019/09",
                "Monto": " $ 73.65 ",
                "Descrip_Rech": "Fondos insuficientes",
                "Referencia": "166736",
                "Producto": "Bolso Protegido",
                "Contratador DNI": "30-71407676-7",
                "Contratador Nombre y Apellido": "Sanabria , Carolina",
                "Forma de pago": "Débito Bancario - PAC",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Banco CBU": "0720402***************"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000012",
                "Certificado": "445",
                "Mes rechazo": "2019/09",
                "Monto": " $ 223.60 ",
                "Descrip_Rech": "Falta de fondos",
                "Referencia": "166705",
                "Producto": "Bolso Protegido",
                "Contratador DNI": "30-50005574-6",
                "Contratador Nombre y Apellido": "TORALES , FACUNDO JOSE",
                "Forma de pago": "Débito Bancario - PAC",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Banco CBU": "3150000***************"
            },
            {
                "Rama": "Robo y riesgos similares",
                "Póliza": "109000012",
                "Certificado": "526",
                "Mes rechazo": "2019/09",
                "Monto": " $ 279.50 ",
                "Descrip_Rech": "Falta de fondos",
                "Referencia": "166691",
                "Producto": "Bolso Protegido",
                "Contratador DNI": "30-50005574-6",
                "Contratador Nombre y Apellido": "TORALES , FACUNDO JOSE",
                "Forma de pago": "Débito Bancario - PAC",
                "Productor": "252",
                "Nombre y Ap.Prod": "Raúl Aberro",
                "Banco CBU": "3150000***************"
            }
        ]
  }
},
  MASTER_PAGE: {
    benefits: [{
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'dolly',
        title: 'Beneficio 1',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'fan',
        title: 'Beneficio 2',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'hammer',
        title: 'Beneficio 3',
        link: '/',
      },
    ],
    infoCards: [{
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
        imgSrc: FriendsPhoto,
        title: 'Card 1',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
        imgSrc: CouplePhoto,
        title: 'Card 2',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
        imgSrc: GrandsonPhoto,
        title: 'Card 3',
        link: '/',
      },
    ],
    carouselDesktop: [CarouselDImageOne, CarouselDImageTwo, CarouselDImageThree],
    carouselMobile: [CarouselMImageOne, CarouselMImageTwo, CarouselMImageThree],
  },
  NOTIFICATIONS_PANEL: {
    notifications: []
  },
 DRAG_FILES: {
      API:'/api',
      
  }
};

export {
  MOCK_DATA
};
