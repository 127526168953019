import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Header} from '../../Components';
import { GLOBAL } from '../../Config/global';

const Demo = () => {
  const { ROUTES, DEMO } = GLOBAL;

  return (
    <section>
      <Header showBackButton={false} />
      <section className="MainContainer">

        <section className="container mt-3">
        <div className="row">
          <div className="col-12">
            <br/>
            <hr/>
            <h3 className="text-center">{DEMO.MENU}</h3>
            <hr/>
          </div>
          <div className="col-md-12">
          <h4 className="text-center">{DEMO.SUBTITLE}</h4>
          </div>
        </div>
          {
            ROUTES.map(({name, link}) => (
              <div className="row d-flex justify-content-center text-center" key={link + name}>
                <div className="col-md-6 my-2">
                  <Link to={link}>
                    <Button>{ name }</Button>
                  </Link>
                </div>
              </div>
            ))
          }
        </section>
      </section>
    </section>
  );
};

export default Demo;
