import userAuth from './userauth';
import * as QLIBS from '../qLibsHelpers';
import { APPCONFIG } from '../app.config';

export const USER_TOKEN = {
    set: ({ token, refreshToken }) => {
        userAuth.getStorage().setItem('access_token', token);
        userAuth.getStorage().setItem('refresh_token', refreshToken);
    },
    remove: () => {
        userAuth.getStorage().removeItem('access_token');
        userAuth.getStorage().removeItem('refresh_token');
    },
    get: () => ({
        token: userAuth.getStorage().getItem('access_token'),
        refreshToken: userAuth.getStorage().getItem('refresh_token'),
    }),
    refresh_token: () => {
        var jwt = require("jsonwebtoken");
        const token = USER_TOKEN.get().token;
        const refresh_token = USER_TOKEN.get().refreshToken;
        var decode = jwt.decode(token);
        console.log(decode);

        var now = new Date();
        var utc_timestamp = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
            now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds(), now.getUTCMilliseconds());

        console.log('now: ' + new Date(utc_timestamp));
        console.log('token expire: ' + new Date(decode.exp * 1000));
        var expirate = decode.exp * 1000 > utc_timestamp;
        console.log('token expirate: ' + expirate);

        if (expirate) {
            return false;
        }

        QLIBS.fetchDataPOST(APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/login/refreshtoken', {
            "token": token,
            "refreshToken": refresh_token,
            "userIdSession": userAuth.userId()
        })
            .then(function (data) {
                if (data.success) {
                    USER_TOKEN.set({ token: data.token, refreshToken: data.refreshToken });
                } else {
                    var userType = userAuth.isUserSimulator() || userAuth.isAdmin();
                    userAuth.signout(() => { window.location.href = userType ? '/admin' : '/login'; });
                }
            });
    },
    get notEmpty() {
        return this.get().token !== null;
    },
};

export const DEFAULT_HEADER = {
    get: () => ({
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': `Bearer ${USER_TOKEN.get().token}`,
        'RefreshToken': USER_TOKEN.get().refreshToken,
        'Application' : 'Origenes'
    }),
};