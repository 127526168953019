import { string } from 'prop-types';
import React from 'react';

const SubTitle = ({ children }) => {
  return (
    <p className="subtitle">{ children }</p>
  );
}

SubTitle.propTypes = {
  children: string.isRequired
};

export default SubTitle;
