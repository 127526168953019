/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { Routing } from './Routes';
import { useClearBrowserCache } from 'react-clear-browser-cache';

export default function App({ store, persistor, basename }) {
  
  const contextValue = useClearBrowserCache();

  return (
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      {/* Override `basename` (e.g: `homepage` in `package.json`) */}
      <BrowserRouter basename={basename}>
        {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
        <LastLocationProvider>
          {/* Render routes with provided `Layout`. */}
          <Routing />
        </LastLocationProvider>
      </BrowserRouter>
    </Provider>
  );
}
