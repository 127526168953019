/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { withRouter } from "react-router-dom";
import { object } from "prop-types";

const BackButton = ({ history: { goBack } }) => {
  const handleClick = () => {
    return goBack();
  };

  return (
    <i
      onClick={handleClick}
      className="fas fa-chevron-circle-left back-button"
    />
  );
};

BackButton.propTypes = {
  history: object
};

BackButton.defaultProps = {
  history: null
};

export default withRouter(BackButton);
