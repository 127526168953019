/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { bool, func, object } from "prop-types";

const TabsHeader = ({ active, handleClick, option }) => {

  return (
      <div
        key={option.label}
        role="button"
        onClick={handleClick}
        className={`tabs-panel__item ${active ? "-active" : ""} ${option.disabled ? "-disabled" : ""}`}
      >
        {option.label}
      </div>
  );
}

TabsHeader.propTypes = {
  option: object.isRequired,
  handleClick: func.isRequired,
  active: bool.isRequired,
};

export default TabsHeader;
