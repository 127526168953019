import React from 'react';
import { formatNumberWithTwoDecimals } from './Shared/Utils';
import { DEFAULT_HEADER } from './Config/userToken';
import userAuth from './Config/userauth';
import moment from "moment";
import { APIS } from './Config/apis';

export function fillCombo(dropDownListId, url, self, emptyItem, useToken = false) {
    fetch(url, {
        method: 'GET',
        headers: useToken ? DEFAULT_HEADER.get() : { 'Content-Type': 'application/json' },
    })
        .then(function (response) { return response.json(); })
        .then(function (data) {
            if (emptyItem) {
                data.unshift(emptyItem);
            }
            console.log(data);
            self.setState({ [dropDownListId]: data }, () => {
                // console.log(self.state) 
                });
        });
}

export function getTableColumns(columns) {
    let columnsDefinition = Object.getOwnPropertyNames(columns);
    let searchTableColumns = [];
    columnsDefinition.forEach((item, index) => {
        let column = {
            index: index,
            name: item,
            title: columns[item].title != null ? columns[item].title : "",
            field: columns[item].field != null ? columns[item].field : "",
            format: columns[item].format != null ? columns[item].format : 0,
            summarize: columns[item].summarize != null ? columns[item].summarize : false,
            show: columns[item].show != null ? columns[item].show : true,
            Header: () => <span title={columns[item].title}>{columns[item].title}</span>,
            accessor: item,
            resizable: true,
            Footer: columnProps => {
                //console.log(columnProps)
                return (
                    <span><b>
                        {columnProps.data.length > 0 && columns[item].summarize ? formatTableColumn(sumPropertyValue(columnProps.data.map((v, i) => { return v._original }), item), columns[item].signo) : ""}
                    </b></span>
                )
            },
            sortMethod: (a, b) => {
               // //debugger
                if (moment(a, "DD/MM/YYYY", true).isValid() && moment(b, "DD/MM/YYYY", true).isValid()) {
                    return moment(a, "DD/MM/YYYY", true).isBefore(moment(b, "DD/MM/YYYY", true)) ? -1 : 1;
                }
                else if (a.toString().includes('$') && b.toString().includes('$')) {
                    var _a = parseFloat(a.replace('$ ', ''));
                    var _b = parseFloat(b.replace('$ ', ''));
                    if (_a < _b) { return -1; }
                    if (_a > _b) { return 1; }
                    return 0;
                }
                else if (/^-{0,1}\d+$/.test(a) && /^-{0,1}\d+$/.test(b)) {
                    var _d = parseInt(a);
                    var _e = parseInt(b);
                    if (_d < _e) { return -1; }
                    if (_d > _e) { return 1; }
                    return 0;
                }
                else {
                    if (a.toUpperCase() === b.toUpperCase()) return 0;
                    if (a.toUpperCase() > b.toUpperCase()) return 1;
                    if (a.toUpperCase() < b.toUpperCase()) return -1;
                }
            }
        };
        if (columns[item].width != null) column.width = columns[item].width;

        searchTableColumns.push(column);
    });
    return searchTableColumns;
}

export function sumPropertyValue(items, prop) {
    let total = items.reduce((a, b) => a + parseFloat(b[prop] !== null && b[prop] !== '' && b[prop] !== '-' ? b[prop].toString().replace('$ ', '') : 0), 0);
    return total;
}

export function formatTableColumn(value, signo = '') {
    let label = `${signo} ${formatNumberWithTwoDecimals(value)}`;
    return label.trim();
}

export function codeDescriptionString(code, desc) {
    return codeString(code) + descriptionString(desc);
}
export function codeString(code) {
    return (code == null ? '' : "(" + code + ") ");
}
export function descriptionString(desc) {
    return (desc == null || desc === '' ? '(SIN DATOS)' : desc);
}

export function fetchDataGET(url, useToken = false) {
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: 'GET',
            headers: useToken ? DEFAULT_HEADER.get() : { 'Content-Type': 'application/json' },
        }).then((response) => {
            let value = authorizeAction(response);
            if (value) {
                resolve(value);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function fetchDataPOST(url, bodyData, useToken = false, contentType = 'application/json') {
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: 'POST',
            headers: useToken ? DEFAULT_HEADER.get() : { 'Content-Type': contentType },
            body: JSON.stringify(bodyData)
        }).then((response) => {
            let value = authorizeAction(response);
            if (value) {
                resolve(value);
            }
        }).catch((error) => {
            reject(error);
        });
    });
}

export function authorizeAction(response) {
    if (response.status === 204) return null;
    if (response.status === 401) {
        var userType = userAuth.isUserSimulator() || userAuth.isAdmin();
        userAuth.signout(() => { window.location.href = userType ? '/admin' : '/login'; });
        return;
    }
    return response.json();
}

export function getFirstDay(date = null) {
    if (!date)
        date = new Date();

    return new Date(date.getFullYear(), date.getMonth(), 1)
}

export function getPeriodRange(dateInit, dateEnd, reverse = false) {
    let periodRange = [];

    let date1 = new Date(dateInit.getFullYear(), dateInit.getMonth(), 1, 0, 0, 0, 0);
    let date2 = new Date(dateEnd.getFullYear(), dateEnd.getMonth(), 1, 0, 0, 0, 0);
    
    while (date1 <= date2) {
        periodRange.push(getPeriod(date1));
        date1.setMonth(date1.getMonth() + 1);
    }

    return (reverse) ? periodRange.reverse() : periodRange;
}

export function getPeriod(date = null) {
    if (!date)
        date = new Date();

    var month = (date.getMonth() + 1);
    var year = (date.getFullYear() * 100);
    var period = year + month;

    return period;
}

export function getPeriodPart(period) {
    var month = (period % 100);
    var year = parseInt(period / 100);

    return [year, month];
}

export function getPeriodName(period) {
    const monthName = [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];

    var month = (period % 100) - 1;
    var year = parseInt(period / 100);
    var periodName = monthName[month] + ', ' + year.toString();

    return periodName;
}

export function getLastDay(date = null) {
    if (!date)
        date = new Date();

    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export async function getParameters(key) {
    return await fetchDataGET(APIS.URLS.PARAMETERS_GET + '?key=' + key, true)
        .then((response) => {
            return response;
        })
        .catch((error) => { console.log(error); })
}

export function saveParameters(key, value) {
    fetchDataPOST(APIS.URLS.PARAMETERS_SAVE, { 'Key': key, 'Value': value }, true)
        .then((response) => {
            return response;
        })
        .catch((error) => { console.log(error); })
}