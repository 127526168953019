import React, { useEffect, useRef, useState } from 'react';
import { array, object} from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import { Actions, CellInput } from '../GridComponents';
import { GLOBAL } from '../../Config/global';
import "react-table/react-table.css";
import { InnerTable, ConfirmationModal } from '..';

const SelectTable = selectTableHOC(ReactTable);

const renderSpan = (spanClass) => (
  <span className={spanClass} />
);

const TextProps = {
  previousText: renderSpan('before-btn'),
  nextText: renderSpan('after-btn'),
  ofText: GLOBAL.TABLE_VIEW.OF_TEXT,
  rowsText: GLOBAL.TABLE_VIEW.ROWS_TEXT,
  pageText: GLOBAL.TABLE_VIEW.PAGE_TEXT
};

const CarteraSearchResults = ({ columns, data, history }) => {
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const renderEditableCell = row => (
    editingRow && editingRow.index === row.index && row.column.editable ? (
      <CellInput
        onKeyDown={e => handleCellKeyStroke(e, row)}
        //onChange={(event) => console.log(event.target.value)}
        type="text"
        defaultValue={row.value}
      />
    ) : (
      row.value
    )
  );

  const tableElementRef = useRef(null);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleExportModal = () => {
    setShowExportModal(!showExportModal);
  };

  const onSuccessRedirect = () => {
    history.push('/table-success');
  };

  const toggleAllSelection = () => {
    if (selectAll) {
      const wrappedInstance = tableElementRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      setSelection(currentRecords.map(record => `select-${record._original.id}`))
    } else {
      setSelection([]);
    }
  };

  const toggleSingleSelection = key => {
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      setSelection([
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ]);
    } else {
      setSelection([
        ...selection,
        key
      ]);
    }
  };

  const isSelected = key => selection.includes(`select-${key}`);

  const isEditing = key => editingRow && editingRow.index === key;
  
  // eslint-disable-next-line no-nested-ternary
  const getSelectedRowProps = rowInfo => (rowInfo && isSelected(rowInfo.original.id)) ? "-selected" : (rowInfo && selection.length && !isSelected(rowInfo.original.id) ? '-disabled' : '');
  
  // eslint-disable-next-line no-nested-ternary
  const getEditingRowProps = rowInfo => rowInfo && isEditing(rowInfo.index) ? "-editing" : rowInfo && editingRow && !isEditing(rowInfo.index) ? '-disabled' : '';

  const getRowProps = (state, rowInfo) => ({
    className: `${getSelectedRowProps(rowInfo)} ${getEditingRowProps(rowInfo)}`
  });

  const getEditableColumnProps = (state, rowInfo) => ({
    className: '-editable'
  });

  const onToggleRowEdit = row => {
    setEditingRow(editingRow && editingRow.index === row.index ? null : row);
  };

  const handleCellKeyStroke = (event, row) => {
    return event.which === 13 && onToggleRowEdit(row);
  }

  useEffect(() => {
    toggleAllSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);

  return (
    <React.Fragment>
      {showDeleteModal && <ConfirmationModal
        message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
        status="warning"
        title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
        onDismiss={toggleDeleteModal} 
        onConfirm={onSuccessRedirect} 
      />}
      {showExportModal && <ConfirmationModal
        message={GLOBAL.TABLE_VIEW.EXPORT_MODAL_BODY}
        title={GLOBAL.TABLE_VIEW.EXPORT_MODAL_TITLE}
        status="primary"
        onDismiss={toggleExportModal}
        onConfirm={onSuccessRedirect}
      />}
      <SelectTable
        {...TextProps}
        ref={tableElementRef}
        keyField="id"
        data={data}
        defaultFilterMethod={(filter, row) => row._original[filter.id].toLowerCase().includes(filter.value.toLowerCase())}
        toggleSelection={toggleSingleSelection}
        toggleAll={() => setSelectAll(!selectAll)}
        selectAll={selectAll}
        resizable={false}
        isSelected={isSelected}
        columns={[
          {
            Header: "Ramo",
            accessor: "dni",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row)
          },
          {
            Header: "Producto",
            accessor: "name",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row)
          },
          {
            Header: "Apellido",
            accessor: "lastName",
            getProps: getEditableColumnProps,
            editable: true,
            Cell: row => renderEditableCell(row)
          },
          {
            Header: "Fecha",
            accessor: "age",
            editable: true,
            getProps: getEditableColumnProps,
            Cell: row => renderEditableCell(row)
          },
          {
            Header: "Certificado",
            width: 150,
            accessor: "cert",
            sortable: true,
            Cell: row => renderEditableCell(row)
          },
          {
            Header: "Acciones",
            accessor: "actions",
            width: 100,
            style: {
              display: 'flex',
              alignItems: 'center'
            },
            filterable: false,
            sortable: false,
            Cell: row => (
              <Actions
               icon="fas fa-file-download"
                onDownload={toggleExportModal}
              />
            )
          },
        ]}
        getTrProps={getRowProps}
        SubComponent={InnerTable}
        defaultPageSize={10}
        className="-striped -highlight"
      />
    </React.Fragment>
  );
}

CarteraSearchResults.propTypes = {
  columns: array.isRequired,
  data: array.isRequired,
  history: object
};

export default CarteraSearchResults;
