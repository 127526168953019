import { string } from 'prop-types';
import React from 'react';

const FormTitle = ({ customClass, text }) => (
  <h4 className={`${customClass.length ? customClass : 'form-title'} form-title-size `}>{ text }</h4>
);

FormTitle.defaultProps = {
  customClass: ''
}

FormTitle.propTypes = {
  text: string.isRequired,
  customClass: string
}

export default FormTitle;
