import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import registracionStep1 from '../../Resources/Images/Tutoriales/Registracion/registracion-1.jpg';
import registracionStep2 from '../../Resources/Images/Tutoriales/Registracion/registracion-2.jpg';
import registracionStep3 from '../../Resources/Images/Tutoriales/Registracion/registracion-3.jpg';
import registracionStep4 from '../../Resources/Images/Tutoriales/Registracion/registracion-4.jpg';
import registracionStep5 from '../../Resources/Images/Tutoriales/Registracion/registracion-5.jpg';
//import registracionStep6 from '../../Resources/Images/Tutoriales/Registracion/registracion-6.jpg';

const Registration = props => {
    return (
        <div>
            <ReactWizard
                                        steps={[
                                            {
                                                stepName: "PASO 1", component: <StepTutorial image={registracionStep1} text={[
                                                    <span>Ingresá a <a href='https://productores.origenes.com.ar/'>https://productores.origenes.com.ar/</a>
                                                     y presioná <b>'Registrate ahora'</b>, o accede directamente haciendo click en  
                                                     <a href='https://productores.origenes.com.ar/register'> https://productores.origenes.com.ar/register</a></span>]} />
                                            },
                                            {
                                                stepName: "PASO 2", component: <StepTutorial image={registracionStep2} text={[
                                                    "Completar los campos: nombre y apellidos o razón social, CUIT, E-mail (deberá ser un email válido ya que se validará su cuenta), teléfono, empresa de telefónica y contraseña con su confirmación, esta última deberá contener al menos una mayúscula, una minúscula y un número para brindarle mayor seguridad",
                                                    <span>Presionar el tilde <b>He leido los terminos y condiciones de uso</b> posterior a leerlas</span>,
                                                    <span>Presionar el botón <b>Registrarse</b></span>]} />
                                            },
                                            {
                                                stepName: "PASO 3", component: <StepTutorial image={registracionStep3} text={[
                                                    "En caso que los datos ingresados sean válidos, se les informará que deberán validar su correo electrónico para continuar con la registración"]} />
                                            },
                                            {
                                                stepName: "PASO 4", component: <StepTutorial image={registracionStep4} text={[
                                                    "Revisar su casilla de correo pasados unos minutos",
                                                    "Abrir el e-mail de confirmación",
                                                    <span>Presionar el botón  <b>Confirmar correo</b> </span>,
                                                    "(En caso de no recibir el email, corroborar bandeja de correo no deseado)"]} />
                                            },
                                            {
                                                stepName: "PASO 5", component: <StepTutorial image={registracionStep5} text={[
                                                    <span>Se abrirá una nueva ventana en el navegador web abriendo  
                                                         <a href='https://productores.origenes.com.ar/'> https://productores.origenes.com.ar/</a>
                                                     con un mensaje informando que el registro finalizó correctamente</span>,
                                                      "Se enviará un nuevo correo electronico dando la bienvenida al sistema Portal Web",
                                                     "Felicitaciones! ya puede comenzar a utilizar el portal PAS de Orígenes"]} />
                                            }
                                        ]}
                                        navSteps
                                        title="Paso a paso del proceso de registración en el sistema."
                                      //  description="Paso a paso del proceso de registración en el sistema."
                                        headerTextCenter
                                        validate
                                        color="green"
                                        previousButtonText="Volver"
                                        nextButtonText="Continuar"
                                        finishButtonText="Finalizar"
                                        finishButtonClick={() => { props.onFinish(); }}
                                    />
        </div>
    )
}

Registration.propTypes = {

}

export default Registration
