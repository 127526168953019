import CarouselDImageOne from '../Resources/Images/FormImages/home-img.jpg';
import CarouselMImageOne from '../Resources/Images/FormImages/home-img-mob.jpg';

const HOME_DATA = {
  HOME_PAGE: {
    carousel: {
        imagesDesktop: [CarouselDImageOne],
        imagesMobile: [CarouselMImageOne],
        titles: ['Bienvenido al Portal de Productores'],
        subtitles: ['Donde podrás administrar tu gestión y cartera de clientes de la manera más fácil, rápida y segura'],
        links: ['']
    },
    benefits: [{
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'dolly',
        title: 'Beneficio 1',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'fan',
        title: 'Beneficio 2',
        link: '/',
      },
      {
        description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem.',
        icon: 'hammer',
        title: 'Beneficio 3',
        link: '/',
      },
    ]
  },
  NOTIFICATIONS_PANEL: {
    notifications: []
  }
};

export {
  HOME_DATA
};
