/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { array, string, bool, number, func, object} from 'prop-types';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import treeTableHOC from 'react-table/lib/hoc/treeTable';
import { advancedExpandTableHOC } from 'react-table/lib/hoc/advancedExpandTable';
import "react-table/react-table.css";
import { Actions, CellInput } from './components';
import { GLOBAL } from '../../Config/global';
import "react-table/react-table.css";
import { InnerTable, ConfirmationModal } from '..';
import {zhCN} from "@material-ui/core/locale";

const SelectTable = treeTableHOC(ReactTable);
const ExpandTable = advancedExpandTableHOC(ReactTable);

const renderSpan = (spanClass) => (
  <span className={spanClass} />
);

const TextProps = {
  previousText: renderSpan('before-btn'),
  nextText: renderSpan('after-btn'),
  ofText: GLOBAL.TABLE_VIEW.OF_TEXT,
  rowsText: GLOBAL.TABLE_VIEW.ROWS_TEXT,
  pageText: GLOBAL.TABLE_VIEW.PAGE_TEXT
};

const Table = ({ 
  history,
  columns,
  className,
  data,
  defaultPageSize,
  filterable,
  onFetchData,
  onSortedChange,
  loading,
  manual,
  pages,
  expandable,
  subComponent }) => {
  const [selection, setSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [editingRow, setEditingRow] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);



  const tableElementRef = useRef(null);

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleExportModal = () => {
    setShowExportModal(!showExportModal);
  };

  const onSuccessRedirect = () => {
    history.push('/table-success');
  };

  const toggleAllSelection = () => {
    if (selectAll) {
      const wrappedInstance = tableElementRef.current.getWrappedInstance();
      const currentRecords = wrappedInstance.getResolvedState().sortedData;

      setSelection(currentRecords.map(record => `select-${record._original.id}`))
    } else {
      setSelection([]);
    }
  };

  const toggleSingleSelection = key => {
    const keyIndex = selection.indexOf(key);

    if (keyIndex >= 0) {
      setSelection([
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ]);
    } else {
      setSelection([
        ...selection,
        key
      ]);
    }
  };

  const isSelected = key => selection.includes(`select-${key}`);

  const isEditing = key => editingRow && editingRow.index === key;
  
  // eslint-disable-next-line no-nested-ternary
  const getSelectedRowProps = rowInfo => (rowInfo && isSelected(rowInfo.original.id)) ? "-selected" : (rowInfo && selection.length && !isSelected(rowInfo.original.id) ? '-disabled' : '');
  
  // eslint-disable-next-line no-nested-ternary
  const getEditingRowProps = rowInfo => rowInfo && isEditing(rowInfo.index) ? "-editing" : rowInfo && editingRow && !isEditing(rowInfo.index) ? '-disabled' : '';

  const getRowProps = (state, rowInfo) => ({
    className: `${getSelectedRowProps(rowInfo)} ${getEditingRowProps(rowInfo)}`
  });

  const getEditableColumnProps = (state, rowInfo) => ({
    className: '-editable'
  });

  const onToggleRowEdit = row => {
    setEditingRow(editingRow && editingRow.index === row.index ? null : row);
  };

  const handleCellKeyStroke = (event, row) => {
    return event.which === 13 && onToggleRowEdit(row);
  };

  useEffect(() => {
    toggleAllSelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectAll]);


  //Inicio Control Scroll Flotante

  let windowSize = useWindowSize();
  let refDivTable = React.createRef()
  let refDivScroll1 = React.createRef()
  let refDivScroll2 = React.createRef()

  
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (refDivTable.current) {
      setWidthDivScroll();
    }
  },[refDivTable]);

  useEffect(() => {
    setTopDivScroll();
  },[windowSize]);


  const scrollHorizontalTable = () => {
    setWidthDivScroll();
    refDivTable.current.scrollLeft = refDivScroll1.current.scrollLeft
  };

  const calculateWidthDivScroll = () => {
    try {
      return (refDivTable.current && refDivTable.current.scrollWidth) ? refDivTable.current.scrollWidth : window.innerWidth+10;
    }
    catch {
      console.log("Error calculando width del scroll de la tabla")
      return window.innerWidth+10;
    }
  }

  const calculateTopDivScroll = () => {
    try {
      const footer = document.querySelector("div.footer-container");
      if ((footer) && (footer.offsetTop + footer.offsetHeight <= window.innerHeight)){
        return window.innerHeight - footer.offsetHeight - 20;
      }
      else {
        return window.innerHeight - 20;
      }
    }
    catch {
      console.log("Error calculando top del scroll de la tabla")
      return 0;
    }
  }

  const setWidthDivScroll = () => {
    refDivScroll2.current.style.width = calculateWidthDivScroll().toString() + "px";
  }

  const setTopDivScroll = () => {
    refDivScroll1.current.style.top = calculateTopDivScroll().toString() + "px";
  }

  //Fin Control Scroll Flotante

  return (
    <React.Fragment>
      {showDeleteModal && <ConfirmationModal
        message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
        status="warning"
        title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
        onDismiss={toggleDeleteModal} 
        onConfirm={onSuccessRedirect} 
      />}
      {showExportModal && <ConfirmationModal
        message={GLOBAL.TABLE_VIEW.EXPORT_MODAL_BODY}
        title={GLOBAL.TABLE_VIEW.EXPORT_MODAL_TITLE}
        status="primary"
        onDismiss={toggleExportModal}
        onConfirm={onSuccessRedirect}
      />}

      <div ref={refDivScroll1} onScroll={scrollHorizontalTable}
        style={{ 
          overflow: "auto",
          position: "sticky",
          zIndex: "1",
          top: calculateTopDivScroll().toString() + "px"
        }}
      >
        <div ref={refDivScroll2} style={{
            width: calculateWidthDivScroll().toString() + "px",
            height: "1px"
          }}>
        </div>
      </div> 

      <div ref={refDivTable} style={{overflow: "hidden", marginBottom: "20px"}}>

      {!expandable && <SelectTable
        {...TextProps}
        ref={tableElementRef}
        data={data}
        // pageSize={(data.length > 10) ? 10 : data.length}
        manual={manual}
        loading={loading}
        pages={pages}
        filterable={filterable}
        defaultPageSize={defaultPageSize}
        minRows={1}
        defaultFilterMethod={(filter, row) =>
           row._original[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase()
           )}
        toggleSelection={toggleSingleSelection}
        toggleAll={() => setSelectAll(!selectAll)}
        selectAll={selectAll}
        resizable={false}
        isSelected={isSelected}
        onFetchData={onFetchData}
        onSortedChange={onSortedChange}
        columns={columns}
        sortable
        getTrProps={getRowProps}
        className={`-striped -highlight ${className}`}               
      />}

      {expandable && <ExpandTable
        {...TextProps}
        ref={tableElementRef}
        data={data}        
        manual={manual}
        loading={loading}
        pages={pages}
        filterable={filterable}
        defaultPageSize={defaultPageSize}
        minRows={1}
        defaultFilterMethod={(filter, row) =>
           row._original[filter.id].toString().toLowerCase().includes(filter.value.toString().toLowerCase()
           )}
        toggleSelection={toggleSingleSelection}
        toggleAll={() => setSelectAll(!selectAll)}
        selectAll={selectAll}
        resizable={false}
        isSelected={isSelected}
        onFetchData={onFetchData}
        onSortedChange={onSortedChange}
        columns={columns}
        sortable
        getTrProps={getRowProps}
        className={`-striped -highlight ${className}`}
        SubComponent={subComponent}        
      />}

      </div> 
      
    </React.Fragment>
  );
}

Table.propTypes = {
  className: string,
  columns: array.isRequired,
  filterable: bool,
  defaultPageSize: number,
  data: array.isRequired,
  keyField: string,
  resizable: bool,
  getTableRef: func,
  defaultSortingMethod: func,
  handleToggleAllSelection: func,
  handleToggleSingleSelection: func,
  handleSetSelectAll: func,
  getRowProps: func,
  selectInputComponent: func,
  selectAll: bool,
  isSelected: func,
  manual: bool,
  onFetchData: func,
  onSortedChange: func,
  loading: bool,
  sorted: array,
  pages: number,  
  expandable: bool,
  subComponent: func
};

Table.defaultProps = {
  className: "",
  keyField: "id",
  defaultPageSize: 10,
  filterable: true,
  resizable: true,
  selectAll: false,
  loading: false,
  manual: false,
  sorted: [],  
  isSelected: () => false,
  defaultSortingMethod: () => null,
  handleToggleAllSelection: () => null,
  handleToggleSingleSelection: () => null,
  handleSetSelectAll: () => null,
  getRowProps: () => ({
      className: ``
  }),
  selectInputComponent: () => null,
  onFetchData: () => null,
  onSortedChange: () => null  ,
  expandable: false,
  subComponent: null
};
export default Table;
