import React from "react";
import { oneOfType, string, number } from "prop-types";

const CellInput = ({ defaultValue, ...props }) => {
  return (
    <input className="cell-input" defaultValue={defaultValue} {...props} />
  );
};

CellInput.propTypes = {
	defaultValue: oneOfType([ string, number ]).isRequired
};

export default CellInput;
