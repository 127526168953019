import React from 'react'
import { array, string } from 'prop-types';

function StepTutorial({ image, text }) {
    return (
        <div>
            <div className="card mb-3" style={{ maxWidth: "auto" }}>
                <div className="row no-gutters">
                    <div className="col-md-8">
                        <img src={image} className="card-img" alt="..." />
                    </div>
                    <div className="col-md-4">
                        <div className="card-body">
                            <p className="card-text">
                                &nbsp;
                                <ol>
                                    {text.map((item, i) => (
                                        <div>
                                        <li key={i}>{item}</li>
                                        <hr></hr>
                                        </div>
                                    ))}
                                </ol>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

StepTutorial.propTypes = {
    image: string.isRequired,
    text: array.isRequired,
}

export default StepTutorial
