/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { array, number } from 'prop-types';
import { TabsHeader } from '..';

const TabsPanel = ({ children, options, onChange, tabActive }) => {
  const [active, setActive] = useState( (tabActive) ? tabActive : 0 );

  function onSelectTab(index) {
    setActive(index);
    onChange(index);
  }

  return (
    <div>
      <div className="tabs-panel">
        {options.map((option, index) => (
          <TabsHeader
            key={index}
            option={option}
            handleClick={() => onSelectTab(index)}
            active={tabActive === index}
          />
        ))}
      </div>
      {children.map((child, index) => (
        <div key={index} className={index === tabActive ? "row" : "row hidden"}>
          {child}
        </div>
      ))}
    </div>
  );
}

TabsPanel.propTypes = {
  options: array.isRequired,
  children: array.isRequired,
  tabActive: number
};

export default TabsPanel;
