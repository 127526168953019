import React, { useState } from 'react'
import { Accordion, Card, Button} from "react-bootstrap";
import { Loading, ConfirmationModal, SchedulerProcessModal, NotificationDefinitionDestinationModal, Table as TableComponent } from "../../../Components";
import { Button as ButtonCustom } from "../../../Components";
import Actions from "../../../Components/GridComponents/Actions";
import * as QLIBS from '../../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../../Config/userToken';
import { APPCONFIG } from '../../../app.config';
import { GLOBAL } from "../../../Config/global";
import userAuth from "../../../Config/userauth";
import moment from 'moment';


const { PRODUCERURL } = APPCONFIG.ENDPOINTS;


function dateFormat(val) {
    return val ? moment(val).format('DD/MM/YYYY') : null;
}

const requestOptions = {
    method: 'GET',
    headers: DEFAULT_HEADER.get()
};

const SectionEnum = {
    SchedulerProcess: 1,
    NotificationDefinitionDestination: 2
};

const initSections = [
    {
        id: SectionEnum.SchedulerProcess,
        title: 'Calendario de ejecución',
        loaded: false
    },
    {
        id: SectionEnum.NotificationDefinitionDestination,
        title: 'Destinatarios de ejecución',
        loaded: false
    }
];




class NotificationDetailGrid extends React.Component {

    constructor(props) {        
        super(props);

        var columns = QLIBS.getTableColumns(this.columnsDef[props.id]).map(c => {
            return { filterable: false, sortable: false, ...c };
        });
       
        this.state = {
            parentId: props.original.Id,
            parentUrlApi: props.original.UrlApi,
            editDestination: props.original.EditDestination,
            editSchedule: props.original.EditScheduler,
            rowId: null,
            showDeleteModal: false,
            showSchedulerProcessModal: false,
            showNotificationDefinitionDestinationModal: false,
            columns:  [this.columnActionDef[props.id], ...columns],
            data: [],
            loading: false,
            width: (props.width === undefined || props.width === null) ? '100%' : props.width.toString() + 'px'
        };

        this.exitDeleteModal = this.exitDeleteModal.bind(this);
        this.removeSchedulerProcess = this.removeSchedulerProcess.bind(this);
        this.showSchedulerProcess = this.showSchedulerProcess.bind(this);
        this.hideSchedulerProcess = this.hideSchedulerProcess.bind(this);
        this.removeNotificationDefinitionDestination = this.removeNotificationDefinitionDestination.bind(this);
        this.showNotificationDefinitionDestination = this.showNotificationDefinitionDestination.bind(this);
        this.hideNotificationDefinitionDestination = this.hideNotificationDefinitionDestination.bind(this);
    }

    editEnabled = {
        [SectionEnum.SchedulerProcess]: () => {
            return this.state.editSchedule;
        },
        [SectionEnum.NotificationDefinitionDestination]: () => {
            return this.state.editDestination;
        },
    };

    columnActionDef = {
        [SectionEnum.SchedulerProcess]: {
            Header: "",
            accessor: "actions",
            width: 100,
            style: {
                display: 'flex',
                alignItems: 'center'
            },
            filterable: false,
            sortable: false,
            resizable: true,
            Cell: row => (
                <>
                    {(this.editEnabled[this.props.id]()) && (
                        <Actions
                            icon="fa fa-calendar-check"
                            onDownload={ () => this.functionAction[SectionEnum.SchedulerProcess].Edit(row.original.Id) }
                        />
                    )}
                    {(this.editEnabled[this.props.id]()) && (
                        <Actions
                            icon="fa fa-remove"
                            onDownload={ () => this.functionAction[SectionEnum.SchedulerProcess].Remove(row.original.Id) }
                        />
                    )}
                </>
            )
        },
        [SectionEnum.NotificationDefinitionDestination]: {
            Header: "",
            accessor: "actions",
            width: 50,
            style: {
                display: 'flex',
                alignItems: 'center'
            },
            filterable: false,
            sortable: false,
            resizable: true,
            Cell: row => (
                <>
                    <Actions
                        icon="fa fa-remove"
                        onDownload={ () => this.functionAction[SectionEnum.NotificationDefinitionDestination].Remove(row.original.Id) }
                    />
                </>
            )
        }
    };
    
    functionAction = {
        [SectionEnum.SchedulerProcess]: {
            Edit: (Id) => this.showSchedulerProcess(Id),
            Remove: (Id) => this.removeSchedulerProcess(Id)
        },
        [SectionEnum.NotificationDefinitionDestination]: {
            Remove: (Id) => this.removeNotificationDefinitionDestination(Id)
        }
    }
    
    columnsDef = {
        [SectionEnum.SchedulerProcess]: {
            "DetailExecutions": { title: "Detalle de calendario de ejecuciones" }
        },
        [SectionEnum.NotificationDefinitionDestination]: {
            "DetailExecutions": { title: "Detalle de destinatarios de ejecuciones" }
        }
    };
    
    apiMethods = {
        [SectionEnum.SchedulerProcess]: {
            Search: (props) => `notification/SearchSchedulerProcess?id=${this.state.parentId}`,
            Delete: (props) => `scheduler/DeleteSchedulerProcess?id=${this.state.rowId}`
        },
        [SectionEnum.NotificationDefinitionDestination]: {
            Search: (props) => `notification/SearchNotificationDefinitionDestination?id=${this.state.parentId}`,
            Delete: (props) => `notification/DeleteNotificationDefinitionDestination?id=${this.state.rowId}`
        }
    };

    componentDidMount() {

        this.loadGrid();
   
    }

    loadGrid = () => {
        this.setState({ loading: true });         

        fetch(`${PRODUCERURL}api/${this.apiMethods[this.props.id].Search(this.props)}`, requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            const dataArray = data;
            const cols = this.columnsDef[this.props.id];

            if (Array.isArray(dataArray)) {
                for(let entity of dataArray) {
                    for(let colName in cols) {                        
                        const col = cols[colName];

                        if(col && col.format) {
                            entity[colName] = col.format(entity[colName]);
                        }
                    }
                }
                
                this.setState({ data: dataArray }); 
            }
            
            this.setState({ loading: false });          
        });
    }


    exitDeleteModal(confirm) {
		this.setState({ showDeleteModal: false });
		if (confirm) {

            this.setState({ loading: true });

            fetch(`${PRODUCERURL}api/${this.apiMethods[this.props.id].Delete(this.props)}`, requestOptions)
            .then((response) => {
                if (response.ok) {
                    this.loadGrid();
                }
                else {
                    this.setState({loading: false });
                }
            })

		}
	}

    removeSchedulerProcess(Id) {
        this.setState({ rowId: Id, showDeleteModal: true });
    }

    showSchedulerProcess(Id) {
        this.setState({ rowId: Id, showSchedulerProcessModal: true });
    }

    hideSchedulerProcess(reload) {
		this.setState({ showSchedulerProcessModal: false });
		if (reload) {
			this.loadGrid();
		}
	}

    removeNotificationDefinitionDestination(Id) {
        this.setState({ rowId: Id, showDeleteModal: true });
    }

    showNotificationDefinitionDestination(Id) {
        this.setState({ rowId: Id, showNotificationDefinitionDestinationModal: true });
    }

    hideNotificationDefinitionDestination(reload) {
		this.setState({ showNotificationDefinitionDestinationModal: false });
		if (reload) {
			this.loadGrid();
		}
	}


    render() {        
        
        return (
            <>

                {this.state.showDeleteModal && (
                    <ConfirmationModal
                        message={GLOBAL.TABLE_VIEW.DELETE_MODAL_BODY}
                        status="primary"
                        title={GLOBAL.TABLE_VIEW.DELETE_MODAL_TITLE}
                        onDismiss={() => { this.exitDeleteModal(false) }}
                        onConfirm={() => { this.exitDeleteModal(true) }}
                    />
                )}

                {this.state.showSchedulerProcessModal && (
                    <SchedulerProcessModal
                        Id={this.state.rowId}
                        UrlApi={this.state.parentUrlApi}
                        dismiss={(reload) => { this.hideSchedulerProcess(reload) }}
                    />
                )}

                {this.state.showNotificationDefinitionDestinationModal && (
                    <NotificationDefinitionDestinationModal
                        ParentId={this.state.parentId}
                        dismiss={(reload) => { this.hideNotificationDefinitionDestination(reload) }}
                    />
                )}

                <Loading visible={this.state.loading}></Loading>
                
                <div className="row my-3">
                    <div className="search-buttons">
                        {(this.props.id == SectionEnum.SchedulerProcess && this.editEnabled[this.props.id]()) && (
                            <ButtonCustom type="button" id="btnNewSchedulerProcess" 
                                onClick={() => { this.showSchedulerProcess(0) }} 
                                classes="to-bottom button-small search-button"><span className="fa fa-plus"> </span> Nuevo calendario
                            </ButtonCustom>
                        )}
                        {(this.props.id == SectionEnum.NotificationDefinitionDestination && this.editEnabled[this.props.id]()) && (
                            <ButtonCustom type="button" id="btnNewNotificationDefinitionDestination" 
                                onClick={() => { this.showNotificationDefinitionDestination(0) }} 
                                classes="to-bottom button-small search-button"><span className="fa fa-plus"> </span> Nuevo destinatario
                            </ButtonCustom>
                        )}
                    </div>
                </div>
                <div className="row my-3">
					<div style={{width: this.state.width}}>

                        <TableComponent 
                            columns={this.state.columns} 
                            data={this.state.data} 
                        />

                    </div>
                </div>
            </>
        );
    }
}


const NotificationRowDetail = (props) => {
    const [sections, setSections] = useState( JSON.parse(JSON.stringify(initSections)));     
    
    const loadSection = (id) => {
        const section = sections.find(e => e.id === id);
    
        if(section.loaded) return;   
        
        const expandable = section.expandable
        
        section.loaded = true;
        section.grid = <NotificationDetailGrid  {...props} id={id} expandable={expandable} />;
    
        setSections(sections.slice());
    };
   
    return (
        <>
            <div className="m-4">   
                <Accordion defaultActiveKey="0">
                    { sections.map(e => (
                        <Card key={e.id}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={e.id} onClick={() => loadSection(e.id)}>
                                    {e.title}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={e.id}>
                                <Card.Body>{e.grid}</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>   
            </div>
        </>    
    );
};


export default NotificationRowDetail;
