import React from 'react';
import { Alert, Button } from "..";
import { GLOBAL } from '../../Config/global';
import userAuth from "../../Config/userauth";

const SimulatorWarning = props => {
    return (
        <>
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false">
                <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={props.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">{GLOBAL.COMPONENTS_FORM.CONFIRM_TITLE}</h5>
                        </div>
                        <div className="modal-body p-0 mb-2">
                            <div className="row">
                                <div className="col-12">
                                    <Alert type="warning" title={GLOBAL.COMPONENTS_FORM.ALERT_WARNING_TITLE_SIMULATOR} message={GLOBAL.COMPONENTS_FORM.ALERT_WARNING_SIMULATOR.replace('XX-XXXXXXXX-X', userAuth.cuitProducer())} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={() => { props.onDismiss() }}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" onClick={() => { props.onConfirm() }}>{GLOBAL.COMPONENTS_FORM.TOAST_CONFIRM_MESSAGE}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SimulatorWarning;