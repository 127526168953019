import React from 'react'
import { array, bool, string } from 'prop-types';
import Select from 'react-select'

const Combobox = ({ id, label, borderColor, errorMsg, onChange, options, defaultValue, isDisabled, placeholder, isMulti, isSearchable }) => {
    const marginBottom = (errorMsg.length) ? 'input input-span' : 'input';
    
    return (
        <div className={marginBottom}>
            {label && (<label htmlFor={id}>{label}</label>)}
            <div className={`input-group-${borderColor} rounded`}>
                {!isMulti && (
                    <Select onChange={onChange} id={id} name={id} options={options} isSearchable={isSearchable} value={options.filter(item => defaultValue === item.value)} isDisabled={isDisabled} placeholder={placeholder} isMulti={isMulti} theme={theme => ({ ...theme, borderRadius: 4, colors: { ...theme.colors, } })} />
                )}
                {isMulti && (
                    <Select onChange={onChange} id={id} name={id} options={options} isSearchable={isSearchable} defaultValue={options.filter(item => defaultValue.includes(item.value))} isDisabled={isDisabled} placeholder={placeholder} isMulti={isMulti} />
                )}
            </div>
            {errorMsg.length ? <span className={`input-span-${borderColor}`}>{errorMsg}</span> : null}
        </div>
    )
}

Combobox.defaultProps = {
    defaultValue: '',
    errorMsg: '',
    borderColor: '',
    label: '',
    id: '',
    isMulti: false,
    isSearchable: false,
    placeholder: ''
};

Combobox.propTypes = {
    errorMsg: string,
    borderColor: string,
    options: array.isRequired,
    label: string.isRequired,
    id: string.isRequired,
    isMulti: bool,
    isSearchable: bool
};

export default Combobox;
