const FORM_DATA = {
    SELECT_OPTION: "Seleccioná...",
    DNI_TYPES: [{
        id: "1",
        value: "DNI"
    },
    {
        id: "2",
        value: "CI"
    },
    {
        id: "3",
        value: "Pasaporte"
    }],
    PHONE_COMPANIES: [{
        id: "1",
        value: "Claro"
    },
    {
        id: "2",
        value: "Movistar"
    },
    {
        id: "3",
        value: "Nextel"
    },
    {
        id: "4",
        value: "Personal"
    },
    {
        id: "5",
        value: "Tuenti"
    }],
    MONTHSINVOICE: getMonthsInvoice(),
    MONTHSSETTLEMENT: getMonthsSettlement(),
    COMPANYS: getCompanys(),
    COMPANYS_CODE: getCompanysCode(),
    DEPARTMENTS: getDepartments(),
    PAYROLL_TYPE: getPayrollType(),
    CONDTRIBUTARIA: [
    {
         id: "Resp. Inscripto",
         value: "Resp. Inscripto"
    },   
    {
        id: "Monotributo",
        value: "Monotributo"
    }, 
    {
        id: "Exento",
        value: "Exento"
    }, 
    ],
    TIPOFACTURA:[ 
    {
        id: "A",
        value: "A"
    },
    // {
    //     id: "ADELANTO",
    //     value: "ADELANTO"
    // },
    {
        id: "C",
        value: "C"
    },
    {
        id: "M",
        value: "M"
    },
    {
        id: "N/C",
        value: "N/C"
    }],
    STATUS: [{
        id: "-1",
        value: "Seleccioná...",
    },
    {
        id: "0",
        value: "VIGENTE"
    },
    {
        id: "1",
        value: "RENTISTA"
    },
    {
        id: "2",
        value: "TERMINADA"
    },
    {
        id: "3",
        value: "EN CAPTURA INCOMPLETA"
    },
    {
        id: "4",
        value: "ANULADA"
    }],
    CANCEL_TEXT: 'Cancelar',
    SEND_TEXT: 'Enviar',
    UPLOAD_NEW_INVOICE: 'Cargar nueva factura',
    GET_DEBT_FREE: "Generar libre deuda",
    GET_RUBRIC_DIGITAL: "Generar rúbrica digital",
    SEND_PAYROLL: "Enviar nómina",
    UPLOAD_TEXT: 'Cargar',
    CONTINUE_UPLOAD_TEXT: 'Seguir cargando',
    ADD_TEXT: 'Agregar'
};

function getMonthsInvoice() {
    let i;
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const today = new Date();
    let d;
    let month;
    const array = [];

    for (i = today.getMonth(); i >= 0; i--) {
        d = new Date(today.getFullYear(), i, 1);
        month = monthNames[i];
        let value = month + ", " + today.getFullYear();
        let mesId = (d.getMonth() + 1) + "-" + d.getFullYear();
        array.push({ id: mesId, value: value });
    }

    let m = 11;
    while (array.length < 12) {
        d = new Date(today.getFullYear() - 1, m, 1);
        month = monthNames[m];
        let value = month + ", " + (today.getFullYear() - 1);
        let mesId = (d.getMonth() + 1) + "-" + (today.getFullYear() - 1);
        array.push({ id: mesId, value: value });
        m = m - 1;
    }
    //console.log(array);
    return array;
}

function getMonthsSettlement() {
    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    const today = new Date();
    let d;
    let month;
    const array = [];
    for (let i = 11; i > -1; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        const value = month + ", " + d.getFullYear();
        const mesId = d.getMonth() + "-" + d.getFullYear();
        array.push({ id: mesId, value: value });
    }
    
    return array.reverse();
}

function getCompanys() {
    return [{
        id: "Orígenes Seguros S.A.",
        value: "Orígenes Seguros S.A."
    },
    {
        id: "Orígenes Seguros de Retiro S.A.",
        value: "Orígenes Seguros de Retiro S.A."
    }];
}

function getCompanysCode() {
    return [{
        id: "VT",
        value: "Orígenes Seguros S.A."
    },
    {
        id: "RVT",
        value: "Orígenes Seguros de Retiro S.A."
    }];
}

function getDepartments() {
    return [{
        id: "1",
        value: "Retiro"
    },
    {
        id: "2",
        value: "Vida y Sepelio"
    },
    {
        id: "3",
        value: "AP - Salud - Patrimoniales"
    }];
}

function getPayrollType() {
    return [{
        id: "AP",
        value: "Accidentes Personales"
    }];
}

export {
    FORM_DATA
};
