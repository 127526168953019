/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Header } from '../../Components';
import { withRouter } from "react-router-dom";
import Registration from '../Tutoriales/registration';
import Login from '../Tutoriales/login';
import GestionTramites from '../Tutoriales/gestion-tramites';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import userAuth from '../../Config/userauth';
import Cartera from './cartera';

window.$ = window.jQuery = require('jquery');

export class Tutoriales extends Component {
    constructor(props) {
        super(props)

        this.state = {
            seccion: ''
        }
        const search = props.location.search;
        const params = new URLSearchParams(search);
        this.state.seccion = params.get('seccion');
    }

    componentDidMount() {
        // Hide submenus
        window.$('#body-row .collapse').collapse('show');

        // Collapse/Expand icon
        window.$('#collapse-icon').addClass('fa-angle-double-left');

        // Collapse click
        window.$('[data-toggle=sidebar-colapse]').click(function () {
            collapseMenu();
        });

        function collapseMenu() {
            window.$('.menu-collapsed').toggleClass('d-none');
            window.$('.sidebar-submenu').toggleClass('d-none');
            window.$('.submenu-icon').toggleClass('d-none');
            window.$('#sidebar-container').toggleClass('sidebar-expanded sidebar-collapsed');

            // Treating d-flex/d-none on separators with title
            var SeparatorTitle = window.$('.sidebar-separator-title');
            if (SeparatorTitle.hasClass('d-flex')) {
                SeparatorTitle.removeClass('d-flex');
            } else {
                SeparatorTitle.addClass('d-flex');
            }

            // Collapse/Expand icon
            window.$('#collapse-icon').toggleClass('fa-angle-double-left fa-angle-double-right');
        }
        collapseMenu()
        window.$("div:has(> .footer-container)").hide();
    }

    render() {
        return (
            <section style={{ marginBottom: "0px !important" }}>
                {(userAuth.isAuthenticated() === false) && (
                    <Header />
                )}
                <section className='form-container' id="form-tutorial">
                    <div className='col-12'>
                        <div className="row" id="body-row">
                            <div id="sidebar-container" className="sidebar-expanded d-none d-md-block">
                                <ul className="list-group">
                                    <div id='submenu1' className="collapse sidebar-submenu">
                                        <a href="#submenu1" data-toggle="collapse" aria-expanded="true" className="bg-success list-group-item list-group-item-action flex-column align-items-start">
                                            <div className="d-flex w-100 justify-content-start align-items-center">
                                                <span className="fas fa-home fa-fw mr-3"></span>
                                                <span className="menu-collapsed">Ayuda</span>
                                            </div>
                                        </a>
                                        <a href="/tutoriales?seccion=como-registrarse" className="list-group-item list-group-item-action bg-success text-white" id="btnRegister" onClick={item => this.handleRouteChange(item)}>
                                            Registración
                                        </a>
                                        <a href="/tutoriales?seccion=como-ingresar" className="list-group-item list-group-item-action bg-success text-white" id="btnLogin" onClick={item => this.handleRouteChange(item)}>
                                            Ingresar
                                        </a>
                                        <a href="/tutoriales?seccion=cartera" className="list-group-item list-group-item-action bg-success text-white" id="btnCartera" onClick={item => this.handleRouteChange(item)}>
                                            Cartera
                                        </a>
                                        <a href="/tutoriales?seccion=gestion-tramites" className="list-group-item list-group-item-action bg-success text-white" id="btnGestionTramites" onClick={item => this.handleRouteChange(item)}>
                                            Gestión de trámites
                                        </a>
                                    </div>
                                    <a href="#" data-toggle="sidebar-colapse" className="bg-success list-group-item list-group-item-action d-flex align-items-center">
                                        <div className="d-flex w-100 justify-content-start align-items-center">
                                            <span id="collapse-icon" className="fas fa-angle-double-left mr-3"></span>
                                            <span id="collapse-text" className="menu-collapsed">Cerrar</span>
                                        </div>
                                    </a>
                                </ul>
                            </div>
                            <div className="col contentTutorial">
                                {
                                    (this.state.seccion === "como-registrarse") ? <Registration onFinish={() => { this.setState({ seccion: 'como-ingresar' }) }} />
                                        :
                                        (this.state.seccion === "como-ingresar") ? <Login onFinish={() => { this.setState({ seccion: 'cartera' }) }} />
                                            :
                                            (this.state.seccion === "cartera") ? <Cartera onFinish={() => { this.setState({ seccion: 'liquidacion' }) }} />
                                                :
                                                (this.state.seccion === "gestion-tramites") ? <GestionTramites onFinish={() => { this.setState({ seccion: 'como-registrarse' }) }} />
                                                  :
                                                   <Registration onFinish={() => { this.setState({ seccion: 'como-ingresar' }) }} />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        )
    }
}

export default withRouter(Tutoriales);
