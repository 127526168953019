import React from 'react';
import { any, bool } from 'prop-types';

const Navbar = ({ children, expanded }) => {
  return (
    <nav className={`Navbar d-md-flex ${expanded ? 'expanded' : ''}`}>
      { children }
    </nav>
  )
}

Navbar.defaultProps = {
  children: null,
  expanded: false
};

Navbar.propTypes = {
  children: any,
  expanded: bool
};

export default Navbar;
