/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Input } from '../../Components'
import { GLOBAL } from '../../Config/global'
import { FORM_ERRORS } from "../../Config/form_errors";
import * as QLIBS from '../../qLibsHelpers';
import { APIS } from '../../Config/apis';
import { Alert, Button } from '../../Components'
import Script from "react-load-script"

class UserModal extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            onDismiss: props.onDismiss,
            entityChild: {
                email: '',
                nombre: '',
                apellido: ''
            },
            error: false,
            errorMessage: '',
            errorMsg_email: '',
            errorMsg_nombre: '',
            errorMsg_apellido: '',
            errorBorder_email: '',
            errorBorder_nombre: '',
            errorBorder_apellido: '',
        }
        this.handleResponse = this.handleResponse.bind(this);
    }

    handleChange = event => {
        const isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;
        let entityCopy = Object.assign({}, this.state.entityChild);
        entityCopy[inputName] = inputValue;
        this.setState({ entityChild: entityCopy }, () => { this.validate(); });
    }

    handleResponse(response) {
        
        if (response && response.success) {
            this.state.onDismiss(true);
        }
        else {
            this.setState({
                loadingVisible: false,
                error: true,
                errorMessage: response.businessExceptions && response.businessExceptions.length ? response.businessExceptions.map(x => x.message).join(". ") : ''
            });
        }
    }

    validate() {
        const { COMMON_ERRORS } = FORM_ERRORS;
        let errorMsg_email = '', errorMsg_nombre = '', errorMsg_apellido = '';
        let errorBorder_email = '', errorBorder_nombre = '', errorBorder_apellido = '';
        let errors = false;
        let result = false;

        if (this.state.entityChild.email === '') {
            errorMsg_email = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }
        else if (validarEmail(this.state.entityChild.email) === false) {
            errorMsg_email = COMMON_ERRORS.EMAIL_INVALID;
            errors = true;
        }

        if (this.state.entityChild.nombre === '') {
            errorMsg_nombre = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }
        if (this.state.entityChild.apellido === '') {
            errorMsg_apellido = COMMON_ERRORS.INITIAL_MARKER + ' ' + COMMON_ERRORS.EMPTY_FIELD;
            errors = true;
        }

        errorBorder_email = (errorMsg_email) ? "error" : "success";
        errorBorder_nombre = (errorMsg_nombre) ? "error" : "success";
        errorBorder_apellido = (errorMsg_apellido) ? "error" : "success";

        this.setState({ errorMsg_email, errorBorder_email, errorMsg_nombre, errorBorder_nombre, errorMsg_apellido, errorBorder_apellido });
        if (!errors) {
            result = true;
        }
        return result;
    }

    save() {
        if (this.validate()) {
            QLIBS.fetchDataPOST(APIS.URLS.USERS_SIMULATORS_CREATE, this.getData(), true)
                .then(this.handleResponse)
                .catch(this.handleResponse);
        }
    }

    getData() {
        return {
            'Email': this.state.entityChild.email,
            'FirstName': this.state.entityChild.nombre,
            'LastName': this.state.entityChild.apellido,
            'Application': GLOBAL.APPNAME
        };
    }

    render() {
        return (
            <div className="modal" tabIndex="-1" role="dialog" aria-hidden="false">
                <Script url="/scripts/form_validation.js" />
                <div className="modal-dialog modal-md modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div>
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.state.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">ADMINISTRAR USUARIO</h5>
                        </div>
                        <div className="modal-body p-0 mb-1">
                            <br />

                            <div className="row">
                                {this.state.error && (
                                    <div className="col-12 mb-1">
                                        <Alert type="error" icon="exclamation" title={GLOBAL.COMPONENTS_FORM.ALERT_ERROR_TITLE} message={this.state.errorMessage} />
                                    </div>
                                )}
                                <div className="col-12">
                                    <Input
                                        id="email"
                                        type="text"
                                        text={"E-Mail"}
                                        defaultValue={this.state.entityChild.email}
                                        classes="mb-4"
                                        placeholder={"example@origenes.com.ar"}
                                        maxLength="50"
                                        onChange={(e) => this.handleChange(e)}
                                        errorMsg={this.state.errorMsg_email}
                                        borderColor={this.state.errorBorder_email}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <Input
                                        id="nombre"
                                        type="text"
                                        text={"Nombre"}
                                        defaultValue={this.state.entityChild.nombre}
                                        classes="mb-4"
                                        maxLength="50"
                                        onChange={(e) => this.handleChange(e)}
                                        errorMsg={this.state.errorMsg_nombre}
                                        borderColor={this.state.errorBorder_nombre}
                                    />
                                </div>
                                <div className="col-6">
                                    <Input
                                        id="apellido"
                                        type="text"
                                        text={"Apellido"}
                                        defaultValue={this.state.entityChild.apellido}
                                        classes="mb-4"
                                        maxLength="50"
                                        onChange={(e) => this.handleChange(e)}
                                        errorMsg={this.state.errorMsg_apellido}
                                        borderColor={this.state.errorBorder_apellido}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={this.state.onDismiss}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" onClick={() => { this.save() }}>{GLOBAL.COMMON_MODAL_FORM.SAVE_BUTTON}</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserModal;