import React from 'react'
import { Toast, Dropdown } from 'react-bootstrap';
import { element, func, string } from 'prop-types';

export default function Widgets(props) {
    const { title, children, onClick, filters, icon, titleHelp } = props;
    return (
        <Toast>
            <Toast.Header>
                <strong className="mr-auto">
                    {(icon ? (<i className={icon} title={titleHelp}>&nbsp;<p>{title}</p></i>) : (title))}
                </strong>
                <small>
                    <Dropdown alignRight={false} drop={'left'}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">Ver</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {filters.map((filter) => {
                                return (
                                    <Dropdown.Item eventKey={filter.id} data-periodo={filter.id} as="button" onClick={(e) => { onClick(e) }}>{filter.title}</Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </small>
            </Toast.Header>
            <Toast.Body>
                {children}
            </Toast.Body>
        </Toast>
    )
}

Widgets.propTypes = {
    title: string.isRequired,
    children: element.isRequired,
    onClick: func.isRequired,
    icon: string,
    titleHelp: string
}

Widgets.defaultProps = {
    titleHelp: '',
};