/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, Redirect, withRouter } from "react-router-dom";
import { Footer, Header, Breadcrumb, ConfirmationModal, DragAndDrop, Button, Alert, Loading, Input, Combobox, SimulatorWarning } from '../../Components';
import { GLOBAL } from '../../Config/global';
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import moment from "moment";
import { DEFAULT_HEADER } from '../../Config/userToken';
import Script from "react-load-script";

class EnvioFacturas extends React.Component {

	constructor(props) {
		super(props);
		this.sended = (new URLSearchParams(window.location.search).has("sended"));
		this.state = {
			error: '',
			success: false,
			redirect: null,
			loadingForm: false,
			fechaDesde: '',
			fechaHasta: '',
			resumen: [{ IdRama: '', Importe: 0, ImporteString: '' }],
			itemList: [],
			item: {
				empresa: null,
				tipofactura: null,
				condtributaria: null,
				// tipofacturaString: '',
				// condtributariaString: '',
				// nrofactura: '',
				empresaString: '',
				ramos: null,
				ramosString: '',
				importe: '',
				mes: null,
				desde: '',
				hasta: '',
				files: [],
			},
			showWarningSimulator: false,
			btnUploadNewInvoice: 'none',
			sectionForm: 'block'
		};

		/// ERRORS
		this.errorMes = "";
		this.errorEmpresa = "";
		this.errorRamos = "";
		this.errorImporte = "";

		/// SETTINGS DRAP AND DROP
		this.settings = {
			apiURl: APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/invoice/upload',
			allowMultiple: true
		};
		this.isLoading = true;

		/// FUNCIONES
		this.handleChange = this.handleChange.bind(this);
		this.handleLoadAmount = this.handleLoadAmount.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleAddItem = this.handleAddItem.bind(this);
		this.handleInit = this.handleInit.bind(this);
		this.onhandleFileSelect = this.onhandleFileSelect.bind(this);
		this.onprocessfile = this.onprocessfile.bind(this);
	}

	componentDidMount() {
		var today = new Date();
		var d = new Date(today.getFullYear(), today.getMonth(), 0);
		console.log('mes anterior: ' + d);
		let entityCopy = Object.assign({}, this.state.item);
		entityCopy['mes'] = (d.getMonth() + 1) + '-' + d.getFullYear();
		var dd = entityCopy.mes.split('-');
		entityCopy['desde'] = new Date(dd[1], dd[0] - 1, 1);
		entityCopy['hasta'] = new Date(dd[1], parseInt(dd[0]), 0);
		console.log(moment(entityCopy.desde).toJSON());
		console.log(moment(entityCopy.hasta).toJSON());
		this.setState({ item: entityCopy }, () => { console.log(this.state.item); this.handleLoadResumen(); });
	}

	handleLoadResumen() {
		if (!this.state.item.mes) {
			return false;
		}
		let self = this;
		self.setState({ loadingForm: true }, function () {
			let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/invoice/GetResumenInvoiceAmount';
			urlApi += '?idProductor=' + userAuth.userId();
			urlApi += '&fechaDesde=' + moment(self.state.item.desde).toJSON();
			urlApi += '&fechaHasta=' + moment(self.state.item.hasta).toJSON();
			QLIBS.fetchDataGET(urlApi, true)
				.then((response) => {
					//debugger
					self.setState({
						resumen: response.Resumen,
						fechaDesde: response.FechaDesde,
						fechaHasta: response.FechaHasta,
						loadingForm: false
					})
				});
		});
	}

	handleComboChange = (selectedOption, event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;
		let inputName = event.name;
		let inputValue = selectedOption.value;
		let inputString = selectedOption.label;
		let entityCopy = Object.assign({}, this.state.item);

		/// SETEO DE ERRORES
		switch (inputName) {
			case "mes":
				this.errorMes = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
				this.errorMesBorderColor = (this.errorMes) ? "error" : "success";
				break;

			case "empresa":
				this.errorEmpresa = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
				this.errorEmpresaBorderColor = (this.errorEmpresa) ? "error" : "success";
				break;

			case "ramos":
				this.errorRamos = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
				this.errorRamosBorderColor = (this.errorRamos) ? "error" : "success";
				break;

			// case "tipofactura":
			// 	this.errorTipoFactura = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
			// 	this.errorTipoFacturaBorderColor = (this.errorTipoFactura) ? "error" : "success";
			// 	break;

			// case "condtributaria":
			// 	this.errorCondTributaria = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
			// 	this.errorCondTributariaBorderColor = (this.errorCondTributaria) ? "error" : "success";
			// 	break;

			default:
				break;
		}
		this.setState({ error: '' });

		/// SETEO DE VALORES
		switch (inputName) {
			case 'mes':
				entityCopy[inputName] = inputValue;
				var d = inputValue.split("-");
				entityCopy['desde'] = new Date(d[1], d[0] - 1, 1);
				entityCopy['hasta'] = new Date(d[1], parseInt(d[0]), 0);
				console.log(moment(entityCopy.desde).toJSON());
				console.log(moment(entityCopy.hasta).toJSON());
				break;

			default:
				entityCopy[inputName] = inputValue;
				entityCopy[`${inputName}String`] = inputString;
				break;
		}
		this.setState({ item: entityCopy }, () => {
			console.log(this.state.item);
			if (inputName !== 'empresa' && inputName !== 'tipofactura' && inputName !== 'condtributaria') {
				this.handleLoadAmount();
				this.handleLoadResumen();
			}
		});
	}

	handleChange = event => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let isCheckbox = event.target.type === "checkbox";
		let inputName = event.target.name;
		let inputValue = isCheckbox ? event.target.checked : event.target.value;

		/// SETEO DE ERRORES
		switch (inputName) {
			case "importe":
				this.errorImporte = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
				this.errorImporteBorderColor = (this.errorImporte) ? "error" : "success";
				break;
			default:
				break;
		}

		/// SETEO DE VALORES
		let entityCopy = Object.assign({}, this.state.item);
		entityCopy[inputName] = inputValue;
		this.setState({ item: entityCopy });
	}

	handleLoadAmount = e => {
		let self = this;
		if (!self.state.item.ramos || !self.state.item.mes) {
			return false;
		}

		self.setState({ loadingForm: true }, function () {
			let urlApi = APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/invoice/GetInvoiceAmount';
			urlApi += '?idRama=' + self.state.item.ramos;
			urlApi += '&fechaDesde=' + moment(self.state.item.desde).toJSON();
			urlApi += '&fechaHasta=' + moment(self.state.item.hasta).toJSON();
			urlApi += '&idProductor=' + userAuth.userId();
			QLIBS.fetchDataGET(urlApi, true)
				.then((response) => {
					self.setState(prevState => {
						let loadingForm = prevState.loadingForm;
						loadingForm = false;
						let item = Object.assign({}, prevState.item);
						item.importe = response.toFixed(2);
						return { item, loadingForm };
					}, () => {
						console.log(self.state.item);
					});
				});
		});
	}

	handleSubmit = event => {

		const { CUSTOM_ERRORS } = FORM_ERRORS;

		event.preventDefault();

		if (!this.state.itemList || this.state.itemList.length === 0) {
			this.setState({
				error: CUSTOM_ERRORS.ENVIO_FACTURA_ERROR,
				redirect: null
			});
			return false;
		} else {

			this.setState({ loadingForm: true }, () => {
				let data = this.getDataForm();

				const requestOptions = {
					method: 'POST',
					//headers: { 'Content-Type': 'application/json' },
					headers: DEFAULT_HEADER.get(),
					body: JSON.stringify({ 'Invoices': data })
				};

				return fetch(APPCONFIG.ENDPOINTS.PRODUCERURL + 'api/invoice/send', requestOptions)
					.then((response) => {
						return response.json()
					})
					.then(this.handleResponse)
					.catch(this.handleResponse);
			});
		}
	}

	getDataForm() {
		let data = [];
		if (!this.state.itemList || this.state.itemList.length === 0) {
			return false;
		} else {
			this.state.itemList.forEach(function (f) {
				data.push({
					'UserId': userAuth.userId(),
					'Month': f.mes,
					'Company': f.empresa,
					'Department': f.ramosString,
					'Amount': f.importe,
					// 'InvoiceType': f.tipofacturaString,
					// 'TaxStatus': f.condtributariaString,
					// 'InvoiceNumber': f.nrofactura,
					'Files': f.files
				});
			});
		}
		return data;
	}

	handleResponse(response) {
		if (response && response.success && !response.businessExceptions.length) {
			document.location.href = "/enviofacturas?sended";
		} else {
			this.setState({
				error: response.businessExceptions.map(x => x.message.replace('<br/>', '\n')).join(""),
				redirect: null,
				loadingForm: false
			}, () => console.log(this.state));
		}
	}

	handleDeleteItem(e, i) {
		this.setState(prevState => {
			let itemList = [];
			itemList.push(...prevState.itemList);
			itemList.splice(i, 1);
			return { itemList };
		}, () => console.log(this.state));
	}

	handleAddItem = event => {
		const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
		event.preventDefault();

		this.errorMes = "";
		this.errorEmpresa = "";
		this.errorRamos = "";
		this.errorImporte = "";
		// this.errorTipoFactura = "";
		// this.errorCondTributaria = "";
		// this.errorNroFactura = "";

		this.errorMesBorderColor = "";
		this.errorEmpresaBorderColor = "";
		this.errorRamosBorderColor = "";
		this.errorImporteBorderColor = "";
		// this.errorTipoFacturaBorderColor = "";
		// this.errorCondTributariaBorderColor = "";
		// this.errorNroFacturaBorderColor = "";

		// Validate every combo
		this.errorInForm = false;
		if (!this.state.item.mes) {
			this.errorMes = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
			this.errorInForm = true;
		}
		if (!this.state.item.empresa) {
			this.errorEmpresa = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
			this.errorInForm = true;
		}
		if (!this.state.item.ramos) {
			this.errorRamos = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION;
			this.errorInForm = true;
		}
		if (!this.state.item.importe) {
			this.errorImporte = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
			this.errorInForm = true;
		}
		// if (!this.state.item.tipofactura) {
		// 	this.errorTipoFactura = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
		// 	this.errorInForm = true;
		// }
		// if (!this.state.item.condtributaria) {
		// 	this.errorCondTributaria = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
		// 	this.errorInForm = true;
		// }
		// if (!this.state.item.nrofactura) {
		// 	this.errorNroFactura = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
		// 	this.errorInForm = true;
		// }
		// else if (this.state.item.nrofactura.length < 3){
		// 	this.errorNroFactura = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_3;
		// 	this.errorInForm = true;
		// }
		// else if (!validarNroFactura(this.state.item.nrofactura)) {
		// 	this.errorNroFactura = COMMON_ERRORS.INITIAL_MARKER + CUSTOM_ERRORS.NRO_FACTURA_ERROR;
		// 	this.errorInForm = true;
		// }
		if (this.errorInForm) {
			this.errorMesBorderColor = (this.errorMes) ? "error" : "success";
			this.errorEmpresaBorderColor = (this.errorEmpresa) ? "error" : "success";
			this.errorRamosBorderColor = (this.errorRamos) ? "error" : "success";
			this.errorImporteBorderColor = (this.errorImporte) ? "error" : "success";
			// this.errorTipoFacturaBorderColor = (this.errorTipoFactura) ? "error" : "success";
			// this.errorCondTributariaBorderColor = (this.errorCondTributaria) ? "error" : "success";
			// this.errorNroFacturaBorderColor = (this.errorNroFactura) ? "error" : "success";
			this.setState({ success: false, error: '' });
		} else {
			this.setState(prevState => {
				let itemList = [];
				itemList.push(...prevState.itemList);
				itemList.push(prevState.item);

				let item = Object.assign({}, prevState.item);
				item['empresa'] = null;
				item['empresaString'] = '';
				// item['tipofactura'] = null;
				// item['tipofacturaString'] = '';
				// item['condtributaria'] = null;
				// item['condtributariaString'] = '';
				item['ramos'] = null;
				item['ramosString'] = '';
				item['nrofactura'] = '';
				item['importe'] = '';
				//item['mes'] = null;
				//item['desde'] = '';
				//item['hasta'] = '';
				item['files'] = [];

				let btnUploadNewInvoice = 'block';
				let sectionForm = 'none';

				return { itemList, item, btnUploadNewInvoice, sectionForm };
			}, () => {
				var dd = document.querySelectorAll(".filepond--item .filepond--action-revert-item-processing");
				dd.forEach(function (f) { f.click(); });
			});
		}
	}

	handleInit = event => { };

	onprocessfile = function (error, file) {
		this.isLoading = true;
		this.setState({ error: '' });

		if (error) {
			this.setState({ error: error });
		}
		else if (file.status !== 9) {
			this.isLoading = false;
			this.setState({ error: '' });

			this.setState(prevState => {
				//debugger
				let item = Object.assign({}, prevState.item);
				if (item.files.indexOf(file.filename) === -1) {
					item.files.push(file.filename);
				}
				return { item };
			});
		}
	}

	onhandleFileSelect = function (files) {
		this.isLoading = files.filter(f => f.status === 9).length > 0;
		this.setState({ error: '' });

		if (files.length === 0) {
			this.setState(prevState => {
				//debugger
				let item = Object.assign({}, prevState.item);
				item.files = [];
				return { item };
			});
		}
		else if (files.length > 0 && this.isLoading === false) {
			this.setState(prevState => {
				//debugger
				let item = Object.assign({}, prevState.item);
				item.files = [];
				files.forEach(function (v, i) {
					if (item.files.indexOf(v.filename) === -1) {
						item.files.push(v.filename);
					}
				});
				return { item };
			});
		}
	}

	render(...props) {
		const { INVOICE_DELIVERY, PAGES } = GLOBAL;
		if (this.state.redirect) {
			return <Redirect to={{
				pathname: this.state.redirect,
				search: ''
			}} />
		}
		return (
			<>
				<Loading visible={this.state.loadingForm}></Loading>
				{this.state.showWarningSimulator && (<SimulatorWarning
					onDismiss={() => { this.setState({ showWarningSimulator: false }) }}
					onConfirm={() => { this.setState({ showWarningSimulator: false }, () => { document.getElementById('btnSubmit').click(); }) }}
				/>)}
				<section className='form-container master-page enviofacturas'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<Breadcrumb currentPage="/enviofacturas" />
								<div className="col-12">
									{
										(this.state.error) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													icon="times"
													message={this.state.error}
													title={INVOICE_DELIVERY.DOCUMENT_ERROR}
													type="errorMessage"
												/>
											</div>
										</div>
									}
									{
										(this.sended) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													icon="times"
													message={INVOICE_DELIVERY.DOCUMENT_SENT_MSG}
													title={INVOICE_DELIVERY.DOCUMENT_SENT_TITLE}
												/>
											</div>
										</div>
									}
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<h4 className="form-title">{PAGES.TITLE.INVOICE}</h4>
										</div>
									</div>
								</div>
								<section style={{ display: `${this.state.sectionForm}` }}>
									<div className="col-12">
										<div className="row">
											<div className="col-6">
												<Combobox
													id="mes"
													text="mes"
													label="Mes de Liquidación"
													placeholder={FORM_DATA.SELECT_OPTION}
													options={FORM_DATA.MONTHSINVOICE.map((item) => ({ value: item.id, label: item.value }))}
													errorMsg={this.errorMes}
													onChange={(v, e) => { this.handleComboChange(v, e); }}
													borderColor={this.errorMesBorderColor}
													defaultValue={this.state.item.mes}
												/>
												<br />
												{INVOICE_DELIVERY.FORM_SUBTITLE}
											</div>
											{(this.state.resumen && this.state.resumen.length > 0) && (
												<div className="col-6">
													<div className="row">
														<div className="table-responsive">
															<table className="table table-striped table-bordered resumen">
																<tbody>
																	<tr>
																		<td colSpan="2"><b>Periodo:&nbsp;{this.state.fechaDesde}&nbsp;-&nbsp;{this.state.fechaHasta}</b></td>
																	</tr>
																	{this.state.resumen.map(item => (
																		<tr>
																			<td>{FORM_DATA.DEPARTMENTS.filter(function (f) { return f.id === item.IdRama.toString() }).length ? FORM_DATA.DEPARTMENTS.filter(function (f) { return f.id === item.IdRama.toString() })[0].value : ''}</td>
																			<td style={{ textAlign: 'right' }}>$&nbsp;{item.ImporteString}</td>
																		</tr>
																	))}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</section>
								<Script url="/scripts/form_validation.js" />
								<form id="envio_facturas_form" onSubmit={this.handleSubmit} className="envio_facturas__Form" noValidate>
									<section style={{ display: `${this.state.sectionForm}` }}>
										<div className="mt-4">
											<div className="row">
												<div className="col-4">
													<Combobox
														id="empresa"
														text="empresa"
														label="Empresa"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={FORM_DATA.COMPANYS.map((item) => ({ value: item.id, label: item.value }))}
														errorMsg={this.errorEmpresa}
														onChange={(v, e) => { this.handleComboChange(v, e); }}
														borderColor={this.errorEmpresaBorderColor}
														defaultValue={this.state.item.empresa}
													/>
												</div>
												<div className="col-4">
													<Combobox
														id="ramos"
														text="ramos"
														label="Rama"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={FORM_DATA.DEPARTMENTS.map((item) => ({ value: item.id, label: item.value }))}
														errorMsg={this.errorRamos}
														onChange={(v, e) => { this.handleComboChange(v, e); this.handleLoadAmount(); }}
														borderColor={this.errorRamosBorderColor}
														defaultValue={this.state.item.ramos}
													/>
												</div>
												<div className="col-4">
													<Input
														type={'number'}
														id={'importe'}
														text={'Importe a facturar (agregar IVA en caso de corresponder)'}
														placeholder={'$'}
														readOnly={'true'}
														errorMsg={this.errorImporte}
														onChange={this.handleChange}
														borderColor={this.errorImporteBorderColor}
														value={this.state.item.importe}
													/>
												</div>
											</div>
										</div>
										{/* <div className="mt-1">
											<div class="row">
												<div className="col-4">
													<Combobox
														id="tipofactura"
														text="tipofactura"
														label="Tipo de factura"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={FORM_DATA.TIPOFACTURA.map((item) => ({ value: item.id, label: item.value }))}
														defaultValue={this.state.item.tipofactura}
														onChange={(v, e) => { this.handleComboChange(v, e); }}
														borderColor={this.errorTipoFacturaBorderColor}
														errorMsg={this.errorTipoFactura}
													/>
												</div>
												<div className="col-4">
													<Combobox
														id="condtributaria"
														text="condtributaria"
														label="Condición tributaria"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={FORM_DATA.CONDTRIBUTARIA.map((item) => ({ value: item.id, label: item.value }))}
														errorMsg={this.errorCondTributaria}
														defaultValue={this.state.item.condtributaria}
														borderColor={this.errorCondTributariaBorderColor}
														onChange={(v, e) => { this.handleComboChange(v, e); }}
													/>
												</div>
												<div className="col-4">
													<Input
														type={'text'}
														id={'nrofactura'}
														onChange={this.handleChange}
														text={'Número de factura'}
														placeholder={'Ingrese últimos números de la factura'}
														value={this.state.item.nrofactura}
														borderColor={this.errorNroFacturaBorderColor}
														errorMsg={this.errorNroFactura}
														maxLength={30}
													/>
												</div>
											</div>
										</div> */}
										<div className='col-md-12 text-center'>
											<DragAndDrop
												maxFiles={20}
												allowMultiple={this.settings.allowMultiple}
												api={this.settings.apiURl}
												onhandleInit={this.handleInit}
												onhandleFileSelect={this.onhandleFileSelect}
												onprocessfile={this.onprocessfile}
											/>
										</div>
										<div className="col-12 mt-3">
											<div className="row">
												<div className="col-8">
													<div className="row">
													</div>
												</div>
												{
													(this.state.item.files.length > 0 && this.isLoading === false) &&
													<div className="col-4 m-bottom">
														<Button type="submit" onClick={this.handleAddItem}>{FORM_DATA.ADD_TEXT}</Button>
													</div>
												}
											</div>
										</div>
									</section>
									<div className="mt-4">
										<div className="row">
											<div className="col-12">
												<table className="table table-bordered" style={{ "width": "100%" }}>
													<tbody>
														{this.state.itemList && this.state.itemList.map((v, i) => (
															<tr>
																<td style={{ width: "1%", background: "#00945E" }}>
																	<span><i className="fa fa-check-circle" style={{ color: "white", fontSize: "20px" }}></i></span>
																</td>
																<td>
																	{v.empresaString}
																</td>
																{/* <td>
																	N° {v.nrofactura}  - Tipo: "{v.tipofacturaString}"
																</td> */}
																<td>
																	{v.ramosString}
																</td>
																<td>
																	$&nbsp;{v.importe}
																</td>
																<td>
																	{v.mes}
																</td>
																<td style={{ width: "15%" }}>
																	<span>&nbsp;{v.files.join("\r\n")}</span>
																</td>
																<td style={{ width: "9%" }}>
																	<button type="button" className="button-white-full" onClick={(e) => { this.handleDeleteItem(e, i) }}><i className="fa fa-times-circle" style={{ color: "red" }}></i>&nbsp;Eliminar</button>
																</td>
															</tr>
														))}
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div className="mt-2">
										<div className="row">
											<div className="col-4">
											</div>
											<div className="col-4">
												<Button type="button" customStyles={{ display: `${this.state.btnUploadNewInvoice}` }} onClick={() => { this.setState({ btnUploadNewInvoice: 'none', sectionForm: 'block' }) }}>{FORM_DATA.UPLOAD_NEW_INVOICE}</Button>
											</div>
											{
												(this.state.itemList && this.state.itemList.length > 0) &&
												<div className="col-4 m-bottom">
													<>
														{userAuth.isUserSimulator() ? (
															<>
																<Button type="submit" id="btnSubmit" customStyles={{ display: 'none' }} />
																<Button type="button" onClick={() => { this.setState({ showWarningSimulator: true }) }}>{FORM_DATA.SEND_TEXT}</Button>
															</>
														) : (<Button type="submit" onClick={this.handleSubmit}>{FORM_DATA.SEND_TEXT}</Button>)}
													</>
												</div>
											}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(EnvioFacturas);
