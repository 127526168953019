import React from 'react';
import { Button, Checkbox } from '..';
import userAuth from "../../Config/userauth";
import { DEFAULT_HEADER } from '../../Config/userToken';
import * as QLIBS from '../../qLibsHelpers';
import { APPCONFIG } from '../../app.config';
import { USER_TOKEN } from '../../Config/userToken';

class HomeCommunication extends React.Component {
 
  constructor(props) {
    super(props);

    this.state = {
      listCommunication: [],
      error: null,
      showModal: false,
      indexItem: null
    }

    this.handleShowBody = this.handleShowBody.bind(this);
    this.handleHideBody = this.handleHideBody.bind(this);
    this.handleConfirmCommunication = this.handleConfirmCommunication.bind(this);
    this.searchCommunication = this.searchCommunication.bind(this);
  }

  componentDidMount() {

    this.searchCommunication();

  }

  searchCommunication() {
    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};	

    const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
    if (cuit !== null && cuit !== undefined) {

      fetch(PRODUCERURL + `api/Communication/SearchCommunication?CUIT=${cuit}&Vigentes=true&Ultimos=true`, requestOptions)
      .then((response) => {
        if (response.ok) {
          QLIBS.authorizeAction(response)
          .then(response => {
              if (Array.isArray(response)) {
                  this.setState({listCommunication: response, error: null });
              }
          });
        }
        else {
          const message = `Error procesando solicitud: ${response.statusText}`;
          this.setState({listCommunication: [], error: message });
        }
      });

    }
  }

  handleShowBody(index) {
    this.setState({showModal: true, indexItem: index }, () => {
      this.handleConfirmCommunication(this.state.listCommunication[this.state.indexItem].Id, false);
    });
  }

  handleHideBody() {
    this.setState({showModal: false, indexItem: null });
  }

  handleHistorial() {
    window.open('./historialcomunicaciones', '_self');
  }

  GetUrlBody(id) {
    const userToken = USER_TOKEN.get();
    const AccessToken = userToken.token;
    const RefreshToken = userToken.refreshToken;

    const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/CommunicationBody/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
    return download_url;
  }
  GetUrlAttach(id) {
    const userToken = USER_TOKEN.get();
    const AccessToken = userToken.token;
    const RefreshToken = userToken.refreshToken;

    const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/CommunicationAttach/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
    return download_url;
  }

  handleConfirmCommunication(Id, Confirmated) {

    const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};

    const cuit = userAuth.isProducer() ? userAuth.cuit() : userAuth.cuitProducer();
    if (cuit !== null && cuit !== undefined) {

      var confirmated = (Confirmated) ? 'true' : 'false';

      fetch(`${PRODUCERURL}api/Communication/ConfirmCommunication?CUIT=${cuit}&Id=${Id}&Confirmated=${confirmated}`, requestOptions)
      .then((response) => {
        if (response.ok) {
          //actualizo los datos
          this.searchCommunication();
        }
        else {
          const message = `Error procesando solicitud: ${response.statusText}`;
          this.setState({error: message });
        }
      });

    }
  };

  render() {

    return (
      <>

        {this.state.showModal && (
        <div className="modal communication-modal" tabIndex="-1" role="dialog"  aria-modal="true">
            <div className="modal-dialog communication-modal-dialog" role="document">
                <div className="modal-content communication-modal-content">
                    <div className="modal-header">
                      <button type="button" className="close" aria-hidden="true" onClick={() => this.handleHideBody()}>x</button>
                      <h4 className="modal-title text-center">{this.state.listCommunication[this.state.indexItem].Title}</h4>
                    </div>
                    <div className={(this.state.listCommunication[this.state.indexItem].BodyEmbedded) ? "modal-body" : "modal-body communication-modal-body"}>
                      {(this.state.listCommunication[this.state.indexItem].BodyEmbedded) ?
                        <iframe
                            src={this.GetUrlBody(this.state.listCommunication[this.state.indexItem].Id)}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        >
                        </iframe>
                        :
                        <img className="communication-body-img"
                            src={this.GetUrlBody(this.state.listCommunication[this.state.indexItem].Id)}
                        >
                        </img>
                      }
                    </div>
                    <div className="modal-footer communication-footer">
                      <div className='col-12 row'>
                        
                        <div className='col-6 communication-footer-left'>
                        {this.state.listCommunication[this.state.indexItem].ConfirmationRequired && (
                          <Checkbox
                              id="ctlConfirmed"
                              text={this.state.listCommunication[this.state.indexItem].ConfirmationText}
                              disabled={this.state.listCommunication[this.state.indexItem].Confirmed}
                              checked={this.state.listCommunication[this.state.indexItem].Confirmed}
                              onChange={() => this.handleConfirmCommunication(this.state.listCommunication[this.state.indexItem].Id, true)}
                              smallPrint=""
                          />
                        )}
                        </div>
                        <div className='col-6 communication-footer-right'>
                        {this.state.listCommunication[this.state.indexItem].UrlLink && (
                          <a target='_blank'
                            href={this.GetUrlAttach(this.state.listCommunication[this.state.indexItem].Id)}
                            style={{ color: "#504F4F" }}>Descargar documentación adjunta <span className="download" />
                          </a>
                        )}  
                        </div>
                        
                      </div>
                    </div>
                </div>
            </div>
        </div>
        )}
        
        <div className="communication-info-container">
          <div className="communication-info-form">
            <div className="communication-info-form-header">
              <div className="col-3"></div>
              <div className="col-6 pt-1">
                <span>Comunicaciones</span>
              </div>
              <div className="col-3 pt-1 pr-2">
                <a className="pull-right" href="#" onClick={this.handleHistorial}><i className="fa fa-plus" title='Ver historial de comunicaciones'></i></a>
              </div>
            </div>
            {this.state && !this.state.error && this.state.listCommunication.length == 0 && (
              <div className="communication-items-empty">En este momento usted no dispone de comunicaciones activas</div>
            )}
            {this.state && !this.state.error && this.state.listCommunication.length > 0 && (
              <div className='communication-items'>
                {this.state.listCommunication.map((item, index) =>
                    <div
                      key={index}
                      className="communication-item"
                      onClick={() => this.handleShowBody(index)}
                    >
                      <div className={`col-1 communication-item-icon communication-priority-${item.Priority}`}>
                        <i className="fa fa-envelope"></i>
                      </div>
                      <div className='col-11 communication-item-text'>
                        {item.Title}
                      </div>
                    </div>
                )}
              </div>
            )}
            {this.state && this.state.error &&
              <div>
                <div className="row pb-3 pt-4">
                  <div className="col-1"></div>
                  <div className="col-10 communication-campo-error">{this.state.error}</div>
                  <div className="col-1"></div>
                </div>
              </div>
            }
          </div>
        </div>

      </>
    );

  }

};

export default HomeCommunication;
