import React from 'react';
import { Link } from 'react-router-dom';
import { object } from 'prop-types';
import { Alert, Button, DatePicker, Header, Breadcrumb, FileUpload, Radio, Input, Select, TextArea, Checkbox, Toast } from "../../Components";
import { GLOBAL } from '../../Config/global';
import { MOCK_DATA } from '../../Config/mock-data';
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import InputWarn from '../../Resources/Images/Logos/input-warn.png';

const FormValidation = ({ location: { pathname } }) => {
  const { COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
  const { COMPONENTS_FORM: FORM_DATA } = MOCK_DATA;

  return (
    <section>
      <Header showBackButton={false} showHamburgerMenu showNavbar />
        <section className="form">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Breadcrumb currentPage="/form" />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h4 className="form-title">{ COMPONENTS_FORM.TITLE }</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-md-5">
                <p className="form-subtitle">
                  { COMPONENTS_FORM.SUBTITLE }
                </p>
              </div>
            </div>
          <div className="row my-2">
            <div className="col-12">
              <Alert
                errorDesc={ COMPONENTS_FORM.ALERT_ERROR_DESC }
                errorInfo={ COMPONENTS_FORM.ALERT_ERROR_INFO }
                errorLine={ COMPONENTS_FORM.ALERT_ERROR_LINE }
                icon="times"
                message={ COMPONENTS_FORM.ALERT_ERROR_MSG }
                title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                type="error"
              />
            </div>
          </div>
          </div>
          <div className="form-validation">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <Input
                  borderColor="success"
                  defaultValue={FORM_DATA.NAME}
                  imgSrc={InputCheck}
                  text={ COMPONENTS_FORM.NAME_INPUT }
                />
              </div>
              <div className="col-md-6">
                <Input
                  borderColor="success"
                  defaultValue={FORM_DATA.LAST_NAME}
                  imgSrc={InputCheck}
                  text={ COMPONENTS_FORM.LASTNAME_INPUT }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  borderColor="success"
                  defaultValue={FORM_DATA.USER}
                  imgSrc={InputCheck}
                  text={ COMPONENTS_FORM.USERNAME_INPUT }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  borderColor="success"
                  defaultValue={FORM_DATA.PASS}
                  imgSrc={InputCheck}
                  errorMsg={ COMPONENTS_FORM.PASSWORD_INPUT_MSG }
                  text={ COMPONENTS_FORM.PASSWORD_INPUT }
                  type="password"
                />
              </div>
              <div className="col-md-6">
                <Input
                  borderColor="warn"
                  defaultValue={FORM_DATA.CONFIRM_PASS}
                  imgSrc={InputWarn}
                  errorMsg={ COMPONENTS_FORM.CONFIRM_PASSWORD_INPUT_MSG }
                  text={ COMPONENTS_FORM.CONFIRM_PASSWORD_INPUT }
                  type="password"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  borderColor="error"
                  defaultValue={FORM_DATA.MAIL}
                  errorMsg={ COMPONENTS_FORM.MAIL_INPUT_MSG }
                  imgSrc={InputError}
                  text={ COMPONENTS_FORM.MAIL_INPUT }
                />
              </div>
              <div className="col-md-6">
                <Input
                  borderColor="success"
                  defaultValue={FORM_DATA.PHONE}
                  imgSrc={InputCheck}
                  text={ COMPONENTS_FORM.TEL_INPUT }
                  errorMsg={ COMPONENTS_FORM.TEL_INPUT_MSG }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 my-2">
                <label>{ COMPONENTS_FORM.BIRTH_DATE }</label>
                <DatePicker
                  id="birthday-date"
                  birthday
                />
              </div>
              <div className="col-md-3 my-2">
                <label>{ COMPONENTS_FORM.DATE_BEGIN }</label>
                <DatePicker
                  id="start-date"
                />
              </div>
              <div className="col-md-3 my-2">
                <label>{ COMPONENTS_FORM.DATE_END }</label>
                <DatePicker
                  id="end-date"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <Select
                  text={ COMPONENTS_FORM.SELECT_INPUT }
                  status="error"
                  defaultValue={ COMPONENTS_FORM.SELECT_INPUT_DEFAULT }
                  errorMsg={ COMPONENTS_FORM.SELECT_INPUT_MSG }
                  options={ FORM_DATA.SELECT }
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-6">
                <Select
                  multiple
                  text={ COMPONENTS_FORM.MULTIPLE_INPUT }
                  defaultValue={ COMPONENTS_FORM.SELECT_INPUT_DEFAULT }
                  options={ FORM_DATA.MULTIPLE_SELECT }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <label>{ COMPONENTS_FORM.TEXTAREA_LABEL }</label>
                <TextArea text={ COMPONENTS_FORM.TEXT_AREA } />
              </div>
            </div>
            <div className="row form-file-upload">
              <div className="col-12">
                <label>{ FILE_UPLOAD.LABEL }</label>
              </div>
              <div className="col-12 d-flex">
                <FileUpload />
              </div>
            </div>
            <div className="row form-radio">
              <div className="col-12">
                <Radio
                  name="radio-set-1"
                  options={ COMPONENTS_FORM.RADIO_OPTIONS }
                />
              </div>
            </div>
            <div className="row form-terms">
              <div className="col-12">
                <Checkbox
                  text={ COMPONENTS_FORM.TERMS_INPUT }
                />
              </div>
            </div>
            <div className="row form-terms">
              <div className="col-md-2">
                <Link to="form">
                  <Button color="secondary" fill="outline">{ COMPONENTS_FORM.CANCEL_BUTTON }</Button>
                </Link>
              </div>
              <div className="col-md-2 my-2 my-md-0">
                <Link to="form-success">
                  <Button>{ COMPONENTS_FORM.OK_BUTTON }</Button>
                </Link>
              </div>
            </div>
          </div>
          <Toast
            confirmMessage={ COMPONENTS_FORM.TOAST_CONFIRM_MESSAGE }
            message={ COMPONENTS_FORM.TOAST_MESSAGE }
            invalid
          />
          </div>
      </section>
    {/*<Footer rol={ USER_OPTIONS.ROLE } />*/}
  </section>
  )
}

FormValidation.propTypes = {
  location: object.isRequired
};

export default FormValidation;
