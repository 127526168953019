import React, { Component } from 'react'
import { Loading, Breadcrumb, Widgets, Table as TableComponent, Combobox } from '../../Components';
import Chart from "react-google-charts"; //https://react-google-charts.com/
import moment from "moment";
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
import * as Utils from '../../Shared/Utils';

const REPORTES = {
    ULTIMOS_ACCESOS: 'Registro de ingresos',
    MODULO_MAS_VISITADO: 'Módulo más visitado',
    USUARIOS_REGISTRADOS: 'Registración de usuarios por periodo (Altas nuevos usuarios)'
};
const TODAY = new Date().getFullYear();
const PERIODOS = [{
    id: 0,
    title: 'Hoy'
},
{
    id: 1,
    title: 'Últimos 7 días'
},
{
    id: 2,
    title: 'Último Mes'
},
{
    id: 3,
    title: 'Últimos 3 meses'
},
{
    id: 4,
    title: 'Últimos 6 meses'
},
{
    id: 6,
    title: 'Últimos 12 meses'
},
{
    id: 5,
    title: `Año ${TODAY}`
}];
const DATA = [{
    label: 'Por dia',
    value: 0
},
{
    label: 'Por mes',
    value: 1
},
{
    label: 'Por año',
    value: 2
},
{
    label: 'Por semana',
    value: 3
}];

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export class Estadisticas extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loadingData: false,
            title1: '',
            title2: '',
            title3: '',

            modulos: [['', ''], ['', 0]],
            ultimos_accesos: [],
            usuarios_registrados: [['', ''], ['0', 0]],
            productores_registrados: null,
            cantidad_productores_registrados: 0,

            tipo: DATA[1].value,
            periodo: PERIODOS[6].id,
            filtroAplicado: '',
        }

        this.columns = {
            "cuit": { title: "CUIT" },
            "nombre": { title: "Nombre" },
            "ultimoAcceso": { title: "Último Acceso" }
        };
    }

    componentDidMount() {
        this.onChangeFilter({ target: { dataset: { periodo: this.state.periodo } } }, REPORTES.MODULO_MAS_VISITADO);
        this.onChangeFilter({ target: { dataset: { periodo: this.state.periodo } } }, REPORTES.ULTIMOS_ACCESOS);
        this.reloadWidget(REPORTES.USUARIOS_REGISTRADOS, this.state.periodo, this.state.tipo);
        this.fillGrid();
    }

    fillGrid = () => {
        QLIBS.fetchDataGET(APIS.URLS.STATISTICS_PRODUCTORES_REGISTRADOS, true)
            .then((response) => {
                if (response && response.length > 0) {
                    response = response.map(function (item) {
                        let result = Object.assign({}, item);
                        result.ultimoAcceso = item.ultimoAcceso == null ? '-' : moment(item.ultimoAcceso).format("DD/MM/YYYY HH:mm:ss");
                        return result;
                    });
                }
                this.setState({ loadingForm: false, productores_registrados: response && response.length > 0 ? response : [], cantidad_productores_registrados: response ? response.length : 0 });
            });
    }

    handleComboChange = (selectedOption, event) => {
        let inputName = event.name;
        let inputValue = selectedOption.value;
        this.setState({ [inputName]: inputValue }, () => { this.reloadWidget(REPORTES.USUARIOS_REGISTRADOS, this.state.periodo, this.state.tipo); });
    };

    onChangeFilter = (e, reporte) => {
        // console.log(e.target.dataset.periodo);
        // console.log(reporte);
        let periodo = parseInt(e.target.dataset.periodo);
        this.setState({ periodo: periodo }, () => { this.reloadWidget(reporte, this.state.periodo, this.state.tipo); })
    }

    reloadWidget = (reporte, periodo, format = null) => {
        this.setState({ loadingData: true });
        switch (reporte) {

            case REPORTES.MODULO_MAS_VISITADO:
                QLIBS.fetchDataGET(APIS.URLS.STATISTICS_MODULO_MAS_VISITADO + periodo, true)
                    .then((response) => {

                        let data = [];

                        if (response.length) {
                            let headers = Array.from(new Set(response.map(f => { return Utils.capitalize(f.modulo.replace('Consulta de ', '').replace('Consultando ', '')); })));
                            //debugger
                            headers.unshift('periodo');
                            data.push(headers);


                            Array.from(new Set(response.map(f => { return f.periodo; }))).sort().forEach(periodo => {
                                let row = [periodo];
                                headers.forEach((modulo, index) => {
                                    if (index !== 0) {
                                        let item = response.filter((f) => { return Utils.capitalize(f.modulo.replace('Consulta de ', '').replace('Consultando ', '')) === Utils.capitalize(modulo) && f.periodo === periodo })[0];
                                        if (item) {
                                            row.push(item.cantidad);
                                        } else {
                                            row.push(0);
                                        }
                                    }
                                });
                                data.push(row);
                            });

                        } else {
                            data.push(['', '']);
                        }

                        //debugger
                        this.setState({ modulos: data, loadingData: false, title1: PERIODOS.filter((f) => { return f.id === periodo })[0].title });
                    });
                break;


            case REPORTES.ULTIMOS_ACCESOS:
                QLIBS.fetchDataGET(APIS.URLS.STATISTICS_ULTIMOS_ACCESOS + periodo, true)
                    .then((response) => {

                        let data = [];

                        data.push(['Periodo', 'Cant.']);
                        if (response.length > 0) {
                            response.forEach(element => {
                                data.push([element.label, element.amount]);
                            });
                        }
                        let filtroAplicado = PERIODOS.filter(function (f) { return f.id === periodo; })[0].title;
                        this.setState({ filtroAplicado: filtroAplicado, ultimos_accesos: data, loadingData: false, title2: PERIODOS.filter((f) => { return f.id === periodo })[0].title });
                    });
                break;


            case REPORTES.USUARIOS_REGISTRADOS:
                QLIBS.fetchDataGET(APIS.URLS.STATISTICS_USUARIOS_REGISTRADOS + '?period=' + periodo + '&format=' + format, true)
                    .then((response) => {

                        let data = [];

                        switch (format) {
                            case 0:
                                data.push(['Día', 'cantidad de registros']);
                                break;
                            case 1:
                                data.push(['Mes', 'cantidad de registros']);
                                break;
                            case 2:
                                data.push(['Año', 'cantidad de registros']);
                                break;
                            case 3:
                                data.push(['Semana', 'cantidad de registros']);
                                break;
                            default:
                                break;
                        }

                        //debugger
                        if (response.length > 0) {
                            response.forEach(element => { data.push([element.label, element.amount]); });
                        } else {
                            data.push([' ', 0]);
                        }

                        this.setState({ usuarios_registrados: data, loadingData: false, title3: PERIODOS.filter((f) => { return f.id === periodo })[0].title });
                    });
                break;

            default:
                break;
        }
    }

    render() {
        return (
            <section className="col-12">
                <Loading visible={this.state.loadingData}></Loading>
                <div className='row'>
                    <div className='col-12'>
                        <Breadcrumb currentPage="/estadisticas" /><br />
                    </div>
                </div>
                <div className="row">
                    <div className="form-container container table-view col-md-12 mt-2">
                        <form className="auditoria__Form">
                            <div className="row estadisticas">
                                <div className="col-md-6 col-xs-12 mb-2">
                                    <Widgets title={`${REPORTES.ULTIMOS_ACCESOS}  (${this.state.filtroAplicado})`}
                                        onClick={(e) => this.onChangeFilter(e, REPORTES.ULTIMOS_ACCESOS)}
                                        filters={PERIODOS.filter(function (f) { return f.id !== 0; })}>
                                        {this.state.ultimos_accesos.length > 0 && (
                                            <Chart
                                                width="500px"
                                                height="350px"
                                                chartType="Bar"
                                                loader={<div>Aguarde por favor...</div>}
                                                data={this.state.ultimos_accesos}
                                                options={{
                                                    title: this.state.title2,
                                                    legend: { position: 'none' },
                                                    colors: ['#006C45'],
                                                    chartArea: { width: 900 },
                                                    hAxis: {
                                                        minValue: 0,
                                                        title: 'Periodo',
                                                        ticks: [],
                                                    },
                                                    vAxis: {
                                                        title: 'Usuarios',
                                                    },
                                                }}
                                                rootProps={{ 'data-testid': '2' }}
                                            />
                                        )}
                                    </Widgets>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-2">
                                    {this.state.productores_registrados === null ? (<div className="sweet-loading">
                                        <div className="spinner">
                                            <SyncLoader
                                                css={override}
                                                size={50}
                                                color={"#00945E"}
                                                loading={true}
                                            />
                                        </div>
                                    </div>) : (
                                            <>
                                                <h6>Total de productores registrados: <b>{this.state.cantidad_productores_registrados}</b></h6><br />
                                                <TableComponent defaultPageSize={4} columns={QLIBS.getTableColumns(this.columns)} data={this.state.productores_registrados} {...this.props} />
                                            </>
                                        )}
                                </div>
                            </div>
                            <div className="row estadisticas">
                                <div className="col-md-6 col-xs-12 mb-2">
                                    <Widgets title={REPORTES.USUARIOS_REGISTRADOS} onClick={(e) => this.onChangeFilter(e, REPORTES.USUARIOS_REGISTRADOS)} filters={PERIODOS}>
                                        <>
                                            <Combobox id="tipo" options={DATA} defaultValue={this.state.tipo} onChange={this.handleComboChange} />
                                            <Chart
                                                width="500px"
                                                height="300px"
                                                chartType="Bar"
                                                loader={<div>Aguarde por favor...</div>}
                                                data={this.state.usuarios_registrados}
                                                options={{
                                                    // Material design options
                                                    chart: { title: this.state.title3 }
                                                }}
                                                // For tests
                                                rootProps={{ 'data-testid': '3' }}
                                            />
                                        </>
                                    </Widgets>
                                </div>
                                <div className="col-md-6 col-xs-12 mb-2">
                                    <Widgets title={REPORTES.MODULO_MAS_VISITADO}
                                        icon="fa fa-info-circle"
                                        titleHelp="Se gráfica cantidad de usuarios que accedieron a cada uno de los módulos en el periodo seleccionado"
                                        onClick={(e) => this.onChangeFilter(e, REPORTES.MODULO_MAS_VISITADO)} filters={PERIODOS}>
                                        <Chart
                                            width={'600px'}
                                            height={'350px'}
                                            chartType="BarChart"
                                            loader={<div>Aguarde por favor...</div>}
                                            data={this.state.modulos}
                                            options={{
                                                title: this.state.title1,
                                                chartArea: { width: '50%' },
                                                hAxis: {
                                                    title: '',
                                                    minValue: 0,
                                                },
                                                vAxis: {
                                                    title: '',
                                                },
                                            }}
                                            // For tests
                                            rootProps={{ 'data-testid': '1' }}
                                        />
                                    </Widgets>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        )
    }
}

export default Estadisticas;