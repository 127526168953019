/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from "react";
import { object } from "prop-types";
import {
  Breadcrumb,
  Button,
  Header,
  Table as TableComponent
} from "../../Components";
import { GLOBAL } from "../../Config/global";
import { MOCK_DATA } from "../../Config/mock-data";

const TableDefault = ({ location: { pathname }, ...props}) => {
  const { USER_OPTIONS, TABLE_VIEW } = GLOBAL;
  const { TABLES, TABLE_COLUMNS } = MOCK_DATA;

  return (
    <section>
      <Header showBackButton={false} showHamburgerMenu showNavbar />
      <section className="form">
        <div className="form-container container table-view">
          <div className="row">
            <div className="col-12">
              <Breadcrumb currentPage={pathname} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h4 className="form-title mb-1">{TABLE_VIEW.TITLE}</h4>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-md-10">
              <p className="form-subtitle">{TABLE_VIEW.SUBTITLE} </p>
            </div>
            <div className="col-auto px-0">
              <Button size="small">
                <i className="fas fa-file-download mx-2" />
                  {TABLE_VIEW.EXPORT_TEXT}
              </Button>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 px-0">
              <TableComponent data={TABLES.makeData()} columns={TABLE_COLUMNS.data} {...props} />
            </div>
          </div>
        </div>
      </section>
      {/*<Footer rol={USER_OPTIONS.ROLE} />*/}
    </section>
  );
};

TableDefault.propTypes = {
  location: object.isRequired
};

export default TableDefault;
