/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import React, { useState } from "react";
import { any, bool, string, number } from "prop-types";
import { CheckBox } from 'devextreme-react/check-box';

function MenuItemSelector({ id, item, labelComponent, active, onClick, onClickChk }) {

  return (
    <React.Fragment>
      
      <div
        onClick={() => onClick(id)}
        key={id}
        className={`menu-selector__item ${active ? 'menu-selector__active' : ''}`}
      >
      <CheckBox defaultValue={false} onValueChanged= {() => onClickChk(id)} />  
        
        {/* <input type="checkbox" onChange={() => onClickChk(id)}></input> */}
        <span className="d-md-inline">{labelComponent}</span>
      </div>
    </React.Fragment>
  );
}

MenuItemSelector.propTypes = {
  id: number,
  labelComponent: any,
  active: bool
};

MenuItemSelector.defaultProps = {
  id: number.isRequired,
  labelComponent: "",
  active: false,
  onClick: () => {},
  onClickChk: () => {}
};

export default MenuItemSelector;
