/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { string } from 'prop-types';
import { GLOBAL } from "../../Config/global";
import Button from '../Button';
import {APPCONFIG} from '../../app.config';
import $ from 'jquery';

class Footer extends React.Component {

  render() {
    let {rol} = this.props;
    const {PORTAL, ENVIRONMENT, VERSION, BUTTON_TEXT} = APPCONFIG.FOOTER;

    return (
        <div className="bg">
          <div className="col-12 footer-container">
            <footer className="page-footer">
              <div className="row">
                {/* Desktop footer: */}
                <div className="col-md-6 d-none d-md-flex justify-content-center justify-content-md-start">
                  <div className="footer-text align-self-center">
                    {PORTAL}{" | "}{ENVIRONMENT}{" | "}{VERSION} {rol}
                  </div>
                </div>
                {/* Finish desktop */}

                {/* Mobile footer: */}
                <div className="col-12 d-flex justify-content-center d-md-none">
                  <div className="text-center footer-text align-self-center">
                    {PORTAL}
                    <hr className="horizonal-line"/>
                    {ENVIRONMENT}
                    <hr className="horizonal-line"/>
                    {VERSION}
                  </div>
                </div>
                {/* Finish mobile */}

                <div className="col-md-6 d-flex justify-content-center justify-content-md-end footerSupportDiv">
                  {/* <Button size="small" fill="outline" type="button" id="soportetecnico">{BUTTON_TEXT}</Button> */}
                </div>
              </div>
            </footer>
          </div>
        </div>
    )
  }
}

Footer.propTypes = {
  rol: string
};

Footer.defaultProps = {
  rol: ''
};

export default Footer;
