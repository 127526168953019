import React from 'react';
import { string } from 'prop-types';


const HomeBannerLink = ({ image, link }) => {

  const AbrirLink = (url) => {
    window.open(url, '_blank');
  };
  
  return (
    <div className="banner-container">
      <div className="banner-image" 
        style={{background: "url('" + image + "')"}}
        onClick={() => AbrirLink(link)}>

      </div>
    </div>
  );

};

HomeBannerLink.defaultProps = {
  description: '',
  link: ''
};

HomeBannerLink.propTypes = {
  image: string.isRequired,
  link: string.isRequired
};

export default HomeBannerLink;
