/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from "react";
import { array, func, string, object } from "prop-types";
import { withRouter, matchPath } from "react-router-dom";

const Sidebar = ({ onChange, items, title, step, match: { path } }) => {

  const isPathActive = link => matchPath(link, {
    path,
    exact: true
  })

  return (
    <aside className="sidebar d-flex">
      <div className="sidebar__header d-none d-md-block">{title}</div>
      <div className="sidebar__items">
        {items.map((item, index) => (
            <div
              onClick={() => onChange(item)}
              key={index}
              role="button"
              className={`sidebar__item ${(isPathActive(item.link) || (step === index)) ? "-active" : ""} ${ item.disabled ? "-disabled" : "" }`}
            >
              <div className="sidebar__item--icon-container">
                <i className={item.icon} />
              </div>
               <span className="d-none d-md-inline"> {item.label} </span>
            </div>
        ))}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  items: array.isRequired,
  title: string.isRequired,
  match: object,
  onChange: func
};

Sidebar.defaultProps = {
  match: null,
  onChange: () => {}
};

export default withRouter(Sidebar);
