import React, { Fragment, useEffect, useState } from 'react';
import { array, string } from 'prop-types';
import { Button } from '..';

const Carousel = ({ displayImages, titles, subtitles, links, size, backgroundSize }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const intervalID = setInterval(
      () => {
        if (currentImageIndex < displayImages.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        } else {
          setCurrentImageIndex(0);
        }
      },
      6000);

    return () => { clearInterval(intervalID) };
  });

  let openImage = ((e, img) => {
    //debugger
    if (e.target.tagName.toLowerCase() === 'button') return false;
    let w = window.open('about:blank');
    if (w) {
      let image = new Image();
      image.src = img;
      setTimeout(function () {
        w.document.write(image.outerHTML);
      }, 0);
    }
  });

  const imageStyle = {
    backgroundImage: "url('" + displayImages[currentImageIndex] + "')",
    backgroundSize: backgroundSize[currentImageIndex],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  };
  return (
    <>
      <div className={`animation ${size}-img cursorPointer`} style={imageStyle} onClick={(e) => openImage(e, displayImages[currentImageIndex])}> </div>
      <div className="dots">
        {
          displayImages.map((image, index) => (
            (index === currentImageIndex) ?
              (<Fragment key={index}>
                <i onClick={() => setCurrentImageIndex(index)} className="fas fa-circle"></i>{"  "}
              </Fragment>)
              :
              (<Fragment key={index}>
                <i onClick={() => setCurrentImageIndex(index)} className="far fa-circle"></i>{"  "}
              </Fragment>)
          ))
        }
      </div>
      <div className="img-text" onClick={(e) => openImage(e, displayImages[currentImageIndex])}>
        <h1 className="title"> {titles[currentImageIndex]} </h1>
        <p className="legend"> {subtitles[currentImageIndex]} </p>
        {links.length > 0 && links[currentImageIndex] && (<Button onClick={() => { window.open(links[currentImageIndex], '_blank'); }} color="white" size="medium" customStyles={{ fontSize: '16px' }}>Ver más</Button>)}
      </div>
    </>
  );
}

Carousel.propTypes = {
  displayImages: array.isRequired,
  titles: array.isRequired,
  subtitles: array.isRequired,
  links: array.isRequired,
  size: string.isRequired,
  backgroundSize: array.isRequired
};

Carousel.defaultProps = {
  size: 'full',
  backgroundSize: ['cover']
};

export default Carousel;
