/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { HomeCarousel, HomeCommunication, HomeBannerLink, HomeUserInfo, HomeAccountManagerInfo } from "../../Components";
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';

const Home = props => {

  return (
    <div className="container-fluid">

      <div className="row container-home">

        <div className="col-sm-12 col-md-6 pl-3 pr-1">
          <HomeCarousel />
        </div>
        <div className="col-sm-12 col-md-3 pl-1 pr-1">
          <div className="col-12 pb-1 px-0" style={{ height: "80%"}}>
            <HomeCommunication />
          </div>
          <div className="col-12 px-0" style={{ height: "20%"}}>
            <HomeBannerLink image={"./images/Home/marketing_digital.png"} link={"https://www.origenes.com.ar/marketing/"} />
          </div>
        </div>
        <div className="col-sm-12 col-md-3 pl-1 pr-3">
          <div className="col-12 pb-1 px-0" style={{ height: "45%"}}>
            <HomeUserInfo />
          </div>
          <div className="col-12 pb-1 px-0" style={{ height: "35%"}}>
            <HomeAccountManagerInfo />
          </div>
          <div className="col-12 px-0" style={{ height: "20%"}}>
            <HomeBannerLink image={"./images/Home/capacitacion.png"} link={"https://www.origenes.com.ar/capacitaciones-pas/"} />
          </div>
        </div>

      </div>
      
    </div>
  )
};

export default Home;