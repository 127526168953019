import React, { useState } from "react";
import { any, object, oneOf } from "prop-types";

const NavDropdownMenuItem = ({ menuPlacement, children, item }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <li 
      className="nav-dropdown-menu__item"
      onMouseEnter={() => setIsDropdownVisible(true)}
      onMouseLeave={() => setIsDropdownVisible(false)}>
        <a href={item.link} style={{ color: "#504F4F" }}>
          {item.label}
          {children && <span className={`arrow ${menuPlacement}`} />}
          {isDropdownVisible && children}
        </a>
    </li>
  );
};

NavDropdownMenuItem.propTypes = {
  children: any,
  item: object.isRequired,
  menuPlacement: oneOf(['left', 'right'])
};

NavDropdownMenuItem.defaultProps = {
  children: null,
  menuPlacement: 'left'
};

export default NavDropdownMenuItem;
