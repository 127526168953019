import React from "react";
import { UserConfig, Breadcrumb, Loading } from "../../Components";
import { GLOBAL } from "../../Config/global";
import { Redirect, withRouter } from "react-router-dom";

class UserDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingData: false,
            showLogoutModal: false,
            redirect: null,
        };
        this.handleRouteChange = this.handleRouteChange.bind(this);
        this.handleUserConfigLoadToggle = this.handleUserConfigLoadToggle.bind(this);
    }

    handleUserConfigLoadToggle = value => {
        this.setState({ loadingData: value });
    };

    handleRouteChange = option => {
        //let {history} = this.props;
        //const [showLogoutModal, setShowLogoutModal] = useState(false);
        if (option.doBeforeRedirect) {
            //setShowLogoutModal(true);
            return this.setState({ showLogoutModal: true });
        }
        return this.props.history.push(option.link);
    };

    render(...props) {
        const { PAGES } = GLOBAL;

        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <>
                <Loading visible={this.state.loadingData}></Loading>
                <div className="col-12">
                    <div className="col-12">
                        <Breadcrumb currentPage="/user-dashboard-info" />
                    </div>
                    <div className="col-12">
                        <h4 className="form-title">{PAGES.TITLE.USER_DASHBOARD}</h4>
                    </div>

                    {/* <div className="sidebar-container col-md-3 p-0 p-md-2">
                               <Sidebar
                                   onChange={item => this.handleRouteChange(item)}
                                   title={SIDEBAR.TITLE}
                                   items={SIDEBAR.ITEMS}
                               />
                            </div> */}

                    <div className="col-md-12 py-2 marginBottomUserDash">
                        <UserConfig onUserConfigLoadToggle={this.handleUserConfigLoadToggle} />
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(UserDashboard);