/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { Breadcrumb, Button, Alert, Loading, Input, Combobox, FileUploadMultiple } from '../../Components';
import { GLOBAL } from '../../Config/global';
import { FORM_DATA } from "../../Config/form_data";
import { FORM_ERRORS } from "../../Config/form_errors";
import { APPCONFIG } from '../../app.config';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';


class CargaNominas extends React.Component {

	constructor(props) {
		super(props);

		this.itemInit = {
			tipo: 'AP',
			poliza: ''
		};

		this.state = {
			error: '',
			success: false,
			loadingForm: true,
			item: this.itemInit
		};

		/// ERRORS
		this.errorTipo = "";
		this.errorPoliza = "";
		this.errorFiles = "";

		/// FUNCIONES
		this.handleComboChange = this.handleComboChange.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputKeyPress = this.handleInputKeyPress.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.procesarCargaNominas = this.procesarCargaNominas.bind(this);

		this.refFileUploadMultiple = React.createRef();
	}

	componentDidMount() {
		this.setState({
			loadingForm: false
		});
	}


	handleComboChange = (selectedOption, event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let inputName = event.name;
		let inputValue = selectedOption.value;

		this.errorTipo = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.SELECT_OPTION : "";
		this.errorTipoBorderColor = (this.errorTipo) ? "error" : "success";

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}

	handleInputChange = (event) => {
		const { COMMON_ERRORS } = FORM_ERRORS;

		let inputName = event.target.name;
		let inputValue = event.target.value;

		switch (inputName) {
			case "poliza":
				this.errorPoliza = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
				this.errorPolizaBorderColor = (this.errorPoliza) ? "error" : "success";
				break;
			default:
				break;
		}

		/// SETEO DE VALORES
		let itemCopy = Object.assign({}, this.state.item);
		itemCopy[inputName] = inputValue;
		this.setState({ item: itemCopy });
	}

	handleInputKeyPress = (event) => {
		var code = event.which || event.keyCode;
		if (code < 48 || code > 57) {
			event.preventDefault();
		}
	}

	handleSubmit = (event) => {
		event.preventDefault();
		const { SEND_PAYROLL } = GLOBAL;
		const item = this.state.item;

		const files = this.refFileUploadMultiple.current.getFiles();

		if (item.tipo.length > 0 &&
			item.poliza.length > 0 &&
			files.length > 0)
		{
			this.setState({ error: "" });
			this.procesarCargaNominas();
		}
		else
		{
			this.setState({ error: SEND_PAYROLL.VALIDATION_ERROR });
		}
	}

	handleResponse = response => {
		var self = this;

		this.refFileUploadMultiple.current.clearFiles();
		if (response.success) {
			this.setState({ loadingForm: false, success: true, item: self.itemInit }, () => {
				setTimeout(function () {
					window.location.reload(true);
				}, 5000);
			});
		}
		else {
			this.setState({ 
				loadingForm: false, 
				title: response.title,
				error: response.businessExceptions.map(x => x.message).join(". "),
			    errorList: response.businessExceptions.map(x => x.message)
			});
		}
	}

	procesarCargaNominas() {
		
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;
        var userId = userAuth.userId();

		this.setState({ loadingForm: true });

		const item = this.state.item;
		const files = this.refFileUploadMultiple.current.getFiles();
		const listaFileName = files.map(f => { return f.name; });
		const listaFile = files.map(f => { return f.file; });

		QLIBS.fetchDataPOST(APPCONFIG.ENDPOINTS.PRODUCERURL + 'portfolio/SendPayroll', {
			'Type': item.tipo,
			'Policy': item.poliza,
			'FileName': listaFileName,
			'File': listaFile,
			'User': userAuth.userId()
		}, true)
		.then(this.handleResponse)
		.catch(error => {
			this.setState({ loadingForm: false, error: `Error: ${error.message}` });
		});
	}


	render(...props) {
		const { SEND_PAYROLL, PAGES } = GLOBAL;

		return (
			<>
				<Loading visible={this.state.loadingForm}></Loading>
				<section className='form-container master-page carga-nonimas'>
					<div className='col-12'>
						<div className='row'>
							<div className='col-12'>
								<div className="col-12">
									<Breadcrumb currentPage="/envionominas" />
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-12">
											<h4 className="form-title">{PAGES.TITLE.SEND_PAYROLL}</h4>
										</div>
									</div>
								</div>
								<div className="col-12">
									{
										(this.state.error) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													type="error"
													icon="info"
													title={this.state.title}
													errorList = {this.state.errorList}
													noDetail={true}
												/>
											</div>
										</div>
									}
									{
										(this.state.success) &&
										<div id="form-error-message" className="row my-2">
											<div className="col-12">
												<Alert
													type="success"
													icon="info"
													title={SEND_PAYROLL.DOCUMENT_OK}
													message=""
													noDetail={true}
												/>
											</div>
										</div>
									}
								</div>
								<form id="carga_nominas_form" onSubmit={this.handleSubmit}>
									<section>
										<div className="mt-4">
											<div className="row">
												<div className="col-4">
													<Combobox
														id="tipo"
														text="Tipo de nómina"
														label="Tipo de nómina"
														placeholder={FORM_DATA.SELECT_OPTION}
														options={FORM_DATA.PAYROLL_TYPE.map((item) => ({ value: item.id, label: item.value }))}
														errorMsg={this.errorTipo}
														borderColor={this.errorTipoBorderColor}
														defaultValue={this.state.item.tipo}
														onChange={(v, e) => { this.handleComboChange(v, e); }}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<Input
														type={'test'}
														id={'poliza'}
														text={'Nro. de póliza'}
														placeholder={''}
														errorMsg={this.errorPoliza}
														borderColor={this.errorPolizaBorderColor}
														value={this.state.item.poliza}
														onChange={this.handleInputChange}
														onKeyPress={this.handleInputKeyPress}
													/>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<div className="input">
														<label>Archivo de nómina</label>
														<FileUploadMultiple ref={this.refFileUploadMultiple} multiple={false} />
													</div>
												</div>
											</div>													
											<div className="row">
												<div className="col-4 pt-5">
													{
														(!this.state.success) &&
														<Button type="submit" onClick={this.handleSubmit}>{FORM_DATA.SEND_PAYROLL}</Button>
													}
												</div>
											</div>
										</div>
									</section>
								</form>
							</div>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default withRouter(CargaNominas);
