/* eslint-disable react/require-default-props */
import React from 'react'
import { array, bool, func, string, object, oneOfType } from 'prop-types';

function Button({ children, color, disabled, onClick, fill, size, type, classes, customStyles, id, ...props }) {
  return (
    <button
      {...props}
      disabled={disabled}
      className={`button button-${color}-${fill} button-${size} button-${disabled ? 'disabled' : ''}-${fill} rounded ${classes}`}
      onClick={onClick}
      type={type}
      style={customStyles}
      id={id}
    >
      {children}
    </button>
  )
}

Button.defaultProps = {
  customStyles: {},
  color: 'primary',
  disabled: false,
  fill: 'full',
  size: 'large',
  type: 'submit',
  classes: '',
};

Button.propTypes = {
  children: oneOfType([string, array]).isRequired,
  color: string,
  classes: string,
  customStyles: object,
  disabled: bool,
  fill: string,
  onClick: func,
  size: string,
  type: string
};

export default Button;