import React from 'react';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import Button from '../Button';
import { GLOBAL } from '../../Config/global';

const RoleCard = ({ title, img }) => (
  <div className="card">
    <img src={img} className="align-self-center justify-self-center card__Logo" alt="Logo usuario" />
    <div className="text-center">
      <h5 className="card__Title">{ title }</h5>
      <Link to="/channels">
        <Button size="medium">
          { GLOBAL.USER_OPTIONS.BUTTON_TEXT }
        </Button>
      </Link>
    </div>
  </div>
);

RoleCard.propTypes = {
  img: string.isRequired,
  title: string
};

RoleCard.defaultProps = {
  title: GLOBAL.USER_OPTIONS.ADMIN.title
}

export default RoleCard;
