import React, { Component } from 'react'
import { Alert, Button, Input, DatePicker, Checkbox, FileUpload, Carousel, Combobox } from '../../Components'
import { FORM_ERRORS } from '../../Config/form_errors';
import { GLOBAL } from '../../Config/global'
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import userAuth from '../../Config/userauth';
import moment from "moment";



export class ViewsModal extends Component {
    constructor(props) {
        super(props);

        const { listColumns, onDismiss, onSave } = props;

        this.state = {
            onDismiss: onDismiss,
            onSave: onSave,
            listColumnsShow: listColumns.filter(f => f.show).map(col => { return { name: col.name, title: col.title, index: col.index }}),
            listColumnsHide: listColumns.filter(f => !f.show).map(col => { return { name: col.name, title: col.title, index: col.index }})
        }

        this.nameColumnSource = null;
        this.nameColumnTarget = null;

        this.save = this.save.bind(this);
        this.removeColumn = this.removeColumn.bind(this);
        this.addColumn = this.addColumn.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
    }

    componentDidMount() {

    }


    save = () => {
        this.state.onDismiss();
        this.state.onSave(this.getColumn());
    }

    removeColumn = (columnName) => {
        let listColumnsShowNew = this.state.listColumnsShow.slice();
        let listColumnsHideNew = this.state.listColumnsHide.slice();

        listColumnsHideNew.push(listColumnsShowNew.find(f => f.name == columnName));
        listColumnsShowNew = listColumnsShowNew.filter(f => f.name != columnName);
        
        this.setState({ listColumnsShow: listColumnsShowNew, listColumnsHide: listColumnsHideNew });
    }

    addColumn = (columnName) => {
        let listColumnsShowNew = this.state.listColumnsShow.slice();
        let listColumnsHideNew = this.state.listColumnsHide.slice();

        listColumnsShowNew.push(listColumnsHideNew.find(f => f.name == columnName));
        listColumnsHideNew = listColumnsHideNew.filter(f => f.name != columnName);
        
        this.setState({ listColumnsShow: listColumnsShowNew, listColumnsHide: listColumnsHideNew });
    }

    getColumn = () => {
        const listColumnsShowNew = this.state.listColumnsShow.slice();
        const listColumnsHideNew = this.state.listColumnsHide.slice();

        const listColumnsNew = listColumnsShowNew.map(col => {
            return { ...col, show: true }
        }).concat(listColumnsHideNew.map(col => {
            return { ...col, show: false }
        }));

        return listColumnsNew;
    }

    handleDragStart = (e) => {
        this.nameColumnSource = e.currentTarget.id.replace("viewColumn_","");
    }

    handleDragOver = (e) => {
        e.preventDefault();
    }

    handleDrop = (e) => {
        e.stopPropagation();
        this.nameColumnTarget = e.currentTarget.id.replace("viewColumn_","");;

        if (this.nameColumnSource && this.nameColumnTarget) {
            let listColumnsShowNew = this.state.listColumnsShow.slice();

            const indexColumnSource = listColumnsShowNew.findIndex(f => f.name == this.nameColumnSource);
            const indexColumnTargetOriginal = listColumnsShowNew.findIndex(f => f.name == this.nameColumnTarget);
            let columnSource = listColumnsShowNew.splice(indexColumnSource, 1)[0];

            const indexColumnTarget = listColumnsShowNew.findIndex(f => f.name == this.nameColumnTarget);
            const heightHeader = 153;
            const heightColumn = 30;
            const offSetY = (heightHeader + heightColumn * indexColumnTargetOriginal);
            const extraIndex = (e.clientY - offSetY >= 15) ? 1 : 0;
            if (indexColumnTarget + extraIndex == listColumnsShowNew.length)
                listColumnsShowNew.push(columnSource);
            else
                listColumnsShowNew.splice(indexColumnTarget + extraIndex, 0, columnSource);

            this.setState({ listColumnsShow: listColumnsShowNew });
            this.nameColumnSource = null;
            this.nameColumnTarget = null;
        }
    }


    render() {

        const { COMPONENTS_FORM } = GLOBAL;

        return (
            <div id="modal-views" className="modal" tabIndex="-1" role="dialog" aria-modal="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header xHeaderVistaColumna">
                            <button type="button" className="close positioning" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true" onClick={this.state.onDismiss}>&times;</span>
                            </button>
                            <h5 className="modal-title text-center">ADMINISTRAR VISTA DE COLUMNAS</h5>
                        </div>
                        <div className="modal-body p-0 mb-1">

                            <div>

                                <div class="xSubTituloVistaColumna">Columnas visibles:</div>
                                <div id="lstVistaColumna" className="container xContenedorVistaColumna">
                                {this.state.listColumnsShow.map( col => {

                                    return (
                                        <div key={col.name}
                                            id={"viewColumn_" + col.name} 
                                            className="xItemVistaColumna xDraggable"
                                            draggable="true"
                                            onDragStart={(event) => {this.handleDragStart(event)}}
                                            onDragOver={(event) => {this.handleDragOver(event)}}
                                            onDrop={(event) => {this.handleDrop(event)}}
                                        >
                                            <div className="col-md-10 xItemDescripcionVistaColumna">{col.title}</div>
                                            <div className="col-md-2 xItemAccionVistaColumna">
                                                {this.state.listColumnsShow.length > 3 && 
                                                 <a href="#" onClick={() => { this.removeColumn(col.name) }} title="quitar columna"><i className="fa fa-minus" aria-hidden="true"></i></a>
                                                }
                                            </div>
                                        </div>
                                    );

                                 })
                                }
                                </div>
                                
                                <div class="xSubTituloVistaColumna">Columnas ocultas:</div>
                                <div id="lstVistaColumnaNo" className="container xContenedorVistaColumna">
                                {this.state.listColumnsHide.map( col => {
                                    
                                    return (
                                        <div key={col.name}
                                            id={"viewColumn_" + col.name}
                                            className="xItemVistaColumna xNoDraggable"
                                        >
                                            <div className="col-md-10 xItemDescripcionVistaColumna">{col.title}</div>
                                            <div className="col-md-2 xItemAccionVistaColumna">
                                                <a href="#" onClick={() => { this.addColumn(col.name) }} title="agregar columna"><i className="fa fa-plus" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    );

                                 })
                                }
                                </div>

                            </div>

                        </div>
                        <div className="modal-footer xFooterVistaColumna">
                            <Button type="button" size="medium-lg" color="secondary" fill="outline" data-dismiss="modal" onClick={this.state.onDismiss}>{GLOBAL.COMMON_MODAL_FORM.CLOSE_BUTTON}</Button>
                            <Button type="button" size="medium-lg" onClick={() => { this.save() }}>{GLOBAL.COMMON_MODAL_FORM.OK_BUTTON}</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ViewsModal