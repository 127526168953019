import React, { Fragment } from 'react';
import { string } from 'prop-types';
import { GLOBAL } from '../../Config/global';

const Breadcrumb = ({ currentPage }) => {
  const { INNER_NAV: routes } = GLOBAL;
  const pageIndex = routes.findIndex(route => (route.link === currentPage.toLowerCase()));
  var breadcrumbs = routes.slice(0, pageIndex + 1);
  // Remover linea de abajo una vez se defina camino de migas, limita a mostrar home y pagina actual
  breadcrumbs = breadcrumbs.filter(x => { return x.link === "/" || x.link === currentPage });
  return (
    <nav className="bread-crumb">
      {
        breadcrumbs.map(({ link, name }, index) => (
          index === breadcrumbs.length - 1 ?
            <a className="bread-crumb__Active" href={link} key={name}>{name}</a>
            :
            <Fragment key={name}><a href={link}>{name}</a>{" | "}</Fragment>
        ))
      }
    </nav>
  );
}

Breadcrumb.propTypes = {
  currentPage: string.isRequired
}

export default Breadcrumb;