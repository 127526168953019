import orderBy from "lodash.orderby";
import { MOCK_DATA } from "../../../Config/mock-data";

export const requestData = (pageSize, page, sorted, filtered) => {
  return new Promise(resolve => {
    let filteredData = MOCK_DATA.TABLES.makeData();

    if (filtered.length) {
      filteredData = filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return `${row[nextFilter.id]}`.includes(nextFilter.value);
        });
      }, filteredData);
    }

    const sortedData = orderBy(
      filteredData,
      sorted.map(sort => {
        return row => {
          if (!row[sort.id]) {
            return -Infinity;
          }

          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    const res = {
      rows: sortedData.slice(pageSize * page, pageSize * page + pageSize),
      pages: Math.ceil(filteredData.length / pageSize)
    };

    setTimeout(() => resolve(res), 500);
  });
};
