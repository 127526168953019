/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react'
import { Footer, Header, Breadcrumb, Button, Alert, Loading, Input, FormTitle } from '../../Components';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';
import { FORM_ERRORS } from "../../Config/form_errors";
import { Redirect, withRouter } from "react-router-dom";
import userAuth from '../../Config/userauth';
import Script from "react-load-script"
import loginImageDesktop from "../../Resources/Images/FormImages/pantalla-login.jpg";
import loginImageMobile from "../../Resources/Images/FormImages/pantalla-login.jpg";

export class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            redirect: null,
            error: '',
            entity: {
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: ''
            }
        };
        this.errorInForm = false;

        this.errorOldPassword = "";
        this.errorNewPassword = "";
        this.errorConfirmNewPassword = "";

        this.errorOldPasswordBorderColor = "";
        this.errorNewPasswordBorderColor = "";
        this.errorConfirmNewPasswordBorderColor = "";

        this.confirmacionError = "";

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentDidMount() {
        if (!new URLSearchParams(window.location.search).has("code")) {
            this.setState({ redirect: "/admin" });
        }
    }

    handleChange = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;
        let isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;


        switch (inputName) {
            case "oldPassword":
                this.errorOldPassword = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.errorOldPasswordBorderColor = (this.errorOldPassword) ? "error" : "success";
                break;

            case "newPassword":
                this.errorNewPassword = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.errorNewPasswordBorderColor = (this.errorNewPassword) ? "error" : "success";
                break;

            case "confirmNewPassword":
                this.errorConfirmNewPassword = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.errorConfirmNewPasswordBorderColor = (this.errorConfirmNewPassword) ? "error" : "success";
                break;
                
            default:
                break;
        }

        /// SETEO DE VALORES
        let entityCopy = Object.assign({}, this.state.entity);
        entityCopy[inputName] = inputValue;
        this.setState({ entity: entityCopy }, () => console.log(this.state.item));
    }

    validate() {
        const { COMMON_ERRORS } = FORM_ERRORS;

        //debugger
        this.errorInForm = false;

        this.errorOldPassword = "";
        this.errorNewPassword = "";
        this.errorConfirmNewPassword = "";

        this.errorOldPasswordBorderColor = "";
        this.errorNewPasswordBorderColor = "";
        this.errorConfirmNewPasswordBorderColor = "";

        this.confirmacionError = "";

        if (!this.state.entity.oldPassword) {
            this.errorOldPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_ISEMPTY;
            this.errorInForm = true;
        }

        if (!this.state.entity.newPassword) {
            this.errorNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
            this.errorInForm = true;
        }
        else if (this.state.entity.newPassword.length < 6) {
            this.errorNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
            this.errorInForm = true;
        }
        else if (!checkPassword(this.state.entity.newPassword)) {
            this.errorNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
            this.errorInForm = true;
        }

        if (!this.state.entity.confirmNewPassword) {
            this.errorConfirmNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG;
            this.errorInForm = true;
        }
        else if (this.state.entity.confirmNewPassword.length < 6) {
            this.errorNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.FIELD_MIN_CHARACTERS_6;
            this.errorInForm = true;
        }
        else if (!checkPassword(this.state.entity.confirmNewPassword)) {
            this.errorNewPassword = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_INVALID_PATTERN;
            this.errorInForm = true;
        }

        if (this.state.entity.newPassword !== this.state.entity.confirmNewPassword) {
            this.confirmacionError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.PASSWORD_DONT_MATCH;
        }

        if (this.errorInForm) {
            this.errorOldPasswordBorderColor = (this.errorOldPassword) ? "error" : "success";
            this.errorNewPasswordBorderColor = (this.errorNewPassword) ? "error" : "success";
            this.errorConfirmNewPasswordBorderColor = (this.errorConfirmNewPassword) ? "error" : "success";
            this.setState({ error: this.confirmacionError });
            return false;
        }

        return true;
    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.validate()) {
            this.setState({ loadingForm: true }, () => {
                let data = this.getDataForm();
                return QLIBS.fetchDataPOST(APIS.URLS.USERS_SIMULATORS_CHANGE_PASSWORD, data)
                    .then(this.handleResponse)
                    .catch(this.handleResponse);
            });
        }
    }

    getDataForm() {
        return {
            'Cuit': userAuth.user(),
            //'OldPassword': this.state.entity.oldPassword,
            'Password': this.state.entity.newPassword,
            'ConfirmPassword': this.state.entity.confirmNewPassword,
            'Code': new URLSearchParams(window.location.search).get("code")
        }
    }

    handleResponse(response) {
        if (response) {
            userAuth.setUserEmailConfirmed(true);
            userAuth.getStorage().removeItem('userCode');
            this.setState({ redirect: '/simulate-producer' });
        }
        else {
            this.setState({
                error: response.businessExceptions.map(x => x.message.replace('<br/>', '\n')).join(""),
                redirect: null,
                loadingForm: false
            }, () => console.log(this.state));
        }
    }

    render(...props) {
        const { CHANGE_PASSWORD_FORM, PAGES } = GLOBAL;
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />
        }
        return (
            <section>
                <Loading visible={this.state.loadingForm}></Loading>
                <Header
                    showBackButton={false}
                    showNotificationPanel
                    showProfileBadge
                    showNavbar={false}
                    showHamburgerMenu
                />
                <section className="MainContainer login">
                    <section className="container MainContainer__Center">
                        <div className="row MainContainer__Card rounded">
                            <div className="col-md-6 d-none d-md-block px-0">
                                <div className="img-area">
                                    <img
                                        src={loginImageDesktop}
                                        className="img-fluid rounded-left"
                                        alt={CHANGE_PASSWORD_FORM.TITLE}
                                    />
                                    <div className="img-text">
                                        <h6 className="title">{CHANGE_PASSWORD_FORM.TITLE}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-block d-md-none px-0">
                                <div className="img-area">
                                    <img
                                        src={loginImageMobile}
                                        className="img-fluid"
                                        alt={CHANGE_PASSWORD_FORM.TITLE}
                                    />
                                    <div className="img-text">
                                        <h1 className="title">{CHANGE_PASSWORD_FORM.TITLE}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 my-auto">
                                {
                                    (this.state.error) &&
                                    <div id="form-error-message" className="col-12 my-2">
                                        <div className="col-12">
                                            <Alert
                                                icon="times"
                                                message={this.state.error}
                                                title={"ERROR!"}
                                                type="errorMessage"
                                            />
                                        </div>
                                    </div>
                                }
                                <Script url="/scripts/form_validation.js" />
                                <form id="simular_form" onSubmit={this.handleSubmit} className="change_password__Form" noValidate>
                                    <FormTitle text={PAGES.TITLE.CHANGEPASSWORD} />
                                    <Input
                                        type={'password'}
                                        id={'oldPassword'}
                                        text={CHANGE_PASSWORD_FORM.OLD_PASSWORD}
                                        errorMsg={this.errorOldPassword}
                                        onChange={this.handleChange}
                                        borderColor={this.errorOldPasswordBorderColor}
                                        value={this.state.entity.oldPassword}
                                    />
                                    <Input
                                        type={'password'}
                                        id={'newPassword'}
                                        text={CHANGE_PASSWORD_FORM.NEW_PASSWORD}
                                        errorMsg={this.errorNewPassword}
                                        onChange={this.handleChange}
                                        borderColor={this.errorNewPasswordBorderColor}
                                        value={this.state.entity.newPassword}
                                    />
                                    <Input
                                        type={'password'}
                                        id={'confirmNewPassword'}
                                        text={CHANGE_PASSWORD_FORM.CONFIRM_NEW_PASSWORD}
                                        errorMsg={this.errorConfirmNewPassword}
                                        onChange={this.handleChange}
                                        borderColor={this.errorConfirmNewPasswordBorderColor}
                                        value={this.state.entity.confirmNewPassword}
                                    />
                                    <Button type="submit">{CHANGE_PASSWORD_FORM.SUBMIT_BUTTON}</Button>
                                </form>
                            </div>
                        </div>
                    </section>
                </section>
                <Footer />
            </section>
        )
    }
}

export default withRouter(ChangePassword);