import React from 'react'
import Header from '../Header';

export default function MainLayout(props) {
    console.log(props);
    const { children } = props;
    return (
        <section>
            <Header
                {...props}
                showBackButton={false}
                showNotificationPanel
                showProfileBadge
                showNavbar={true}
                showHamburgerMenu
                token={true}
            />
            <section className="master-page">
                {children}
            </section>
        </section>
    )
}