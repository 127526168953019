import React from "react";
import { Button, Header} from "../../Components";
import { GLOBAL } from "../../Config/global";
import alertImg from "../../Resources/Images/GenericError/alert.png";

const GenericError = () => {
  const { GENERIC_ERROR } = GLOBAL;

  return (
    <section>
      <Header showBackButton={false} showHamburgerMenu showNavbar />
      <section className="form-container master-page">
        <div className="container">
          <div className="row pt-5">
            <div className="col-md-4 pt-5">
              <div className="row">
                <div className="col-md-12">
                  <h2>{GENERIC_ERROR.TITLE}</h2>
                  <br />
                  <p>{GENERIC_ERROR.TITLE_MESSAGE}</p>
                  <br />
                  <Button size="small">{GENERIC_ERROR.BUTTON} </Button>
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center pt-5">
              <br />
              <img
                src={alertImg}
                className="generic-error__img"
                alt="alert img"
              />
            </div>
          </div>
        </div>
      </section>
      {/*<Footer rol={CHANNEL_OPTIONS.ROLE} />*/}
    </section>
  );
};

export default GenericError;
