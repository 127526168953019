import React, { useState } from "react";
import { array, bool, func, oneOf, string } from "prop-types";

const Select = ({ id, text, defaultValue, disabled, errorMsg, status, multiple, options, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(multiple ? [] : defaultValue);
  const [index, setIndex] = useState(multiple ? [] : 0);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const onBlur = event => {
    if (!event.currentTarget.contains(event.relatedTarget) || !event.relatedTarget) {
      setExpanded(false);
    }
  };

  const onOptionClick = (newIndex, newValue) => {
    if (multiple) {
      if (value.includes(newValue)) {
        setValue(value.filter(option => option !== newValue));
        setIndex(index.filter(option => option !== newIndex));
      } else {
        setValue([...value, newValue]);
        setIndex([...index, newIndex]);
      }
    } else {
      setValue(newValue);
      setIndex(newIndex);
      onChange({
        target: { id },
        index: newIndex,
        value: newValue
      });
    }

    setExpanded(false);
  };

  return (
    <div className={`form-group select ${disabled ? 'disabled' : ''}`} tabIndex="0" onBlur={onBlur}>
      <label htmlFor={id}>{text}</label>
      <input type="hidden" id={id} onChange={onChange} value={index} />
      {multiple && <span className="small-print mb-3">{value.sort().join(", ")}</span>}
      {!multiple && (
        <div
          tabIndex="0"
          role="button"
          className={`select__title select__title-${status || 'default'}`}
          onMouseDown={toggleExpanded}
          /*onFocus={toggleExpanded}*/
          onChange={onChange}
        >
          {value}
          <i className="fas fa-caret-down arrow-icon" />
        </div>
      )}
      {(expanded || multiple) && (
        <ul className={`select__list ${multiple ? 'position-relative' : 'zx-4'}`}>
          {options.map((option, index) => (
            <li
              role="presentation"
              className={`select__list--item ${option.disabled && !disabled ? 'disabled' : ''} 
                ${multiple && !option.disabled && value.includes(option.value) ? 'selected' : ''}`}
              key={index}
              onMouseDown={() => onOptionClick(option.id, option.value)}
            >
              {option.value}
            </li>
          ))}
        </ul>
      )}
      {errorMsg.length ? <span className={`span-${status}`} >{errorMsg}</span> : null}
    </div>
  );
};

Select.propTypes = {
  id: string.isRequired,
  text: string.isRequired,
  defaultValue: string,
  disabled: bool,
  errorMsg: string,
  multiple: bool,
  options: array.isRequired,
  status: oneOf(["success", "error", "warning", "default"]),
  onChange: func
};

Select.defaultProps = {
  disabled: false,
  errorMsg: '',
  multiple: false,
  status: "default",
  onChange: () => null
};

export default Select;
