import React from 'react'
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"
import StepTutorial from './index-step';

import CarteraStep1 from '../../Resources/Images/Tutoriales/Cartera/Cartera-1.jpg';
import CarteraStep2 from '../../Resources/Images/Tutoriales/Cartera/Cartera-2.jpg';
import IconIndent from '../../Resources/Images/Tutoriales/Cartera/indent.jpg';
import IconDownload from '../../Resources/Images/Tutoriales/Cartera/download.jpg';

const Cartera = props => {
    return (
        <div>
            <ReactWizard
                steps={[
                    {
                        stepName: "PASO 1", component: <StepTutorial image={CarteraStep1} text={['Seleccionar los filtros correspondientes antes de realizar la búsqueda', 'Presione el boton Buscar']} />
                    },
                    {
                        stepName: "PASO 2", component: <StepTutorial image={CarteraStep2}
                        text={[
                            <span>Al hacer click en <img src={IconIndent} alt="..." /> podrás abrir la póliza y navegarla en sus distintas secciones</span>,
                            <span>Al hacer click en <img src={IconDownload} alt="..." /> podrás descargar la póliza / certificado de cobertura</span>,
                            'Al hacer click en el botón “Descargar” podrás exportar tu listado de pólizas a Excel'
                        ]} />
                    }
                ]}
                navSteps
                title="Paso a paso para utilizar la búsqueda de Cartera."
                headerTextCenter
                validate
                color="green"
                previousButtonText="Volver"
                nextButtonText="Continuar"
                finishButtonText="Finalizar"
                finishButtonClick={() => { props.onFinish(); }}
            />
        </div>
    )
}

Cartera.propTypes = {

}

export default Cartera
