import React, { useEffect, useState } from 'react';
import { Carousel } from "../../Components";
import { HOME_DATA } from '../../Config/home-data';
import { APIS } from '../../Config/apis';
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from '../../Config/global';

const HomeCarousel = props => {
  const { HOME_PAGE } = HOME_DATA;

  const [displayImages, setDisplayImages] = useState([]);
  const [titles, setTitles] = useState([]);
  const [subtitles, setSubtitles] = useState([]);
  const [links, setLinks] = useState([]);
  const [backgroundSize, setBackgroundSize] = useState([]);
//debugger
  useEffect(() => {
    async function fetchData() {
//debugger
      let response = await QLIBS.getParameters(GLOBAL.PARAMETERS.SHOW_DEFAULT_IMAGE_HOME);
      if (response) {
        let defaultImageHome = JSON.parse(response.value);
        setDisplayImages(defaultImageHome ? HOME_PAGE.carousel.imagesDesktop : []);
        setTitles(defaultImageHome ? HOME_PAGE.carousel.titles : []);
        setSubtitles(defaultImageHome ? HOME_PAGE.carousel.subtitles : []);
        setLinks(defaultImageHome ? HOME_PAGE.carousel.links : []);
        setBackgroundSize(defaultImageHome ? ['cover'] : []);
      }
      await QLIBS.fetchDataGET(APIS.URLS.NEWS_QUERY + '?Vigentes=true', true)
        .then(response => {
          response.forEach(item => {
            setDisplayImages(prevdisplayImages => [...prevdisplayImages, item.Image ? item.Image : HOME_PAGE.carousel.imagesDesktop[0]]);
            setTitles(prevtitles => [...prevtitles, item.SubTitle]);
            setSubtitles(prevsubtitles => [...prevsubtitles, item.Text]);
            setLinks(prevlinks => [...prevlinks, item.URL]);
            setBackgroundSize(prebackgroundSize => [...prebackgroundSize, item.BackgroundSize]);
          });
        })
        .catch(error => console.log(error))
    }
    fetchData();
  }, [HOME_PAGE.carousel.imagesDesktop, HOME_PAGE.carousel.links, HOME_PAGE.carousel.subtitles, HOME_PAGE.carousel.titles]);

  return (
    <div className="col-12 px-0 img-container full-img-container">
        <Carousel
          displayImages={displayImages}
          titles={titles}
          subtitles={subtitles}
          links={links}
          size="full"
          backgroundSize={backgroundSize}
        />
    </div>
  )
};

export default HomeCarousel;