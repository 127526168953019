/* eslint-disable jsx-a11y/heading-has-content */
import React from 'react';
import {
	Alert,
	Breadcrumb,
	Button,
	DatePicker,
	Input,
	Loading,
	ProducerSelector,
	Table as TableComponent,
	TabsPanel,
	Combobox,
	SchedulerModal,
	ViewsModal,
	ConfirmationModal,
	LabelSection
} from "../../Components";
import Actions from "../../Components/GridComponents/Actions";
import 'react-dates/initialize';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import { GLOBAL } from "../../Config/global";
import { FORM_DATA } from "../../Config/form_data";
import "font-awesome/css/font-awesome.min.css";
import buildQuery from 'odata-query'
import { APPCONFIG } from '../../app.config';
import { USER_TOKEN } from '../../Config/userToken';
import moment from "moment";
import { loadProducerCode } from '../../Config/producerData';
import { truncate } from '../../Shared/Utils';
import userAuth from "../../Config/userauth";
import * as QLIBS from '../../qLibsHelpers';
import { DEFAULT_HEADER } from '../../Config/userToken';
import { PolicyInfoModal } from './policyInfoModal';
import PolicyRowDetail from './partials/policyRowDetail';
import { APIS } from '../../Config/apis';
class Cartera extends React.Component {
	constructor(props) {
		super(props);
		this.filterTabs = [
			{
				label: 'Selección Productor/es',
			},
			{
				label: 'Filtro por Fecha'
			},
			{
				label: 'Filtro por Producto'
			},
			{
				label: 'Filtro por Póliza'
			},
			{
				label: 'Filtro por Asociado'
			}
		];
		this.state = {
			ramos: [],
			productos: [],
			name: '',
			checkedItems: [],
			productorSelected: 0,
			showImprimeAsegurados: false,
			showPolicyInfoModal:false,
			showViewsModal:false,
			selectedRow:null,
			enableFilterTabs: false,
			loadingData: false,
			certificadoGenerado: false,
			searchEnabled: false,
			filtersSelected: false,
			filterTabActive: 0,
			searchCriteria: [
				{
					"productores": []
				},
				{
					"fechaDesde": null,
					"fechaHasta": new Date()
				},
				{
					"ramo": '',
					"producto": ''
				},
				{
					"poliza": null,
					"certificado": null,
					"estado": FORM_DATA.STATUS[0].id
				},
				{
					"nombre": null,
					"apellido": null,
					"dni": null
				}
			],
			searchCriteriaText: "",
			oDataQuery: "",
			productoresData: [],
			showSchedulerModal: false,
			iconFilter: 'fa fa-eye',
			showFilters: false
		};
		this.logoutModalVisible = false;
		this.producerSelector = React.createRef();
		this.showResults = false;
		this.searchResultsError = "";

		this.searchTableDownloadColumn = {
			Header: "Certificado",
			accessor: "actions",
			width: 100,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<Actions
					icon="fas fa-file-download"
					onDownload={() => this.downloadItem(row)}
				/>
			)
		};		

		this.detailsColumn = {
			Header: "Detalles",
			accessor: "actions",
			width: 75,
			style: {
				display: 'flex',
				alignItems: 'center'
			},
			filterable: false,
			sortable: false,
			resizable: true,
			Cell: row => (
				<Actions
				    icon="fas fa-info-circle"
					onDownload={() => this.detailsItem(row)}
				/>
			)
		};

		this.baseTableColumns = this.createBaseTableColumns();
		this.searchTableColumns = this.createSearchTableColumns();

		this.downloadItem = this.downloadItem.bind(this);
		this.onChangeTab = this.onChangeTab.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.clearFiltros = this.clearFiltros.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleResponse = this.handleResponse.bind(this);
		this.handleProducerIdChanged = this.handleProducerIdChanged.bind(this);
		this.handleCheckedItemsChanged = this.handleCheckedItemsChanged.bind(this);
		this.handleProducersChanged = this.handleProducersChanged.bind(this);
		this.handleSaveViewModal = this.handleSaveViewModal.bind(this);
		this.onDismissImprimeAsegurados = this.onDismissImprimeAsegurados.bind(this);
		this.onConfirmImprimeAsegurados = this.onConfirmImprimeAsegurados.bind(this);
	}

	componentDidMount() {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		QLIBS.fillCombo('ramos', PRODUCERURL + 'api/datos/GetComboValues?e=Ramo_Portfolio', this, GLOBAL.COMBO_EMPTY_ITEM, true);
		QLIBS.fillCombo('productos', PRODUCERURL + 'api/datos/GetComboValues?e=Producto_Portfolio', this, GLOBAL.COMBO_EMPTY_ITEM, true);

		var userId = userAuth.userId();
		const requestOptions = {
			method: 'GET',
			//headers: { 'Content-Type': 'application/json' }
			headers: DEFAULT_HEADER.get()
		};

		return fetch(PRODUCERURL + "api/producer/GetProducerCodes?userId=" + userId, requestOptions)
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				var uc = data;
				for (var u in uc) {
					var item = uc[u];
					for (var p in item.producerCodes) {
						var pc = item.producerCodes[p];
						pc.name = pc.name.split("|")[0];
					}
				}
				this.handleProducersChanged(loadProducerCode(uc));
			});
	}
	handleResponseOk = (response, fileName) => {
		let self = this;
		response.blob()
		.then(function (blob) {
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
		})
		.then(() => {
			self.setState({certificadoGenerado:true, loadingData: false, item: self.itemInit });
		});
	}
	handleResponseError = (response) => {
		let self = this;
		self.showResults= true;
		self.searchResultsError="Error al intentar descargar el certificado.";
		response.text()
		.then(function (message) {
			self.setState({ 
				loadingData: false, 
				error: message,
			 });

		})
	}
	downloadItem = event => {
		this.setState({ loadingData: true });
		let fileName = `${event.original.producto.replaceAll(/\s/g,'')}_Certificado.pdf`
		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: JSON.stringify({ 
				idArticulo : event.original.productoId,
				idOperacion : event.original.certificado,
				DNI : event.original.documento_contratante,
				idPoliza : event.original.poliza,
				fechaExpiracion : event.original.fecha_vigencia_hasta.toString()
			})
		};
		const PRODUCERURL  = APIS.URLS.DOWNLOAD_CERTIFICADO;
		fetch(`${PRODUCERURL}`, requestOptions)
		.then(response => {
			if (response.ok)
			this.handleResponseOk(response,fileName);
			else
				this.handleResponseError(response);
		})
		.catch(error => {
			this.setState({ error: `Error: ${error.message}` });
		});
	};

	onDismissImprimeAsegurados = () => {
		this.setState({showImprimeAsegurados: false });
		if (this.state.selectedRow != null) {
			const pdfUrl = this.GetPdfPolizaMatriz(this.state.selectedRow.poliza, 0);
			window.open(pdfUrl, "_blank");
		}
	}
	onConfirmImprimeAsegurados = () => {
		this.setState({showImprimeAsegurados: false });
		if (this.state.selectedRow != null) {
			const pdfUrl = this.GetPdfPolizaMatriz(this.state.selectedRow.poliza, 1);
			window.open(pdfUrl, "_blank");
		}
	}

	detailsItem = event => {
		this.setState({selectedRow:event.row._original, showPolicyInfoModal: true });
	};

	onChangeTab = index => {
		this.setState({ name: this.state.name, filterTabActive: index, filtersSelected: (index > 0) });
	};

	handleKeyDown = event => {
		// Chek which field
		switch (event.target.id) {
			// case "ramo":
			// 	if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
			// 		event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
			// 		(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) &&
			// 		(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
			// 		event.preventDefault();
			// 	}
			// 	break;

			// case "producto":
			// 	if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
			// 		event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
			// 		(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105) &&
			// 		(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
			// 		event.preventDefault();
			// 	}
			// 	break;

			case "nro_poliza":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nro_certificado":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			case "nombre":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "apellido":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 65 || event.keyCode > 90) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 219) {
					event.preventDefault();
				}
				break;

			case "dni":
				if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 35 &&
					event.keyCode !== 36 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
					(event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 96 || event.keyCode > 105)) {
					event.preventDefault();
				}
				break;

			default:
				// Do Nothing
				break;
		}
	};

	handleComboChange = (selectedOption, event) => {
		let inputName = event.name;
		let inputValue = selectedOption.value;
		//let inputString = selectedOption.label;
		let searchCriteria = this.state.searchCriteria;

		switch (inputName) {
			case "ramo":
				searchCriteria[2].ramo = inputValue;
				break;
			case "producto":
				searchCriteria[2].producto = inputValue;
				break;
			case "estado":
				searchCriteria[3].estado = inputValue;
				break;

			default:
				break;
		}
		this.setState({
			searchCriteria: searchCriteria,
			searchEnabled: (searchCriteria[0].productores.length > 0 &&
				((searchCriteria[1].fechaHasta) ||
					(searchCriteria[2].ramo || searchCriteria[2].producto) ||
					(searchCriteria[3].poliza || searchCriteria[3].certificado) ||
					(searchCriteria[4].nombre || searchCriteria[4].apellido || searchCriteria[4].dni)))
		}, () => {
			this.refreshFiltros();
		});
	};

	handleChange = event => {
		let searchCriteria = this.state.searchCriteria;
		// Check which field
		switch (event.target.id) {
			case "fecha_emision_desde":
				searchCriteria[1].fechaDesde = event.target.value;
				break;

			case "fecha_emision_hasta":
				searchCriteria[1].fechaHasta = event.target.value;
				break;

			case "nro_poliza":
				searchCriteria[3].poliza = event.target.value;
				break;

			case "nro_certificado":
				searchCriteria[3].certificado = event.target.value;
				break;

			case "nombre":
				searchCriteria[4].nombre = event.target.value;
				break;

			case "apellido":
				searchCriteria[4].apellido = event.target.value;
				break;

			case "dni":
				searchCriteria[4].dni = event.target.value;
				break;

			default:
				break;
		}

		this.setState({
			searchCriteria: searchCriteria,
			searchEnabled: (searchCriteria[0].productores.length > 0 &&
				((searchCriteria[1].fechaHasta) ||
					(searchCriteria[2].ramo || searchCriteria[2].producto) ||
					(searchCriteria[3].poliza || searchCriteria[3].certificado) ||
					(searchCriteria[4].nombre || searchCriteria[4].apellido || searchCriteria[4].dni)))
		}, () => {
			this.refreshFiltros();
		});
	};

	refreshFiltros = () => {
		const { COMMON_SEARCH_FORM } = GLOBAL;
		// Populate Filtros
		let searchCriteriaText = COMMON_SEARCH_FORM.SELECTED_PRODUCERS;

		// Add productores
		const filter = {};
		//debugger
		// creo dinamicamente el objeto para el filtro de acuerdo al filtro seleccionado
		if (this.state.searchCriteria[0].productores.length > 0) {
			filter.ProducerId = {};
			filter.ProducerId.in = [];
			filter.Source = {};
			filter.Source.in = [];
		} else {
			delete filter.ProducerId;
			delete filter.Source;
		}

		//debugger
		let productores_id = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.id.replace('X', ''))));
		let productores_core = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.core)));
		let productores_text = Array.from(new Set(this.state.searchCriteria[0].productores.map((item) => item.text)));

		productores_text.forEach((item, index) => {
			searchCriteriaText += (index) ? ", " : "";
			searchCriteriaText += item;

			// agrego para la clausula in ProducerId
			let id = parseInt(productores_id[index]);
			if (id > 0)
				filter.ProducerId.in.push(id);

			let core = productores_core[index];
			if (core)
				filter.Source.in.push(core);
		});

		if (this.state.searchCriteria[2].ramo) {
			//filter.BranchCode = Number(this.state.searchCriteria[2].ramo);
			filter.BranchCode = {};
			filter.BranchCode.in = [];
			this.state.searchCriteria[2].ramo.split(';').forEach(function (value, index) {
				filter.BranchCode.in.push(parseInt(value));
			});
		} else {
			delete filter.BranchCode;
		}

		if (this.state.searchCriteria[2].producto) {
			filter.ProductCode = Number(this.state.searchCriteria[2].producto);
		} else {
			delete filter.ProductCode;
		}

		if (this.state.searchCriteria[3].poliza) {
			filter.PolicyNumber = parseInt(this.state.searchCriteria[3].poliza);
		} else {
			delete filter.PolicyNumber;
		}

		if (this.state.searchCriteria[3].certificado) {
			filter.CertificateNumber = parseInt(this.state.searchCriteria[3].certificado);
		} else {
			delete filter.CertificateNumber;
		}

		if (this.state.searchCriteria[3].estado !== '-1') {
			let idestado = this.state.searchCriteria[3].estado;
			let estado = FORM_DATA.STATUS.filter(function (value) { return value.id === idestado })[0];
			//filter.Status = estado.value;
			filter.StatusWebProducers = estado.value;
		} else {
			//delete filter.Status;
			delete filter.StatusWebProducers;
		}

		if (this.state.searchCriteria[4].dni) {
			filter.RecruiterDni = {};
			filter.RecruiterDni.contains = this.state.searchCriteria[4].dni;
		} else {
			delete filter.RecruiterDni;
		}

		// Add dates
		if (this.state.searchCriteria[1].fechaDesde || this.state.searchCriteria[1].fechaHasta) {
			searchCriteriaText += "<br>Rango de Fechas:";
			searchCriteriaText += this.state.searchCriteria[1].fechaDesde ? " Desde " + moment(this.state.searchCriteria[1].fechaDesde).format("DD/MM/YYYY") : "";
			searchCriteriaText += (moment(this.state.searchCriteria[1].fechaHasta).format("DD/MM/YYYY")) ? " Hasta " + moment(this.state.searchCriteria[1].fechaHasta).format("DD/MM/YYYY") : "";
		}

		if (this.state.searchCriteria[2].ramo || this.state.searchCriteria[2].producto) {
			let idramo = this.state.searchCriteria[2].ramo;
			let ramo = '';
			if (idramo) {
				ramo = this.state.ramos.filter(x => x.id === idramo)[0].value;
			}
			let idproducto = this.state.searchCriteria[2].producto;
			let producto = '';
			if (idproducto) {
				producto = this.state.productos.filter(x => x.id === idproducto)[0].value;
			}

			searchCriteriaText += "<br>" + COMMON_SEARCH_FORM.PRODUCTS_SEARCH_TEXT;
			searchCriteriaText += (ramo !== '') ? " Ramo \"" + ramo + "\"" : "";
			searchCriteriaText += ((producto !== '' && ramo !== '') ? ", " : "") + ((producto !== '') ? " Producto \"" + producto + "\"" : "");
		}

		let idestado = this.state.searchCriteria[3].estado;
		if (this.state.searchCriteria[3].poliza || this.state.searchCriteria[3].certificado || (idestado && idestado !== "-1")) {
			searchCriteriaText += "<br>Por póliza:";
			searchCriteriaText += (this.state.searchCriteria[3].poliza) ? " Póliza \"" + this.state.searchCriteria[3].poliza + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[3].certificado && this.state.searchCriteria[3].poliza) ? ", " : "") + ((this.state.searchCriteria[3].certificado) ? " Certificado \"" + this.state.searchCriteria[3].certificado + "\"" : "");

			let estado = FORM_DATA.STATUS.filter(function (value) { return value.id === idestado })[0];
			searchCriteriaText += ((this.state.searchCriteria[3].estado && (this.state.searchCriteria[3].poliza || this.state.searchCriteria[3].certificado)) ? ", " : "") + ((this.state.searchCriteria[3].estado && estado) ? " Estado \"" + estado.value + "\"" : "");
		}

		if (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido || this.state.searchCriteria[4].dni) {
			searchCriteriaText += "<br>Por asociado:";
			searchCriteriaText += (this.state.searchCriteria[4].nombre) ? " Nombre \"" + this.state.searchCriteria[4].nombre + "\"" : "";
			searchCriteriaText += ((this.state.searchCriteria[4].apellido && this.state.searchCriteria[4].nombre) ? ", " : "") + ((this.state.searchCriteria[4].apellido) ? " Apellido \"" + this.state.searchCriteria[4].apellido + "\"" : "");
			searchCriteriaText += ((this.state.searchCriteria[4].dni && (this.state.searchCriteria[4].nombre || this.state.searchCriteria[4].apellido)) ? ", " : "") + ((this.state.searchCriteria[4].dni) ? " Dni \"" + this.state.searchCriteria[4].dni + "\"" : "");
		}

		let oDataQuery = buildQuery({ filter });

		if (this.state.searchCriteria[4].nombre && this.state.searchCriteria[4].apellido) {
			let containsFilter = (this.state.searchCriteria[4].nombre + " " + this.state.searchCriteria[4].apellido).split(" ");
			oDataQuery += oDataQuery.length > 0 ? " and " : "";
			for (let i = 0; i < containsFilter.length; i++) {
				oDataQuery += `contains(toupper(RecruiterName),'${containsFilter[i].toUpperCase()}')` + (i < containsFilter.length - 1 ? " and " : "");
			}
		} else if (this.state.searchCriteria[4].nombre) {
			let containsFilter = this.state.searchCriteria[4].nombre.split(" ");
			oDataQuery += oDataQuery.length > 0 ? " and " : "";
			for (let i = 0; i < containsFilter.length; i++) {
				oDataQuery += `contains(toupper(RecruiterName),'${containsFilter[i].toUpperCase()}')` + (i < containsFilter.length - 1 ? " and " : "");
			}
		} else if (this.state.searchCriteria[4].apellido) {
			let containsFilter = this.state.searchCriteria[4].apellido.split(" ");
			oDataQuery += oDataQuery.length > 0 ? " and " : "";
			for (let i = 0; i < containsFilter.length; i++) {
				oDataQuery += `contains(toupper(RecruiterName),'${containsFilter[i].toUpperCase()}')` + (i < containsFilter.length - 1 ? " and " : "");
			}
		}

		if (this.state.searchCriteria[1].fechaDesde) {
			oDataQuery += oDataQuery.length > 0 ? " and " : "";
			oDataQuery += ` IssueDate ge ${moment(this.state.searchCriteria[1].fechaDesde).format("YYYY-MM-DD")}`
		}
		if (this.state.searchCriteria[1].fechaHasta) {
			oDataQuery += oDataQuery.length > 0 ? " and " : "";
			oDataQuery += ` IssueDate le ${moment(this.state.searchCriteria[1].fechaHasta).format("YYYY-MM-DD")}`
		}

		this.setState({ name: this.state.name, searchCriteriaText: searchCriteriaText, oDataQuery: oDataQuery });
	};

	clearFiltros = () => {
		const SEARCH_DEFAULT_VALUES = [
			{
				"productores": []
			},
			{
				"fechaDesde": null,
				"fechaHasta": new Date()
			},
			{
				"ramo": '',
				"producto": ''
			},
			{
				"poliza": null,
				"certificado": null,
				"estado": FORM_DATA.STATUS[0].id
			},
			{
				"nombre": null,
				"apellido": null,
				"dni": null
			}
		];
		document.getElementById('fecha_emision_desde').value = '';
		document.getElementById('fecha_emision_hasta').value = moment(new Date()).format("DD/MM/YYYY");
		//document.getElementById('ramo').value = '';
		//document.getElementById('producto').value = '';
		document.getElementById('nro_poliza').value = '';
		document.getElementById('nro_certificado').value = '';
		//document.getElementById('estado').selectedIndex = 0;
		document.getElementById('nombre').value = '';
		document.getElementById('apellido').value = '';
		document.getElementById('dni').value = '';

		//this.searchEnabled = false;
		this.showResults = false;
		this.searchResultsError = false;
		//this.filterTabActive = 0;
		this.producerSelector.current.resetSelection();
		this.setState({
			searchCriteria: SEARCH_DEFAULT_VALUES,
			checkedItems: [],
			productorSelected: 0,
			enableFilterTabs: false,
			searchEnabled: false,
			filterTabActive: 0
		}, () => {
			this.refreshFiltros();
		});
	};

	handleSubmit = event => {

		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();

		const limiteProductores = 25;
		if (this.state.searchCriteria[0].productores.length > limiteProductores) {

			event.preventDefault();	
			this.searchResultsError = `Por el momento el máximo de productores seleccionados en una misma consulta es de ${limiteProductores}. Por favor verifique ese filtro. Gracias`;
			this.showResults = true;

			let tableData = [];
			this.searchTableColumns = this.createSearchTableColumns();
			this.searchTableData = {
				makeData: () => {
					return tableData
				}
			};
	
			this.setState({ name: this.state.name, loadingData: false });

			return;
		}

		this.searchResultsError = "";
		this.showResults = false;

		this.setState({ name: this.state.name, filterTabActive: -1, loadingData: true });

		// Parse data to send
		const requestOptions = {
			method: 'GET',
			headers: DEFAULT_HEADER.get()
		};				
		return fetch(`${PRODUCERURL}portfolio/${this.state.oDataQuery.replace(/%20/g, " ")}`, requestOptions)
			.then((response) => {
				//return response.json()
				return QLIBS.authorizeAction(response);
			})
			.then(this.handleResponse)
			.catch(this.handleResponse);

		this.handleResponse([]);
	};

	handleCheckedItemsChanged(checkedItems) {
		//debugger
		let searchCriteria = this.state.searchCriteria;
		searchCriteria[0].productores = checkedItems;

		this.setState({
			checkedItems: checkedItems,
			enableFilterTabs: checkedItems.length > 0,
			searchCriteria: searchCriteria,
			searchEnabled: (searchCriteria[0].productores.length > 0 &&
				((searchCriteria[1].fechaHasta) ||
					(searchCriteria[2].ramo || searchCriteria[2].producto) ||
					(searchCriteria[3].poliza || searchCriteria[3].certificado) ||
					(searchCriteria[4].nombre || searchCriteria[4].apellido || searchCriteria[4].dni)))
		}, () => {
			this.refreshFiltros();
		});
	}

	handleProducerIdChanged(producerId) {
		this.setState({ productorSelected: producerId });
	}

	handleProducersChanged(newProducers) {
		this.setState({ productoresData: newProducers });
	};

	handleResponse(response) {
		const { COMMON_SEARCH_FORM } = GLOBAL;

		let tableData = [];
		if (Array.isArray(response)) {
			this.searchResultsError = "";
			this.showResults = true;

			tableData = response.map(function (item) {

				return {
					id: item.insuranceNumber,
					ramo: item.branchDescription,
					ramoId: item.branchCode,
					producto: item.productDescription,
					productoId: item.productCode,
					poliza: (item.policyNumber).toString(),
					tipoPoliza: (item.policyType != null) ? item.policyType : 1,
					certificado: (item.certificateNumber).toString(),
					nombre: item.recruiterName,
					fecha_emision: moment(item.issueDate).format("DD/MM/YYYY"),
					fechaultimoestado: item.lastStatusDate === null ? '-' : moment(item.lastStatusDate).format("DD/MM/YYYY"),
					estado: item.status,
					estadoWebProductores: item.statusWebProducers,
					premio: `$ ${truncate(item.premium, 2)}`,
					prima: `$ ${truncate(item.reward, 2)}`,
					moneda: item.currency,
					periodo_facturado: moment(item.lastBilledPeriod).format("DD/MM/YYYY"),
					medio_pago: item.paymentMethod,
					fecha_vigencia_desde: moment(item.startDate).format("DD/MM/YYYY"),
					fecha_vigencia_hasta: (item.endDate !== undefined && item.endDate !== null && item.endDate !== '') ? moment(item.endDate).format("DD/MM/YYYY") : '-',
					productor: item.producerDescription,
					compania: item.company,
					documento_contratante: item.recruiterDni,
					pdfUrl: item.pdfUrl,
					core:item.source,
					fecha_dm: moment(item.sysDate).format("DD/MM/YYYY")
				}
			});//JSON.parse(response.data);

		} else {
			this.searchResultsError = COMMON_SEARCH_FORM.SERVER_ERROR;
			this.showResults = true;
		}

		this.searchTableColumns = this.createSearchTableColumns();

		this.searchTableData = {
			makeData: () => {
				return tableData
			}
		};

		this.setState({ name: this.state.name, loadingData: false });
	}

	handleExportSubmit(event) {
		const { PRODUCERURL } = APPCONFIG.ENDPOINTS;

		event.preventDefault();
		this.setState({ loadingData: true });

		const columns = JSON.stringify(this.createExcelReportColumns());

		const requestOptions = {
			method: 'POST',
			headers: DEFAULT_HEADER.get(),
			body: `{"query": "portfolio/${this.state.oDataQuery}", "url": "${PRODUCERURL}", "filters": "${this.state.searchCriteriaText.replace(/['"]+/g, "\\'")}", "columns": "${columns.replace(/['"]/g, "\\'")}"}`
		};
		fetch(`${PRODUCERURL}portfolio/export`, requestOptions)
			.then(function (resp) {
				return resp.blob();
			})
			.then(function (blob) {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = "Cartera.xlsx";
				link.click();
			})
			.then(() => {
				this.setState({ loadingData: false });
			});
	};

	handleSaveViewModal(newListColumns) {
		const tempTableColumns = this.createBaseTableColumns();

		this.baseTableColumns = newListColumns.map(c => {
			let col = tempTableColumns.find(f => f.name == c.name);
			col.show = c.show;
			return col;
		});

		this.searchTableColumns = this.createSearchTableColumns();
	}

	onKeyDown = (event) => {
		// 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
		if (event.key === 'Enter' && this.state.searchEnabled) {
			event.preventDefault();
			event.stopPropagation();
			document.getElementById("btnSubmit").click();
		}
	}

	createSearchTableColumns() {
		const newTableColumns = [this.searchTableDownloadColumn, ...this.baseTableColumns.filter(f => f.show)];
		return newTableColumns;
	}

	createExcelReportColumns() {
		const excelReportColumns = this.baseTableColumns.filter(f => f.show).map(c => {
			return {
				Title: c.title,
				Field: c.field,
				Summarize: c.summarize,
				Format: c.format
			}
		});
		return excelReportColumns;
	}

	createBaseTableColumns() {
		const { REPORT_FORMAT } = GLOBAL;

		let newTableColumns = [];

		// Get Columns identifier
		let columns = {
			"producto": { title: "Producto", field: "ProductDescription", show: true, width: 200 },
			"nombre": { title: "Nombre", field:"", field:"RecruiterName", show: true, width: 200 },
			"premio": { title: "Premio", field:"Premium", format: REPORT_FORMAT.MONEDA, summarize: true, signo: '$', show: true },
			"prima": { title: "Prima", field:"Reward", format: REPORT_FORMAT.MONEDA, summarize: true, signo: '$', show: true },
			"moneda": { title: "Moneda", field:"Currency", show: true },
			"poliza": { title: "Póliza", field:"PolicyNumber", show: true },
			"fecha_emision": { title: "Emisión", field:"IssueDate", format: REPORT_FORMAT.FECHA, show: true },
			"certificado": { title: "Certificado", field:"CertificateNumber", show: true },
			"estadoWebProductores": { title: "Estado", field:"StatusWebProducers", show: true },
			"fechaultimoestado": { title: "Último estado", field:"LastStatusDate", format: REPORT_FORMAT.FECHA, show: true },
			"periodo_facturado": { title: "Período facturado", field:"LastBilledPeriod", format: REPORT_FORMAT.FECHA, show: true },
			"medio_pago": { title: "Medio de Pago", field:"PaymentMethod", show: true },
			"fecha_vigencia_desde": { title: "Vigencia desde", field:"StartDate", format: REPORT_FORMAT.FECHA, show: true },
			"fecha_vigencia_hasta": { title: "Vigencia hasta", field:"EndDate", format: REPORT_FORMAT.FECHA, show: true },
			"productor": { title: "Productor", field:"ProducerDescription", show: true, width: 200 },
			"documento_contratante": { title: "Documento contratante", field:"RecruiterDni", show: true },
			"compania": { title: "Compañía", field:"Company", show: true, width: 200 },
			"ramo": { title: "Ramo", field:"BranchDescription", show: true, width: 200 },
		};

		// Add Columns to Table
		let cols = QLIBS.getTableColumns(columns);
		cols.forEach((c) => { newTableColumns.push(c) });

		return newTableColumns;
	}

	GetPdfPolizaMatriz(poliza, imprimeAsegurados) {
		const userToken = USER_TOKEN.get();
		const AccessToken = userToken.token;
		const RefreshToken = userToken.refreshToken;

		const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `portfolio/GetPdfPolizaMatriz/${poliza}/${imprimeAsegurados}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
		return download_url;
	}

	render(...props) {
		const { TABLE_VIEW } = GLOBAL;
		const { COMMON_SEARCH_FORM, PAGES } = GLOBAL;
		//console.log(APPCONFIG.ENABLESCHEDULER);
		// Set Tabs disabled
		this.filterTabs[1].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[2].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[3].disabled = (!this.state.enableFilterTabs);
		this.filterTabs[4].disabled = (!this.state.enableFilterTabs);

		return (
			<>
				{this.state.showImprimeAsegurados && <ConfirmationModal
					message={"¿Desea imprimir el detalle de asegurados?"}
					status="success"
					title={"Descarga de Certificado"}
					onDismiss={() => this.onDismissImprimeAsegurados()} 
					onConfirm={() => this.onConfirmImprimeAsegurados()} 
				/>}
				{this.state.showViewsModal && <ViewsModal
				 listColumns={this.baseTableColumns}
				 onDismiss={() => { this.setState({ showViewsModal: false }) }}
				 onSave={(listColumns) => { this.handleSaveViewModal(listColumns) }}
				/>}
				<Loading visible={this.state.loadingData}></Loading>
				{this.state.showPolicyInfoModal && <PolicyInfoModal policy={this.state.selectedRow} 
				 onDismiss={() => { this.setState({ showPolicyInfoModal: false }) }} />}
				{this.state.showSchedulerModal && (<SchedulerModal reporte="Portfolio" api={`portfolio/export`} productores={this.state.searchCriteria[0].productores.map((item) => { return item.id.replace('X', '') })} cores={this.state.searchCriteria[0].productores.map((item) => { return item.core })} dismiss={() => { this.setState({ showSchedulerModal: false }) }} />)}
				<section className="col-12">
					<div className="row">
						<div className="col-12">
							<Breadcrumb currentPage="/cartera" />
						</div>
						<div className="col-12">
							<h4 className="form-title">{PAGES.TITLE.PORTFOLIO}</h4>
						</div>
						<div className="col-12 mb-2">
							<LabelSection section="cartera" type="subtitulo" className="form-title" />
						</div>
						<div className="form-container container table-view col-md-12 mt-2">
							<form id="cartera_search_form_1" onSubmit={this.handleSubmit} onKeyDown={this.onKeyDown} className="register__Form" noValidate>
								<div className="row">
									<div className="col-12">
										<TabsPanel options={this.filterTabs} tabActive={this.state.filterTabActive} onChange={this.onChangeTab}>
											<div id="tab1" className="container option-1">
												<ProducerSelector producers={this.state.productoresData}
													ref={this.producerSelector}
													onCheckedItemsChanged={this.handleCheckedItemsChanged}
													onProducerIdChanged={this.handleProducerIdChanged}
													onProducersChanged={this.handleProducersChanged}
												/>
											</div>
											<div id="tab2" className="container option-2">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<label>{COMMON_SEARCH_FORM.FECHA_EMISION.DESDE}</label>
																<DatePicker
																	id="fecha_emision_desde"
																	defaultValue={(this.state.searchCriteria[1].fechaDesde) ? this.state.searchCriteria[1].fechaDesde.toString() : ""}
																	maxLength="10"
																	noDefaultValue="true"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-6">
																<label>{COMMON_SEARCH_FORM.FECHA_EMISION.HASTA}</label>
																<DatePicker
																	id="fecha_emision_hasta"
																	defaultValue={(this.state.searchCriteria[1].fechaHasta) ? this.state.searchCriteria[1].fechaHasta.toString() : ""}
																	maxLength="10"
																	noDefaultValue="true"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab3" className="container option-3">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-6">
																<Combobox
																	id="ramo"
																	label={COMMON_SEARCH_FORM.RAMO}
																	options={this.state.ramos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].ramo}
																/>
															</div>
															<div className="col-md-6">
																<Combobox
																	id="producto"
																	label={COMMON_SEARCH_FORM.PRODUCTO}
																	options={this.state.productos.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[2].producto}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab4" className="container option-4">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nro_poliza"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_POLIZA}
																	defaultValue={this.state.searchCriteria[3].poliza}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="nro_certificado"
																	type="text"
																	text={COMMON_SEARCH_FORM.NRO_CERTIFICADO}
																	defaultValue={this.state.searchCriteria[3].certificado}
																	classes="mb-0"
																	maxLength="50"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Combobox
																	id="estado"
																	label={COMMON_SEARCH_FORM.ESTADO}
																	options={FORM_DATA.STATUS.map((item) => ({ value: item.id, label: item.value }))}
																	onChange={(v, e) => { this.handleComboChange(v, e); }}
																	defaultValue={this.state.searchCriteria[3].estado}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div id="tab5" className="container option-5">
												<div className="col-12 pt-3">
													<div className="container">
														<div className="row">
															<div className="col-md-4">
																<Input
																	id="nombre"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.NOMBRE}
																	defaultValue={this.state.searchCriteria[4].nombre}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="apellido"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.APELLIDO}
																	defaultValue={this.state.searchCriteria[4].apellido}
																	maxLength="100"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="col-md-4">
																<Input
																	id="dni"
																	type="text"
																	text={COMMON_SEARCH_FORM.ASEGURADO.DNI}
																	defaultValue={this.state.searchCriteria[4].dni}
																	classes="mb-0"
																	maxLength="8"
																	onKeyDown={this.handleKeyDown}
																	onChange={this.handleChange}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</TabsPanel>
									</div>
								</div>


								{(this.state.productorSelected > 0) && (this.state.checkedItems.length <= 0) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.PRODUCTOR_SELECTED_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.START_SEARCH_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0) && (this.state.checkedItems.length > 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.SET_FILTER_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="success"
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_ALERT}
												message=""
											/>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (!this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<div className="alert-modal text-center text-lg-left text-light" role="alert">
												<div className="d-md-inline">
													<LabelSection section="cartera" type="aviso" className="alert-modal-info alert-modal-info-label" />
												</div>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected <= 0) && (this.state.filtersSelected) && (!this.state.searchEnabled) && (
									<div className="row">
										<div className="col-12 mt-3">
											<Alert
												type="error"
												icon="info"
												title={COMMON_SEARCH_FORM.SELECT_PRODUCER_ALERT}
												message=""
												noDetail={true}
											/>
										</div>
									</div>
								)}


								{(this.state.searchEnabled) && (
									<div className="row mt-3">
										<div className="col-12">
											<div className="search-buttons">
												<Button type="submit" id="btnSubmit" classes="to-bottom button-small search-button"><span className="fa fa-search"> </span> {COMMON_SEARCH_FORM.SEARCH_BUTTON}</Button>
											</div>
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={this.clearFiltros}><span className="fa fa-filter"> </span> {COMMON_SEARCH_FORM.CLEAR_FILTERS}</Button>
											</div>
											{(this.state.searchEnabled && this.showResults && this.searchTableData.makeData().length > 0) && (
												<div className="search-buttons">
													<Button type="submit" onClick={event => this.handleExportSubmit(event)} size="small"><i className="fas fa-file-download mx-2" /> {TABLE_VIEW.EXPORT_TEXT}</Button>
												</div>
											)}
											{APPCONFIG.ENABLESCHEDULER === "true" && (
												<div className="search-buttons">
													<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showSchedulerModal: true }) }}><span className="fa fa-clock-o"> </span> {COMMON_SEARCH_FORM.SCHEDULER_BUTTON}</Button>
												</div>
											)}
											<div className="search-buttons">
												<Button type="button" classes="to-bottom button-small search-button" onClick={() => { this.setState({ showViewsModal: true }) }}><span className="fa fa-table"> </span> {COMMON_SEARCH_FORM.VIEW_BUTTON}</Button>
											</div>
											<div className="search-buttons search-filters">
												<table style={{ width: '100%' }}>
													<tbody>
														<tr>
															<td><Button type="button" id="btnView" classes="to-bottom button-small search-button" onClick={() => { this.setState({ iconFilter: this.state.iconFilter === 'fa fa-eye' ? 'fa fa-eye-slash' : 'fa fa-eye', showFilters: !this.state.showFilters }) }}><span className={this.state.iconFilter}> </span> {COMMON_SEARCH_FORM.VIEW_FILTERS}</Button></td>
															{this.state.searchEnabled && this.showResults && this.searchTableData.makeData().length > 0 && (<td>{`Se encontraron ${this.searchTableData.makeData().length} registros`}</td>)}
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								)}
								{(this.state.productorSelected > 0 && this.state.checkedItems.length > 0 && this.state.showFilters) && (
									<div className="row">
										<div className={`col-12 mt-3`}>
											<Alert
												errorDesc={this.state.searchCriteriaText}
												errorInfo=""
												errorLine=""
												icon="info"
												message={` ${this.state.productoresData[this.state.productorSelected - 1].text}`}
												title={COMMON_SEARCH_FORM.APPLIED_FILTERS_ALERT}
												type="message"
												details={true}
											/>
										</div>
									</div>
								)}
								{this.searchResultsError && (
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												icon="times"
												message=""
												title={this.searchResultsError}
												errorInfo={this.searchResultsError}
												type="error"
											/>
										</div>
									</div>
								)}	{
									(this.state.certificadoGenerado) &&
									<div id="form-error-message" className="row my-2">
										<div className="col-12">
											<Alert
												type="success"
												title={"Certificado generado con éxito"}
												message=""
												noDetail={true}
											/>
										</div>
									</div>
								}
								{(this.state.searchEnabled && this.showResults) && (
									<div className="row my-3">
										<div className="col-12 px-3">
											<TableComponent columns={this.searchTableColumns} data={this.searchTableData.makeData()} expandable={false} subComponent={props => (<PolicyRowDetail width={(window.innerWidth - 120)} {...props} />)} {...props} />											
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</section>
			</>
		);
	}
}

export default Cartera;