/* eslint-disable import/named */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react'
import recoverImage from '../../Resources/Images/FormImages/recover-form.png'
import { Loading, Alert, Input, Button, FormTitle, Header, BackButton, SubTitle } from "../../Components";
import InputCheck from '../../Resources/Images/Logos/input-check.png';
import InputError from '../../Resources/Images/Logos/input-error.png';
import { GLOBAL, IDENTITY_CONFIG } from "../../Config/global";
import { FORM_ERRORS } from "../../Config/form_errors";
import Script from "react-load-script";
import {APPCONFIG} from '../../app.config';
import { Redirect, withRouter } from "react-router-dom";

class RecoverAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      error: '',
      redirect: null
    }

    this.cuit = "";
    this.email = "";
    this.formError = false;
    this.formValidated = false;

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResponse = this.handleResponse.bind(this);
  }

  handleKeyDown = event => {
    // Chek which field
    switch(event.target.id){
      case "email":
        if (event.keyCode !== 0 && event.keyCode !== 8 && event.keyCode !== 9 && event.keyCode !== 16 && event.keyCode !== 32 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 46 &&
          (event.keyCode < 48 || event.keyCode > 57) && (event.keyCode < 65 || event.keyCode > 90) && (event.keyCode < 96 || event.keyCode > 105) &&
          (event.keyCode < 192 || event.keyCode > 252) && event.keyCode !== 109 && event.keyCode !== 189 && event.keyCode !== 190) {
          event.preventDefault();
        }
      break;

      default:
        // Do Nothing
        break;
    }
  }

  handleChange = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { RECOVER_ACCOUNT } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;

    // Chek which field
    switch(event.target.id){
      case "cuit":
        // Remove "-"
        this.cuit = (event.target.value) ? event.target.value.replace(/-/g, "") : "";

        // Validate data
        this.cuitValid = ValidarCuit(this.cuit);
        if (this.cuitValid){
          this.cuitError = "";
          this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
          this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
        }else{
          this.cuitErrorIcon = "";
          this.cuitErrorBorderColor = "";
        }
      break;

      case "email":
        if (checkEmail(event.target.value)){
          this.emailError = "";
          this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
          this.emailErrorBorderColor = (this.emailError) ? "error" : "success";
        }else{
          this.emailErrorIcon = "";
          this.emailErrorBorderColor = "";
        }
      break;

      default:
        // Do Nothing
      break;
    }

    this.setState({ name: this.state.name});
  }

  handleSubmit = event => {
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { RECOVER_ACCOUNT } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    const { SECURITYURL } = APPCONFIG.ENDPOINTS;
    event.preventDefault();
    const data = new FormData(event.target);

    this.cuit = event.target.cuit.value;
    this.email = event.target.email.value;

    this.formError = false;
    this.formValidated = false;

    this.cuitError = "";
    this.emailError = ""

    this.cuitErrorIcon = "";
    this.emailErrorIcon = ""

    this.cuitErrorIconBorderColor = "";
    this.emailErrorIconBorderColor = ""

    // First validation
    if (!this.cuit){
      this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }
    if (!this.email){
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD;
    }

    // Special Validation
    if (!this.cuitError){
      // Remove "-"
      this.cuit = (this.cuit) ? this.cuit.replace(/-/g, "") : "";

      // Validate data
      this.cuitValid = ValidarCuit(this.cuit);
      if (!this.cuitValid){
        this.cuitError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.CUIT_INVALID;
      }
    }
    if (!this.emailError && !checkEmail(this.email)){
      this.emailError = COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMAIL_INVALID;
    }

    this.formError = this.cuitError || this.emailError;
    if (this.formError){
      this.cuitErrorIcon = (this.cuitError) ? InputError : InputCheck;
      this.cuitErrorBorderColor = (this.cuitError) ? "error" : "success";
      this.emailErrorIcon = (this.emailError) ? InputError : InputCheck;
      this.emailErrorBorderColor = (this.emailError) ? "error" : "success";

      this.loadingForm = false;
      this.setState({ name: this.state.name})
    }else{
      this.loadingForm = true;
      this.setState({ name: this.state.name})

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            Username : this.cuit,
            Email : this.email,
            Application:GLOBAL.APPNAME
        })
      };

      return fetch(SECURITYURL+ "account/SendEmailConfirmation", requestOptions)
        .then(this.handleResponse)
        .catch(this.handleResponse)
      ;
    }
  }

  handleResponse(response){
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { RECOVER_ACCOUNT } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    let mensajeError;

    if (response && response.ok){
        //document.location.href = "./register-validation";
        this.setState({
          redirect: "/register-validation"
        })
        return;
    }else{
      response.text().then(result => {
          this.setState({
          error: RECOVER_ACCOUNT.SERVER_ERROR,
            redirect : null
      })});
    }

    this.loadingForm = false;
    this.formValidated = true;
    this.setState({ name: this.state.name})
  }

  render(){
    const { USER_OPTIONS, COMPONENTS_FORM, FILE_UPLOAD } = GLOBAL;
    const { RECOVER_ACCOUNT } = GLOBAL;
    const { COMMON_ERRORS, CUSTOM_ERRORS } = FORM_ERRORS;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <section>
        <Loading visible={this.loadingForm}></Loading>
        <Header centered />

        <section className="MainContainer">
          <section className="MainContainer__Center container">
            <div className="row MainContainer__Card rounded">
              <div className="col-lg-6 px-0 d-none d-lg-block">
                <img src={ recoverImage } className="img-fluid" alt="Recover Form" />
              </div>
              <div className="col-lg-1 recover__Back d-md-block d-none">
                <BackButton />
              </div>
              <div className="col-lg-4 my-auto">
                {
                  (this.state.error) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message={ this.state.error }
                        title={ COMPONENTS_FORM.ALERT_ERROR_TITLE }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                {
                  (this.formValidated && !this.state.error) &&
                  <div id="form-error-message" className="row my-2">
                    <div className="col-12">
                      <Alert
                        icon="times"
                        message= ""//"{COMPONENTS_FORM.ALERT_ERROR_MSG}"
                        title={ COMMON_ERRORS.REQUEST_DEFAULT }
                        type="errorMessage"
                      />
                    </div>
                  </div>
                }
                <Script
                  url="/scripts/form_validation.js"
                />
                <form id="form-recover-password" onSubmit={this.handleSubmit} className="recover__Form" noValidate>
                  <FormTitle
                    text={RECOVER_ACCOUNT.TITLE}
                  />
                  <SubTitle>{ RECOVER_ACCOUNT.TITLE_MESSAGE }</SubTitle>
                  <Input
                    id="cuit"
                    type="text"
                    text={ RECOVER_ACCOUNT.INPUTS.CUIT }
                    defaultValue=""
                    mask="99-99999999-9"
                    borderColor={ this.cuitErrorBorderColor }
                    imgSrc={ this.cuitErrorIcon }
                    errorMsg={ this.cuitError }
                    onChange={this.handleChange}
                  />
                  <Input
                    id="email"
                    type="text"
                    text={RECOVER_ACCOUNT.INPUTS.EMAIL}
                    defaultValue=""
                    maxLength="100"
                    errorMsg={ this.emailError }
                    borderColor={ this.emailErrorBorderColor }
                    imgSrc={ this.emailErrorIcon }
                    onKeyDown={this.handleKeyDown}
                    onChange={this.handleChange}
                  />
                  <div className="m-bottom">
                    <Button type="submit">{ RECOVER_ACCOUNT.RECOVER_BUTTON }</Button>
                  </div>
                </form>
              </div>
              <div className="col-lg-1 recover__Back d-md-block d-none" />
            </div>
          </section>
        </section>
      {/*<Footer />*/}
    </section>
    );
  }
}

export default withRouter(RecoverAccount);
