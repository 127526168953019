import {
    Login,
    Demo,
    RecoverAccount,
    RecoverPassword,
    RecoverEmail,
    RecoverReset,
    RecoverSuccess,
    Register,
    RegisterSuccess,
    RegisterValidation,
    Form,
    FormLoading,
    FormValidation,
    FormSuccess,
    TableDefault,
    TableSuccess,
    TableError,
    MasterPage,
    Terminos,
    GenericError,
    DragFiles,
    LoginAdmin,
    Tutoriales
} from '../Containers';

export default [
  {
    path: "/login",
    component: Login,
    breadcrumb: "Login",
    exact: true
  },
  {
    path: "/admin",
    component: LoginAdmin,
    breadcrumb: "Login",
    exact: true
  },
  {
    path: "/recover-account",
    component: RecoverAccount,
    breadcrumb: "Recover Account"
  },
  {
    path: "/recover-password",
    component: RecoverPassword,
    breadcrumb: "Recover Password"
  },
  {
    path: "/recover-email",
    component: RecoverEmail,
    breadcrumb: "Recover Email"
  },
  {
    path: "/recover-reset",
    component: RecoverReset,
    breadcrumb: "Recover Reset"
  },
  {
    path: "/recover-success",
    component: RecoverSuccess,
    breadcrumb: "Recover Success"
  },
  {
    path: "/register",
    component: Register,
    breadcrumb: "Register"
  },
  {
    path: "/register-success",
    component: RegisterSuccess,
    breadcrumb: "Register Success"
  },
  {
    path: "/register-validation",
    component: RegisterValidation,
    breadcrumb: "Register Validation"
  },
  {
    path: "/form",
    component: Form,
    breadcrumb: "Alta Formulario"
  },
  {
    path: "/terminos",
    component: Terminos,
    breadcrumb: "Terminos y condiciones"
  },
  {
    path: "/form-loading",
    component: FormLoading,
    breadcrumb: "Alta Formulario"
  },
  {
    path: "/form-validated",
    component: FormValidation,
    breadcrumb: "Alta Formulario"
  },
  {
    path: "/form-success",
    component: FormSuccess,
    breadcrumb: "Alta Formulario"
  },
  {
    path: "/table",
    component: TableDefault,
    breadcrumb: "Tabla"
  },
  {
    path: "/table-success",
    component: TableSuccess,
    breadcrumb: "Tabla"
  },
  {
    path: "/table-error",
    component: TableError,
    breadcrumb: "Tabla"
  },
  {
    path: "/demo",
    component: Demo,
    breadcrumb: "Demo"
  },
  {
    path: "/master-page",
    component: MasterPage,
    breadcrumb: "Master Page"
  },
  {
    path: "/generic-error",
    component: GenericError,
    breadcrumb: "Generic Error"
  },
  {
    path: "/drag-files",
    component: DragFiles,
    breadcrumb: "Tutoriales"
  },
  {
    path: "/tutoriales",
    component: Tutoriales,
    breadcrumb: "Tutoriales"
  }
];
