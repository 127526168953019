import React from 'react';
import { array, any, string, bool, func } from 'prop-types';

const Radio = ({ name, options, selectedOption, onChange, inline, smallPrint }) => (
  <section>
    {options.map(({ label, value, disabled }, index) => (
      <div className={`form-check ${inline ? 'form-check-inline' : ''} ${disabled ? 'radio-disabled' : ''}`} key={value}>
        <input
          className="form-check-input"
          //defaultChecked={selectedOption === value}
          checked={selectedOption === value}
          id={`radio${index}`}
          name={name}
          type="radio"
          value={value}
          disabled={disabled}
          onChange={onChange}
        />
        <label className="form-check-label" htmlFor={`radio${index}`}>{label}</label>
      </div>
    ))}
    {smallPrint.length ? <><br /><span className="new-line">{smallPrint}</span></> : null}
  </section>
);

Radio.defaultProps = {
  onChange: () => { },
  inline: false,
  smallPrint: ""
};

Radio.propTypes = {
  name: string.isRequired,
  options: array.isRequired,
  selectedOption: any,
  onChange: func,
  inline: bool,
  smallPrint: string
};

Radio.displayName = 'Radio';

export default Radio;
