import React from "react";
import { bool, string } from "prop-types";
import Button from "../Button";

const Toast = ({ message, confirmMessage, invalid }) => {
  return (
    <div className={`toast-wrapper d-flex align-items-center ${invalid ? 'invalid' : ''}`}>
      <span>{message}</span>
      <Button
        customStyles={{
          fontSize: '11px',
          fontWeight: 'bold',
          padding: '7px 29px'
        }}
        size="small"
        disabled={invalid}>
        {confirmMessage}
      </Button>
    </div>
  );
};

Toast.propTypes = {
  confirmMessage: string.isRequired,
  message: string.isRequired,
  invalid: bool
};

Toast.defaultProps = {
  invalid: false
};

export default Toast;
