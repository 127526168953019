import React, { useState, useEffect } from "react";
import { array, bool, func } from "prop-types";
import { APPCONFIG } from '../../app.config';
import { USER_TOKEN } from '../../Config/userToken';

function NotificationCounter (props) {

  let listMessages = props.data.map(item => {
    return {...item, active: true};
  });

  const [state, setState] = useState({
    listMessages: listMessages,
    controlVisible: props.visible,
    messagesVisible: false
  });

  useEffect(() => {

    let listMessages = props.data.map(item => {
      return {...item, active: true};
    });

    setState({...state, listMessages: listMessages, controlVisible: props.visible});
  }, [props]);
  
  function ShowMessages() {
    setState({...state, messagesVisible: true});
  }
  function HideMessages() {
    setState({...state, messagesVisible: false});
  }
  function VerifyMessage(id) {
    if (props.onChange !== null) {

      let stateNew = {...state};
      let item = stateNew.listMessages.find(i => i.id == id);
      if (item) item.active = false;
      setState({...stateNew});

      props.onChange(id);
    }
  }
  function GetUrlAttach(id) {
    const userToken = USER_TOKEN.get();
    const AccessToken = userToken.token;
    const RefreshToken = userToken.refreshToken;

    const download_url = APPCONFIG.ENDPOINTS.PRODUCERURL + `api/FileRepository/Download/NotificationAttach/${id}?RefreshToken=${RefreshToken}&AccessToken=${AccessToken}`;
    return download_url;
  }
  function ClickCounter() {
    if (state.messagesVisible)
      HideMessages();
    else
      ShowMessages();
  }

  function ClickTitle(index) {
    let item = state.listMessages[index];
    if (item.body.length > 0) {
      item.showBody = !item.showBody;
      setState({...state, listMessages: state.listMessages});
    }
  }

  function handleHistorial() {
    window.open('./historialnotificaciones', '_self');
  }


  return(

    <React.Fragment>
      {state.controlVisible &&
      <div className={"counter-div"} >
        <a className={"counter-info"}  href="#" onClick={ClickCounter} >
            <i className={"counter-icon fa fa-bell"} ></i>
            {
              (state.listMessages.length > 0) && <span className={"label counter-label"} >{state.listMessages.length}</span> 
            }
        </a>
        {state.messagesVisible &&
          <ul className={"counter-dropdown dropdown-menu"}>

            <li>
              <div className={"dropdown-messages-box dropdown-messages-close"} >
                <a className={"pull-right"}><i className={"fa fa-angle-up"} onClick={e => HideMessages() }></i></a>
              </div>
            </li>
            { 
            (state.listMessages.length > 0) ?
              state.listMessages.map( function(item, index) {
                
                return  <li key={index}>
                          <div className={"dropdown-messages-box"}>
                            <a className={"pull-left"}><i className={"fa fa-envelope"} aria-hidden="true"></i></a>
                            <div>
                              <span
                                className={(item.body.length > 0 || item.link.length > 0) ? "counter-title counter-title-con-contendio" : "counter-title"}
                                title={(item.title.length > 45) ? item.title : ""}
                                onClick={e => ClickTitle(index) }
                              >
                                {(item.title.length > 45) ? item.title.toString().substring(0,45) + "..." : item.title}
                              </span>
                              <a className={"pull-right"} title='Marcar como vista'>
                                <i className={(item.active) ? "fa fa-times counter-verify": "fa fa-spinner counter-verify"} aria-hidden="true"
                                  onClick={e => {if (item.active) VerifyMessage(item.id)} }></i>
                              </a>
                              {item.link && (
                              <a className={"pull-right"} title='Ver archivo adjunto'
                                href={GetUrlAttach(item.id)} target="_blank">
                                <i className={"fa fa-paperclip counter-verify"} aria-hidden="true"></i>
                              </a>
                              )}
                            </div>
                            {item.showBody && item.body.length > 0 &&
                              <div className={"counter-body"} dangerouslySetInnerHTML={{ __html: item.body }} />
                            }
                          </div>
                          {
                            (index + 1 < state.listMessages.length) && <div className={"divider"}></div>
                          }
                        </li>

              })
              :
              <li>
                <div className={"text-center"}>
                  <div>
                    <span className={"counter-empty"}>Sin notificaciones pendientes</span>
                  </div>
                </div>
              </li>
            }
            <li>
              <div className={"counter-historial text-center"}>
                <hr />
                <span 
                onClick={e => handleHistorial()}
                className={"counter-title"}>Ver historial de notificaciones</span>
              </div>
            </li>

          </ul>
        }
      </div>
      }
    </React.Fragment>

  );

}

NotificationCounter.propTypes = {
  data: array,
  visible: bool,
  onChange: func
};

NotificationCounter.defaultProps = {
  data: [],
  visible: false,
  onChange: null
};

export default NotificationCounter;
