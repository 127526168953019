import React, { Component } from 'react'
import { Breadcrumb, Alert, Input } from '../../Components';
import { FORM_ERRORS } from "../../Config/form_errors";
import { withRouter } from "react-router-dom";
import ReactWizard from 'react-bootstrap-wizard';
import "react-bootstrap-wizard/dist/react-wizard.scss"

class FirstStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstStep: "first step here",
            field_1: '',
            name: ''
        };
        this.field_1_borderError = '';
        this.field_1_errorMsg = '';
    }
    handleChange = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;

        let isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        this.field_1_errorMsg = '';

        /// SETEO DE ERRORES
        switch (inputName) {
            case "field_1":
                this.field_1_errorMsg = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.field_1_borderError = (this.field_1_errorMsg) ? "error" : "success";
                break;
            default:
                break;
        }

        /// SETEO DE VALORES
        this.setState({ [inputName]: inputValue });
    }
    isValidated() {
        const { COMMON_ERRORS } = FORM_ERRORS;

        this.field_1_errorMsg = '';
        this.field_1_errorMsg = (this.state.field_1.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
        this.field_1_borderError = (this.field_1_errorMsg) ? "error" : "success";

        this.setState({ name: '' });

        return this.field_1_errorMsg.length === 0;
    }
    render() {
        return (
            <>
                <div>Hey from First</div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <Input
                            type="text"
                            id="field_1"
                            text="Campo 1"
                            defaultValue={this.state.field_1}
                            borderColor={this.field_1_borderError}
                            errorMsg={this.field_1_errorMsg}
                            onChange={this.handleChange}
                            placeholder="CAMPO 1"
                        />
                    </div>
                </div>
            </>
        );
    }
}
class SecondStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            secondStep: "second step here",
            field_2: '',
            name: ''
        };
        this.field_2_borderError = '';
        this.field_2_errorMsg = '';
    }
    handleChange = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;

        let isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        this.field_2_errorMsg = '';

        /// SETEO DE ERRORES
        switch (inputName) {
            case "field_2":
                this.field_2_errorMsg = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.field_2_borderError = (this.field_2_errorMsg) ? "error" : "success";
                break;
            default:
                break;
        }

        /// SETEO DE VALORES
        this.setState({ [inputName]: inputValue });
    }
    isValidated() {
        const { COMMON_ERRORS } = FORM_ERRORS;

        this.field_2_errorMsg = '';
        this.field_2_errorMsg = (this.state.field_2.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
        this.field_2_borderError = (this.field_2_errorMsg) ? "error" : "success";

        this.setState({ name: '' });

        return this.field_2_errorMsg.length === 0;
    }
    render() {
        return (
            <>
                <div>Hey from Second</div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <Input
                            type="text"
                            id="field_2"
                            text="Campo 2"
                            defaultValue={this.state.field_2}
                            borderColor={this.field_2_borderError}
                            errorMsg={this.field_2_errorMsg}
                            onChange={this.handleChange}
                            placeholder="CAMPO 2"
                        />
                    </div>
                </div>
            </>
        );
    }
}
class ThirdStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            thirdStep: "third step here",
            field_3: '',
            name: ''
        };
        this.field_3_borderError = '';
        this.field_3_errorMsg = '';
    }
    handleChange = event => {
        const { COMMON_ERRORS } = FORM_ERRORS;

        let isCheckbox = event.target.type === "checkbox";
        let inputName = event.target.name;
        let inputValue = isCheckbox ? event.target.checked : event.target.value;

        this.field_3_errorMsg = '';

        /// SETEO DE ERRORES
        switch (inputName) {
            case "field_3":
                this.field_3_errorMsg = (inputValue.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
                this.field_3_borderError = (this.field_3_errorMsg) ? "error" : "success";
                break;
            default:
                break;
        }

        /// SETEO DE VALORES
        this.setState({ [inputName]: inputValue });
    }
    isValidated() {
        const { COMMON_ERRORS } = FORM_ERRORS;

        this.field_3_errorMsg = '';
        this.field_3_errorMsg = (this.state.field_3.length <= 0) ? COMMON_ERRORS.INITIAL_MARKER + COMMON_ERRORS.EMPTY_FIELD_LONG : "";
        this.field_3_borderError = (this.field_3_errorMsg) ? "error" : "success";

        this.setState({ name: '' });

        return this.field_3_errorMsg.length === 0;
    }
    render() {
        return (
            <>
                <div>Hey from Third</div>
                <br />
                <div className="row">
                    <div className="col-12">
                        <Input
                            type="text"
                            id="field_3"
                            text="Campo 3"
                            defaultValue={this.state.field_3}
                            borderColor={this.field_3_borderError}
                            errorMsg={this.field_3_errorMsg}
                            onChange={this.handleChange}
                            placeholder="CAMPO 3"
                        />
                    </div>
                </div>
            </>
        );
    }
}

export class WizardExample extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingForm: false,
            error: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentDidMount() {
    }

    handleSubmit = allStates => {
        console.log(allStates);
        alert(JSON.stringify(allStates));
    }

    handleResponse = response => {
    }

    render(...props) {
        var steps = [
            // this step hasn't got a isValidated() function, so it will be considered to be true
            { stepName: "PASO 1", component: FirstStep },
            // this step will be validated to false
            { stepName: "PASO 2", component: SecondStep },
            // this step will never be reachable because of the seconds isValidated() steps function that will always return false
            { stepName: "PASO 3", component: ThirdStep }
        ];
        return (
            <section className='form-container master-page wizard-page'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-12'>
                            <Breadcrumb currentPage="/" />
                            <div className="col-12">
                                {
                                    (this.state.error) &&
                                    <div id="form-error-message" className="row my-2">
                                        <div className="col-12">
                                            <Alert
                                                icon="times"
                                                message={this.state.error}
                                                title={"ERROR PANTALLA..."}
                                                type="errorMessage"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-12">
                            <ReactWizard
                                steps={steps}
                                navSteps
                                title="react-wizard"
                                description="This will help you split a complicated flow or a complicated form in multiple steps."
                                headerTextCenter
                                validate
                                color="green"
                                previousButtonText={"VOLVER"}
                                nextButtonText={"CONTINUAR"}
                                finishButtonText={"GRABAR"}
                                finishButtonClick={this.handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(WizardExample);