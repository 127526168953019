import React, { Component } from 'react'
import { Header, Table as TableComponent, Footer, Loading, CRUDButtons, Alert, Actions, ConfirmationModal } from '../../Components'
import { APIS } from '../../Config/apis'
import * as QLIBS from '../../qLibsHelpers';
import { GLOBAL } from "../../Config/global";
import { withRouter } from "react-router-dom";
import { UserModal } from '..';
import { FORM_ERRORS } from "../../Config/form_errors";

export class AdminUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loadingVisible: false,
            clickOnAddNewRecord: false,
            clickOnDeleteRecord: false,
            dataList: [],
            entity: {},
            submitError: false,
            formError: ''
        }
        this.handleResponse = this.handleResponse.bind(this);
    }

    componentDidMount() {
        this.fillGrid();
    }

    fillGrid = () => {
        const self = this;
        const url = APIS.URLS.USERS_SIMULATORS_QUERY;
        QLIBS.fetchDataPOST(url, self.getQueryFilters(), true)
            .then(function (data) {
                self.setState({ dataList: data.Rows, loadingVisible: false });
            });
    }

    getQueryFilters = () => {
        return GLOBAL.USER_TYPE_SIMULADOR;
    }

    addNewRecord = event => {
        this.setState({ clickOnAddNewRecord: true, submitOk: false, submitError: false });
    }

    toggleModal = (response = false) => {
        this.setState({
            clickOnAddNewRecord: false,
            clickOnDeleteRecord: false,
            submitOk: response
        },
            () => {
                this.fillGrid();
            });
    }

    deleteRecord = (row) => {
        this.setState({ clickOnDeleteRecord: true, submitOk: false, submitError: false, entity: row.original });
    }

    onConfirmDeleteRecord = event => {
        const self = this;
        QLIBS.fetchDataPOST(APIS.URLS.USERS_SIMULATORS_DELETE, self.state.entity.Id, true)
            .then(this.handleResponse)
            .catch(this.handleResponse);
    }

    handleResponse(response) {
        const { COMMON_ERRORS } = FORM_ERRORS;

        if (response && response.success) {
            this.setState({
                clickOnDeleteRecord: false,
                submitOk: true,
                entity: null
            }, () => { this.fillGrid(); });
        }
        else {
            if (response.businessExceptions.length) {
                this.setState({ clickOnDeleteRecord: false, submitError: true, formError: response.businessExceptions.map(x => x.message).join(". ") });
            }
            else {
                this.setState({ clickOnDeleteRecord: false, submitError: true, formError: COMMON_ERRORS.REQUEST_DEFAULT });
            }
        }
    }

    render(...props) {
        const { PAGES, COMMON_CRUD_FORM, COMPONENTS_FORM } = GLOBAL;
        return (
            <section>
                <Loading visible={this.state.loadingData}></Loading>
                <Header
                    {...this.props.history}
                    showBackButton={false}
                    showNotificationPanel
                    showProfileBadge
                    showNavbar={false}
                    showHamburgerMenu
                />
                {this.state.clickOnAddNewRecord && <UserModal onDismiss={this.toggleModal} />}
                {this.state.clickOnDeleteRecord && <ConfirmationModal title={GLOBAL.COMPONENTS_FORM.CONFIRM_TITLE} message={GLOBAL.COMPONENTS_FORM.CONFIRM_DELETE} onDismiss={this.toggleModal} onConfirm={this.onConfirmDeleteRecord} />}
                <section className="form-container master-page">
                    <div className='col-12'>
                        <div className="row">
                            <div className="col-10">
                                <h4 className="form-title">{PAGES.TITLE.ADMINUSERS}</h4>
                            </div>
                            <div className="col-2" style={{ marginBottom: '24px', marginTop: '42px' }}>
                                <CRUDButtons text={COMMON_CRUD_FORM.NEW_BUTTON} icon={COMMON_CRUD_FORM.NEW_ICON_BUTTON} class="button button-primary-full button-small button--full rounded" onClick={this.addNewRecord} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-1">
                                {(this.state.submitError) &&
                                    <Alert
                                        type="error"
                                        icon="exclamation"
                                        title={COMPONENTS_FORM.FORM_ERROR}
                                        message={this.state.formError}
                                    />}
                                {(this.state.submitOk) &&
                                    <Alert
                                        title={COMPONENTS_FORM.REGISTEROK}
                                        message={''}
                                    />}
                            </div>
                            <div className="col-12">
                                <TableComponent data={this.state.dataList} keyField={"id"}
                                    columns={[
                                        { Header: "", accessor: "actions", width: 70, style: { display: 'flex', alignItems: 'center' }, filterable: false, sortable: false, Cell: row => (<Actions onDelete={() => this.deleteRecord(row)} />) },
                                        //{ Header: "ID", accessor: "Id" },
                                        { Header: "Nombre de usuario", accessor: "UserName" },
                                        { Header: "Nombre", accessor: "FirstName" },
                                        { Header: "Apellido", accessor: "LastName" },
                                    ]} />
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </section>
        )
    }
}

export default withRouter(AdminUsers);