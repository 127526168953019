export const truncate = (num, places) => {
    return +(Math.round(num + "e+" + places) + "e-" + places);
    //return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
};
export const formatNumberWithTwoDecimals = (num) => {
    return (
        num
          .toFixed(2) // always two decimal digits
          .replace('.', ',') // replace decimal point character with ,
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      ) // use . as a separator
};